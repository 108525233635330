import { useField, useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';

export interface TextInputFieldProps {
    name: string,
    label: string,
    autofocus?: boolean,
    type?: 'text' | 'password',
    multiline?: boolean
}

const TextInputField: React.FC<TextInputFieldProps> = ({
    name,
    label,
    autofocus = false,
    type = 'text',
    multiline = false
}) => {
    const [fieldProps, fieldMeta] = useField(name);
    const isError = fieldMeta.touched && !!fieldMeta.error;
    const helperText = fieldMeta.touched ? fieldMeta.error : undefined;

    return (
        <>
            <TextField
                {...fieldProps}
                type={type}
                variant="outlined"
                margin="normal"
                fullWidth
                id={name}
                label={label}
                name={name}
                autoComplete={name}
                error={isError}
                helperText={helperText}
                value={fieldProps.value}
                autoFocus={autofocus}
                multiline={multiline}
            />
        </>
    );
};

export default TextInputField;