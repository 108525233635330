import { WorkoutNew } from '../../models/Workout';
import WorkoutEditorProvider from './WorkoutEditorContext/WorkoutEditorContext';
import WorkoutEditor from './WorkoutEditor';

interface WorkoutEditorProps {
    initialValues?: WorkoutNew,
    handleSave: (values: WorkoutNew) => void
}

const WorkoutEditorContainer: React.FC<WorkoutEditorProps> = ({...props}) => {
    return (
        <WorkoutEditorProvider>
            <WorkoutEditor {...props} />
        </WorkoutEditorProvider>
    );
};

export const workoutInitialValues: WorkoutNew = {
    name: '',
    description: '',
    image: '',
    published: false,
    premium: true,
    level: 'Beginner',
    steps: [],
    type: 'singular'
};

export const alphabet = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];

export default WorkoutEditorContainer;