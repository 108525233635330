import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import AppCard from '../components/AppCard';
import CardLoader from '../components/CardLoader';
import { useNutrition } from '../nutrition/context/NutritionContext';

const useStyles = makeStyles((theme) => ({
    cardBase: {
        height: 92
    },
    current: {
        fontSize: 20,
        fontWeight: theme.typography.fontWeightBold
    },
    target: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium
    },
    kcal: {
        fontSize: 10,
        fontWeight: theme.typography.fontWeightMedium
    }
}));

const KcalCard: React.FC = () => {
    const classes = useStyles();
    const { setDate, isLoading, diaryTotals, target, fetchTarget } = useNutrition();

    useEffect(() => {
        setDate(new Date());
        fetchTarget();
    }, []);

    return (
        <AppCard className={classes.cardBase}>
            <div>
                <span className={classes.current}>{diaryTotals?.kcal.toFixed(0)}</span>
                <span className={classes.target}> / {target?.kcal}</span>
                <span className={classes.kcal}> kcals</span>
            </div>
            <CardLoader loading={isLoading} />
        </AppCard>
    );
};

export default KcalCard;