import { Button, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useCallback } from 'react';
import { useHttpRequest } from '../../api';
import { getAllChallenges } from '../../api/Workout';
import { appPaths } from '../../AppRoutes';
import AppButton from '../../components/AppButton';
import config from '../../config';

const useStyles = makeStyles(theme => ({
    upcomingChallenge: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 16,
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderBottom: '1px solid silver'
    },
    upcomingChallengeImage: {
        position: 'relative',
        '& > div': {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            maxHeight: '200px',

            '& > img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: theme.shape.borderRadius
            }
        }
    },
    upcomingChallengeContent: {
        display: 'flex',
        alignItems: 'center'
    },
    uPre: {
        textTransform: 'uppercase',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    uName: {
        fontSize: '1.2rem'
    },
    uDate: {
        marginBottom: '1rem'
    }
}));

const UpcomingChallengeList: React.FC = () => {
    const classes = useStyles();

    const getWorkoutChallenges = useCallback(() => getAllChallenges(), []);
    const { result: challenges, isLoading } = useHttpRequest(getWorkoutChallenges);
    
    const guides = challenges;

    return (
        <>
            {!isLoading && guides &&  guides.length > 0 && guides.map(guide => 
                <div className={classes.upcomingChallenge} key={guide._id}>
                    <div className={classes.upcomingChallengeContent}>
                        <div>
                            <div className={classes.uPre}>Brand New!</div>
                            <div className={classes.uName}>{guide.name}</div>
                            <div className={classes.uDate}>Starts {moment(guide.startsAt).format('Do MMM')}</div>
                            <AppButton linkPath={`${appPaths.guideDetail}/${guide._id}`}>Learn more</AppButton>
                        </div>
                    </div>
                    <div className={classes.upcomingChallengeImage}>
                        <div>
                            {guide?.image && <img src={`${config.apiBaseUrl}/media/content/${guide.image}`} alt={guide?.name} />}
                        </div>
                    </div>
                </div>  
            )}
        </>  
    );
};

export default UpcomingChallengeList;