import AppButton from '../../../components/AppButton';
import { Form, Formik } from 'formik';
import React from 'react';
import { ExerciseFormInitialValues, ExerciseFormValidationSchema, ExerciseFormValues } from '.';
import TextInputField from '../../../components/AppForm/TextInputField';
import { useDispatch } from 'react-redux';
import { actions } from '../../../store';
import { addNewExercise, updateExercise } from '../../../api/Workout';
import { successToast } from '../../../toast';
import { useHistory } from 'react-router';
import { adminAppPaths } from '../../../AppRoutes';
import ExerciseCategorySelect from '../../../components/AppForm/ExerciseCategorySelect';
import AppCheckBox from '../../../components/AppForm/AppCheckBox';
import AppFileUpload from '../../../components/AppForm/AppFileUpload';

interface ExerciseFormProps {
    initialValues?: ExerciseFormValues
}

const ExerciseForm: React.FC<ExerciseFormProps> = ({
    initialValues
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = async (exercise: ExerciseFormValues) => {
        const response = initialValues ? await updateExercise(exercise) : await addNewExercise(exercise);

        if(response.status === 200) {
            initialValues ? dispatch(actions.exercises.updateExercise(response.data)) : dispatch(actions.exercises.addExercise(response.data));
            successToast(initialValues ? 'Exercise has been updated' : 'Exercise added to library');
            history.push(adminAppPaths.exerciseManager);
        }
    };

    return (
        <>
            <Formik
                validationSchema={ExerciseFormValidationSchema}
                onSubmit={handleSubmit}
                initialValues={initialValues ? initialValues : ExerciseFormInitialValues}>
                <Form>
                    <TextInputField
                        name='name'
                        label='Exercise name'
                        autofocus />
                    <TextInputField
                        name='video'
                        label='Vimeo video ID (e.g. 547261873)' />
                    <AppFileUpload
                        name='image'
                        label='Exercise image' />
                    <TextInputField
                        name='description'
                        label='Description'
                        multiline={true} />
                    <AppCheckBox
                        name='isolateral'
                        label='Is Isolateral exercise?' />
                    <ExerciseCategorySelect
                        name='category' />
                    <AppButton
                        type='submit'
                        variant='contained'
                        color='secondary'>
                        {initialValues ? 'Update Exercise' : 'Add Exercise'}
                    </AppButton>
                </Form>
            </Formik>
        </>
    );
};

export default ExerciseForm;