import { useContext } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { WorkoutStep } from '../../models/Workout';
import { WorkoutEditorContext } from './WorkoutEditorContext/WorkoutEditorContext';
import WorkoutEditorStepExercise from './WorkoutEditorStepExercise';
import { alphabet } from '.';

interface WorkoutEditorStepitem {
    index: number,
    step: WorkoutStep
}

const useStyles = makeStyles((theme) => ({
    item: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        boxShadow: theme.shadows[1]
    },
    itemHeader: {
        position: 'relative',
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    itemTitle: {
        fontWeight: theme.typography.fontWeightMedium
    },
    itemActions: {
        marginLeft: 'auto'
    }
}));

const WorkoutEditorStepItem: React.FC<WorkoutEditorStepitem> = ({
    index,
    step
}) => {
    const classes = useStyles();
    const { actions } = useContext(WorkoutEditorContext);

    const handleAddSet= () => {
        actions && actions.handleAddSet(step.stepId);
    };

    const handleDeleteStep = () => {
        actions && actions.handleDeleteStep(step.stepId);
    };

    return (
        <Draggable
            key={index}
            draggableId={step.stepId}
            index={index}>
            {(provided, snapshot) => {
                return (
                    <div
                        className={classes.item}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <div className={classes.itemHeader}>
                            <span className={classes.itemTitle}>
                                Exercise {index} {step.exercises.length > 1 ? '(SUPERSET)' : ''}
                            </span>
                            <div className={classes.itemActions}>
                                <Button size='small' onClick={handleAddSet}>+ Set</Button>
                                <Button size='small' onClick={handleDeleteStep}>Delete</Button>
                            </div>
                        </div>
                        {step.exercises.map((exercise, i) =>
                            <WorkoutEditorStepExercise stepId={step.stepId} exercise={exercise} supersetIdentifier={step.exercises.length > 1 ? alphabet[i] : undefined} key={i} />    
                        )}
                    </div>
                );}}
        </Draggable>
    );
};

export default WorkoutEditorStepItem;