import { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import config from '../config';
import { DatePicker } from '@material-ui/pickers';
import AppPageTitle from '../components/AppPageTitle';
import { makeStyles } from '@material-ui/core';
import { ChevronRight as RightArrow } from '@material-ui/icons';

interface ILog {
    _id?: string,
    date: Date,
    workoutId: string,
    workoutName: string
}

const useStyles = makeStyles(theme => ({
    datepicker: {
        '& input': {
            backgroundColor: theme.palette.grey[200],
            textAlign: 'center',
            borderRadius: theme.shape.borderRadius,
            width: 140,
            padding: '10px 0',
            fontWeight: theme.typography.fontWeightMedium
        },
        '& .MuiInput-underline:after': {
            display: 'none !important'
        },
        '& .MuiInput-underline:before': {
            display: 'none !important'
        }
    },
    logItem: {
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(4),
        alignItems: 'center',
        textDecoration: 'none',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary
    },
    icon: {
        width: '40px',
        height: '40px',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto'
    }
}));

const LogBookPage = () => {
    const classes = useStyles();
    const [logs, setLogs] = useState<ILog[] | undefined>(undefined);
    const [selectedDate, setSelectedDate] = useState<any>(new Date());

    const fetchLogs = async () => {
        const month = moment(selectedDate).format('M'),
            year = moment(selectedDate).format('YYYY');

        await fetch(`${config.apiBaseUrl}/v2/workout/log/month/${month}/year/${year}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
            .then(async (res) => {
                const data = await res.json();

                if(res.status === 200) {
                    setLogs(data);
                } else if (res.status === 401) {
                    window.location.href = '/';
                } else {
                    toast.error('Error fetching your progress pictures. Please try again.');
                }
            })
            .catch(err => {
                toast.error('Error fetching your progress pictures. Please try again.');
            });
    };

    useEffect(() => {
        fetchLogs();
    }, [selectedDate]);

    return (
        <>
            <div className="home-container">
                <div className="upload-library__header">
                    <AppPageTitle title='Log Book' icon='workout'>
                        <DatePicker 
                            className={classes.datepicker}
                            autoOk
                            variant="dialog"
                            minDate={new Date(2020, 5, 1)}
                            maxDate={new Date()}
                            value={selectedDate}
                            openTo="month"
                            views={['year', 'month']}
                            onChange={(date) => setSelectedDate(date as Date)} />
                    </AppPageTitle>
                    
                </div>
                {logs && logs.length > 0 ?
                    <>
                        {logs.map((log, index) => 
                            <>
                                <Link to={`/logbook/entry/${log.workoutId}`} className={classes.logItem} key={index}>
                                    <span><span className="log-date">{moment(log.date).format('Do MMM')}</span> |{log.workoutName ? ` ${log.workoutName}` : ' Workout'}</span>
                                    <div className={classes.icon}>
                                        <RightArrow />
                                    </div>
                                </Link>
                            </>
                        )}
                    </>
                    :
                    <>
                        <p>You do not currently have any logs for this month.</p>
                    </>
                }
            </div>
        </>
    );
};

export default LogBookPage;