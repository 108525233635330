import { makeStyles, TextField, Theme, IconButton } from '@material-ui/core';
import { HighlightOff as DeleteIcon } from '@material-ui/icons';
import { useContext } from 'react';
import { InstructionType, SetData, SetProps } from '../../models/Workout';
import { WorkoutEditorContext } from './WorkoutEditorContext/WorkoutEditorContext';

interface WorkoutEditorStepExerciseSetProps {
    set: SetData,
    type: InstructionType,
    index: number,
    stepId: string,
    stepExerciseId: string,
    supersetIdentifier?: string
}

interface RowProps {
    set: SetData,
    handleChange: (property: SetProps, value: unknown) => void,
    index: number
}

interface StyleProps {
    index: number
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    item: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 4px',
        backgroundColor: ({ index }) => index % 2 ? theme.palette.grey[200] : ''
    },
    itemTitle: {
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(2),
        flex: 'none'
    },
    itemAction: {
        marginLeft: 'auto'
    },
    itemInput: {
        marginRight: theme.spacing(1)
    }
}));

const RepsRow: React.FC<RowProps> = ({ set, handleChange, index }) => {
    const classes = useStyles({
        index
    });

    return (
        <>
            <div className={classes.itemInput}>
                <TextField variant='outlined' size='small' type='number' name='reps' label='Reps' value={set.reps} onChange={(e) => handleChange(e.target.name as SetProps, e.target.value)} />
            </div>
            <div className={classes.itemInput}>
                <TextField variant='outlined' size='small' type='number' name='weight' label='Weight (kg)' value={set.weight} onChange={(e) => handleChange(e.target.name as SetProps, e.target.value)} />
            </div>
        </>
    );
};

const HiitRow: React.FC<RowProps> = ({ set, handleChange, index }) => {
    const classes = useStyles({
        index
    });

    return (
        <>
            <div className={classes.itemInput}>
                <TextField variant='outlined' size='small' type='number' name='duration' label='Duration (mins)' value={set.duration} onChange={(e) => handleChange(e.target.name as SetProps, e.target.value)} />
            </div>
            <div className={classes.itemInput}>
                <TextField variant='outlined' size='small' type='number' name='on' label='On (s)' value={set.on} onChange={(e) => handleChange(e.target.name as SetProps, e.target.value)} />
            </div>
            <div className={classes.itemInput}>
                <TextField variant='outlined' size='small' type='number' name='off' label='Off (s)' value={set.off} onChange={(e) => handleChange(e.target.name as SetProps, e.target.value)} />
            </div>
        </>
    );
};

const LissRow: React.FC<RowProps> = ({ set, handleChange, index }) => {
    const classes = useStyles({
        index
    });
    
    return (
        <>
            <div className={classes.itemInput}>
                <TextField variant='outlined' size='small' type='number' name='duration' label='Duration (minutes)' value={set.duration} onChange={(e) => handleChange(e.target.name as SetProps, e.target.value)} />
            </div>
            <div className={classes.itemInput}>
                <TextField variant='outlined' size='small' type='number' name='speed' label='Speed' value={set.speed} onChange={(e) => handleChange(e.target.name as SetProps, e.target.value)} />
            </div>
        </>
    );
};

const WorkoutEditorStepExerciseSet: React.FC<WorkoutEditorStepExerciseSetProps> = ({
    set,
    type,
    index,
    stepId,
    stepExerciseId,
    supersetIdentifier
}) => {
    const classes = useStyles({
        index
    });
    const { actions } = useContext(WorkoutEditorContext);

    const handleChange = (property: SetProps, value: unknown) => {
        actions && actions.handleExerciseSetUpdate(stepId, stepExerciseId, set.stepExerciseSetId, property, value);
    };

    const handleDeleteSet = () => {
        actions && actions.handleDeleteSet(stepId, stepExerciseId, set.stepExerciseSetId);
    };

    return (
        <div className={classes.item}>
            <span className={classes.itemTitle}>Set {index}{supersetIdentifier}</span>
            {type === 'REPS' && <RepsRow set={set} handleChange={handleChange} index={index} />}
            {type === 'LISS' && <LissRow set={set} handleChange={handleChange} index={index} />}
            {type === 'HIIT' && <HiitRow set={set} handleChange={handleChange} index={index} />}
            <div className={classes.itemAction}>
                <IconButton size='small' onClick={handleDeleteSet}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default WorkoutEditorStepExerciseSet;