import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import BottomNav from './BottomNav';
import NavBar from './NavBar';
import SideBar from './SideBar';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: theme.palette.grey[100],
        paddingBottom: 110
    },
    content: {
        position: 'relative',
        padding: '90px 1rem 0 1rem'
    }
}));

const MainLayout: React.FC = ({
    children
}) => {
    const classes = useStyles();
    const location = useLocation();
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        setMenu(false);
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className={classes.root}>
            <NavBar openMenu={() => setMenu(true)} />
            <SideBar open={menu} closeMenu={(() => setMenu(false))} />
            <BottomNav />
            <div className={classes.content}>
                {children}
            </div>
        </div>
    );
};

export default MainLayout;