import { Card, makeStyles } from '@material-ui/core';

interface AppCardProps {
    className?: string
}

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.shape.borderRadius,
        padding: '1rem',
        boxShadow: 'none',
        // marginBottom: theme.spacing(2),
    },
}));

const AppCard: React.FC<AppCardProps> = ({
    className,
    children
}) => {
    const classes = useStyles();

    return (
        <Card className={`${classes.box} ${className}`}>
            {children}
        </Card>
    );
};

export default AppCard;