import { makeStyles } from '@material-ui/core';
import { DateRange as CalendarIcon } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { appPaths } from '../AppRoutes';
import { ScheduleItem } from '../models/Schedule';
import { useSchedule } from '../schedule/context/scheduleContext';
import { selectors } from '../store';
import config from '../config';
import AppButton from '../components/AppButton';

const useStyles = makeStyles((theme) => ({
    cardBase: {
        position: 'relative',
        display: 'block',
        textDecoration: 'none',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        height: '100%',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        overflow: 'hidden'
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '40%',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%)',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '40%',
            background: 'linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%)',
        }
    },
    cardInner: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        zIndex: 1,
        '& h2': {
            margin: 0,
            fontSize: '1rem'
        }
    },
    workoutName: {
        marginTop: 'auto',
        fontSize: 22,
        fontWeight: theme.typography.fontWeightBold,
        '& span': {
            display: 'block',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: 12,
            opacity: .75
        }
    },
    cardDecor: {
        position: 'absolute',
        fontWeight: theme.typography.fontWeightBold,
        top: -16,
        right: -10,
        opacity: .35,
        transform: 'rotate(40deg)',
        '& span:first-of-type': {
            fontSize: 54
        },
        '& span:nth-child(2)': {
            position: 'relative',
            fontSize: 36,
            top: 10,
            left: 8
        },
        '& span:last-of-type': {
            position: 'relative',
            fontSize: 22,
            top: 26,
            left: -40
        }
    },
    cardDecorAlt: {
        position: 'absolute',
        fontWeight: theme.typography.fontWeightBold,
        top: -16,
        right: -10,
        opacity: .35,
        transform: 'rotate(40deg)',
        '& span:first-of-type': {
            display: 'block',
            width: 54,
            height: 54,
            borderRadius: '50%',
            border: '6px solid rgba(255, 255, 255, .35)'
        },
        '& span:nth-child(2)': {
            position: 'relative',
            display: 'block',
            fontSize: 36,
            top: 10,
            left: 8,
            width: 36,
            height: 36,
            borderRadius: '50%',
            border: '4px solid rgba(255, 255, 255, .35)'
        },
        '& span:last-of-type': {
            position: 'relative',
            display: 'block',
            fontSize: 22,
            top: 16,
            left: -20,
            width: 22,
            height: 22,
            borderRadius: '50%',
            border: '5px solid rgba(255, 255, 255, .35)'
        }
    },
    emptyState: {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: theme.typography.fontWeightBold,
        justifyContent: 'space-between',
        flex: 1,
        textAlign: 'center',
        '& a': {
            backgroundColor: theme.palette.common.white
        },
        '& svg': {
            width: 42,
            height: 42,
            fill: theme.palette.common.white,
            margin: '0 auto'
        }
    }
}));

interface ItemContainerProps {
    scheduleItem?: ScheduleItem
}

const ItemContainer: React.FC<ItemContainerProps> = ({
    scheduleItem,
    children
}) => {
    const classes = useStyles();

    return (
        <>
            {scheduleItem ?
                <>
                    {scheduleItem.type === 'restday' && <div className={classes.cardBase}>{children}</div>}
                    {scheduleItem.type !== 'restday' && <Link to={`${appPaths.workoutWalkthrough}/${scheduleItem.workoutId}?gsid=${scheduleItem.guideScheduleId}`} className={classes.cardBase}>{children}</Link>}

                </>
                :
                <div className={classes.cardBase}>{children}</div>
            }
        </>
    );
};

const TodaysWorkoutCard: React.FC = () => {
    const classes = useStyles();
    const { todaysScheduleItem, guideData } = useSchedule();
    const [scheduleItem, setScheduleItem] = useState<ScheduleItem>();
    const workoutData = useSelector(selectors.workouts.getWorkoutById(scheduleItem && scheduleItem.workoutId ? scheduleItem.workoutId : ''));

    useEffect(() => {
        const item = todaysScheduleItem();

        setScheduleItem(item);
    }, []);

    return (
        <ItemContainer scheduleItem={scheduleItem}>
            {guideData?.image && <div className={classes.image}><img src={`${config.apiBaseUrl}/media/content/${guideData.image}`}  alt={guideData.name} /></div>}
            <div className={classes.cardInner}>
                {scheduleItem &&
                    <>
                        <h2>Today</h2>
                        <div className={classes.workoutName}>
                            <span>{guideData?.name}</span>
                            {scheduleItem.type === 'restday' ? 'Rest Day' : workoutData?.name}
                        </div>
                    </>
                }
                {!scheduleItem &&
                <div className={classes.emptyState}>
                    <CalendarIcon />
                    <div>No scheduled workouts</div>
                    <AppButton linkPath={appPaths.workout} fullWidth>View Guides</AppButton>
                </div>
                }
            </div>
            {scheduleItem && scheduleItem.type === 'restday' &&
                <div className={classes.cardDecor}>
                    <span>Z</span>
                    <span>Z</span>
                    <span>Z</span>
                </div>
            }
            {scheduleItem && scheduleItem.type !== 'restday' &&
                <div className={classes.cardDecorAlt}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            }
        </ItemContainer>
    );
};

export default TodaysWorkoutCard;