import { TextField, FormControlLabel, Checkbox, MenuItem, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { ChangeEvent, useContext } from 'react';
import AppForm from '../../components/AppForm';
import AppCheckBox from '../../components/AppForm/AppCheckBox';
import AppFileUpload from '../../components/AppForm/AppFileUpload';
import AppFormikSelect from '../../components/AppForm/AppFormikSelect';
import AppFormikSubmitButton from '../../components/AppForm/AppFormikSubmitButton';
import TextInputField from '../../components/AppForm/TextInputField';
import { WorkoutNew } from '../../models/Workout';
import { WorkoutEditorContext } from './WorkoutEditorContext/WorkoutEditorContext';
import FileUpload from '../../components/AppForm/FileUpload';
import { CheckBox } from '@material-ui/icons';
import AppTextField from '../../components/AppForm/AppTextField';

interface WorkoutEditorMetaProps {
    workoutData: WorkoutNew
}

const useStyles = makeStyles((theme) => ({
    item: {
        marginBottom: theme.spacing(2)
    }
}));

const WorkoutEditorMeta: React.FC<WorkoutEditorMetaProps> = ({
    workoutData
}) => {
    const classes = useStyles();
    const { actions } = useContext(WorkoutEditorContext);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        actions && actions.setWorkoutData({...workoutData, [e.target.name]: e.target.value});
    };

    return (
        <>
            <TextField 
                name='name' 
                label='Workout name' 
                variant='outlined' 
                fullWidth
                value={workoutData.name} 
                onChange={handleInputChange}
                className={classes.item} />
            <TextField 
                name='description' 
                label='Description' 
                variant='outlined' 
                fullWidth
                multiline
                value={workoutData.description} 
                onChange={handleInputChange}
                className={classes.item} />
            <FileUpload 
                name='image' 
                label='Workout cover image' 
                value={workoutData.image}
                onChange={(name, value) => actions && actions.setWorkoutData({...workoutData, [name]: value})}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        name='published'
                        checked={Boolean(workoutData.published)}
                        onChange={handleInputChange}
                        color="primary"
                    />
                }
                label='Published?'
            />
            <FormControlLabel
                control={
                    <Checkbox
                        name='premium'
                        checked={Boolean(workoutData.premium)}
                        onChange={handleInputChange}
                        color="primary"
                    />
                }
                label='Premium?'
            />
            <AppTextField
                select
                value={workoutData.level}
                name='level'
                onChange={handleInputChange}>
                <MenuItem value='Beginner'>Beginner</MenuItem>
                <MenuItem value='Intermediate'>Intermediate</MenuItem>
                <MenuItem value='Advanced'>Advanced</MenuItem>
            </AppTextField>
        </>
    );
};

export default WorkoutEditorMeta;