import axios, { AxiosResponse } from 'axios';
import { WorkoutLog } from '../models/Logging';
import { buildApiUrl } from './common';
import { WorkoutLogDto } from './models/Log';

export const addWorkoutLog = async (data: WorkoutLogDto): Promise<AxiosResponse<void>> => {
    const url = buildApiUrl('v2/log');

    const response = await axios.post(url, data, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getWorkoutLog = async (): Promise<AxiosResponse<WorkoutLog[]>> => {
    const url = buildApiUrl('v2/log');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};