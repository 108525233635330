import { makeStyles } from '@material-ui/core';
import { Restaurant as NutritionIcon } from '@material-ui/icons';
import { appPaths } from '../AppRoutes';
import AppButton from '../components/AppButton';
import AppCard from '../components/AppCard';

const useStyles = makeStyles(theme => ({
    cardBase: {
        height: 220,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center'
    },
    icon: {
        width: 42,
        height: 42,
    },
    text: {
        fontWeight: theme.typography.fontWeightBold,
    },
    btn: {
        marginTop: 'auto'
    }
}));

const NutritionEmptyState: React.FC = () => {
    const classes = useStyles();

    return (
        <AppCard className={classes.cardBase}>
            <NutritionIcon className={classes.icon} />
            <div className={classes.text}>
                Calculate your nutrition targets
            </div>
            <AppButton linkPath={appPaths.nutrition} color='secondary' className={classes.btn}>My Nutrition</AppButton>
        </AppCard>
    );
};

export default NutritionEmptyState;