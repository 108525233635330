import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '../../config';
import { selectors } from '../../store';
import LogBookEntryExercise from './LogBookEntryExercise';

interface PageParams {
    id: string
}

interface ExerciseHeaderProps {
    exerciseId: string
}

interface ExerciseSetProps {
    log: any,
    setNo: number
}

const ExerciseHeader: React.FC<ExerciseHeaderProps> = ({
    exerciseId
}) => {
    const exercise = useSelector(selectors.exercises.getById(exerciseId));

    return (
        <>
            {exercise &&
                <h3>{exercise.name}</h3>
            }
        </>
    );
};

const ExerciseSet: React.FC<ExerciseSetProps> = ({
    log,
    setNo
}) => {
    return (
        <div>
            Set {setNo} | {log.reps ? `${log.reps} Reps` : 'N/A'} | {log.weight ? `${log.weight} KG` : 'N/A'}
        </div>
    );
};

const LogBookEntryPage: React.FC = () => {
    const { id } = useParams<PageParams>();
    const [workout, setWorkout] = useState<any>();
    const [log, setLog] = useState<any>();
    const [logItems, setLogItems] = useState<any[]>([]);

    const getLogBookDetail = async () => {
        await fetch(`${config.apiBaseUrl}/v2/workout/log/detail/${id}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
            .then(async (res) => {
                const data = await res.json();

                if(res.status === 200) {
                    setWorkout(data.workoutItem);
                    setLog(data.log);
                    setLogItems(data.logItems);
                } else if (res.status === 401) {
                    window.location.href = '/';
                } else {
                    toast.error('Error fetching log details. Please try again.');
                }
            })
            .catch(err => {
                toast.error('Error fetching log details. Please try again.');
            });
    };

    useEffect(() => {
        getLogBookDetail();
    }, []);
    
    return (
        <>
            {workout && log && logItems &&
            <>
                <h2>{moment(log.date).format('Do MMM')} | {workout.name}</h2>
                <hr />
                {workout.steps.map((step: any) => 
                    <>
                        {step.exercises.map((exercise: any) => 
                            <>
                                {exercise.type === 'REPS' &&
                                <>
                                    <ExerciseHeader exerciseId={exercise.exerciseId} />
                                    {exercise.sets.map((set: any, i: number) => {
                                        const log = logItems.find(item => item.stepExerciseSetId === set.stepExerciseSetId);
                                        return log ? <ExerciseSet key={set} log={log} setNo={i + 1} /> : undefined;
                                    }
                                    )}
                                </>
                                }
                            </>
                        )}
                    </>
                )}
            </>
            }
        </>
    );
};

export default LogBookEntryPage;