import Logo from '../assets/logo-white.svg';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import RegisterForm from '../authentication/RegisterForm/RegisterForm';
import React, { useState, useContext } from 'react';
import * as yup from 'yup'; 
import { forgottenPassword } from '../api/Authentication';
import AppTextField from '../components/AppForm/AppTextField';
import AppButton from '../components/AppButton';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        display: 'flex',
        backgroundColor: '#D5DDCC',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 200
    },
    formSection: {
        boxShadow: 'none'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& *': {
            width: '100%'
        }
    },
    logo: {
        width: '100%',
        maxWidth: '240px'
    },
    btn: {
        marginTop: theme.spacing(2)
    }
}));

const ForgottenPasswordPage: React.FC = () => {
    const classes = useStyles();
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [complete, setComplete] = useState<boolean>(false);
    const emailSchema = yup.string().required().email();

    const submitResetRequest = async () => {
        const isValid = await emailSchema.isValid(email);

        if(isValid) {
            setError(undefined);

            const result = await forgottenPassword(email);

            if(result.status === 200) {
                setComplete(true);
            } else {
                setError('There was a problem trying to reset password. Please try again.');
            }

        } else {
            setError('Please enter a valid email address.');
        }
    };

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                <img className={classes.logo} src={Logo} alt="Stronger You Logo" />
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={classes.formSection}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Forgotten password
                    </Typography>
                    {complete ?
                        <>
                            <p>We have sent a password reset link to <b>{email}</b>. Please check your junk/spam folder if it is not in your inbox. If you do not receive this email please get in contact with us.</p>
                        </>
                        :
                        <>
                            <p>If you are having issues logging in or have forgotten your passsword simply enter your email address in the box below and we will send you a password reset link.</p>
                            <div className="form-item">
                                <AppTextField fullWidth label='Email address' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            {error && <div className="form-error">{error}</div>}
                            <AppButton className={classes.btn} color='secondary' fullWidth onClick={() => submitResetRequest()}>Reset Password</AppButton>
                        </>
                    }
                </div>
            </Grid>
        </Grid>
    );
};

export default ForgottenPasswordPage;