import { makeStyles } from '@material-ui/core';
import { ContextType, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import AppPageTitle from '../../components/AppPageTitle';
import { WorkoutNew } from '../../models/Workout';
import { workoutInitialValues } from './index';
import WorkoutEditorExerciseLibrary from './WorkoutEditorExerciseLibrary/WorkoutEditorExerciseLibrary';
import WorkoutEditorMeta from './WorkoutEditorMeta';
import WorkoutEditorStepContainer from './WorkoutEditorStepContainer';
import { WorkoutEditorContext } from './WorkoutEditorContext/WorkoutEditorContext';
import AppButton from '../../components/AppButton';

export interface WorkoutEditorProps {
    initialValues?: WorkoutNew,
    handleSave: (workout: WorkoutNew, update?: boolean) => void
}

const useStyles = makeStyles((theme) => ({
    editorContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gridGap: '1rem'
    },
    actions: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

const WorkoutEditor: React.FC<WorkoutEditorProps> = ({
    initialValues = workoutInitialValues,
    handleSave
}) => {
    const classes = useStyles();
    const { workoutData, actions } = useContext(WorkoutEditorContext);
    
    useEffect(() => {
        actions && actions.setWorkoutData(initialValues);
    }, [initialValues]);

    const saveWorkout = () => {
        workoutData && handleSave(workoutData, workoutData._id ? true : false);
    };

    return (
        <>
            {workoutData && actions &&
            <>
                <AppPageTitle title='Workout Editor' />
                <br />
                <WorkoutEditorMeta workoutData={workoutData} />
                <DragDropContext
                    onDragEnd={actions.handleDragNDrop}>
                    <div className={classes.editorContainer}>
                        <WorkoutEditorStepContainer workoutSteps={workoutData.steps} />
                        <WorkoutEditorExerciseLibrary />
                    </div>
                </DragDropContext>
                <div className={classes.actions}>
                    <AppButton disabled={workoutData.name !== '' ? false : true} onClick={saveWorkout}>{workoutData._id ? 'Update Workout' : 'Save Workout'}</AppButton>
                </div>
            </>
            }
        </>
    );
};

export default WorkoutEditor;