import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './style.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import AppRoutes from './AppRoutes';
import AppTheme from './AppTheme';
import store from './store';
import { AuthProvider } from './authentication/authContext/authContext';
import WorkoutWalkthroughProvider from './workout/WorkoutWalkthrough/Context/WorkoutWalkthroughProvider';
import ScheduleProvider from './schedule/context/scheduleProvider';
import NutritionProvider from './nutrition/context/NutritionProvider';
import LoggingProvider from './Logging/context/LoggingProvider';
import config from './config';

const stripePromise = loadStripe(config.stripePk);

const App: React.FC = () => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ToastContainer />
            <CssBaseline />
            <AppTheme>
                <Elements stripe={stripePromise}>
                    <Router>
                        <AuthProvider>
                            <Provider store={store}>
                                <NutritionProvider>
                                    <ScheduleProvider>
                                        <LoggingProvider>
                                            <ToastContainer />
                                            <WorkoutWalkthroughProvider>
                                                <AppRoutes />
                                            </WorkoutWalkthroughProvider>
                                        </LoggingProvider>
                                    </ScheduleProvider>
                                </NutritionProvider>
                            </Provider>
                        </AuthProvider>
                    </Router>
                </Elements>
            </AppTheme>
        </MuiPickersUtilsProvider>
    );
};

export default App;
