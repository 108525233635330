import { Card, IconButton, Badge, Avatar, Chip, makeStyles } from '@material-ui/core';
import { AccountCircle as AccountIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { buildApiUrl } from '../api/common';
import { appPaths } from '../AppRoutes';
import { useAuth } from '../authentication/authContext/authContext';

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.shape.borderRadius,
        padding: '2rem 1rem',
        boxShadow: 'none',
        marginBottom: theme.spacing(2),
    },
    boxInner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginBottom: theme.spacing(2)
    },
    name: {
        fontSize: '1.2rem',
        marginBottom: theme.spacing(2),
        fontWeight: 500
    },
    notificationIcon: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    premium: {
        backgroundColor: '#FFD700'
    },
    admin: {
        backgroundColor: '#1937e3',
        color: theme.palette.common.white
    }
}));

const ProfileCard: React.FC = () => {
    const classes = useStyles();
    const auth = useAuth();

    const avatarUrl = buildApiUrl(`media/${auth.state?.user.avatar}`);

    return (
        <Card className={classes.box}>
            <Link to={appPaths.profile}>
                <IconButton className={classes.notificationIcon}>
                    <AccountIcon />
                </IconButton>
            </Link>
            <div className={classes.boxInner}>
                {auth.state?.user.avatar ?
                    <Avatar className={classes.avatar} src={avatarUrl} />
                    :
                    <Avatar className={classes.avatar}>S</Avatar>
                }
                <div className={classes.name}>
                    {auth.state?.user.firstname} {auth.state?.user.lastname}
                </div>
                <Chip
                    className={auth.state?.user.type === 'premium' ? classes.premium : auth.state?.user.type === 'admin' ? classes.admin : ''}
                    label={`${auth.state?.user.type.charAt(0).toUpperCase()}${auth.state?.user.type.slice(1)}`}
                    color="default"
                />
            </div>
        </Card>
    );
};

export default ProfileCard;