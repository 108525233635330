import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { actions, selectors, useDispatchEffect } from '../../store';
import { useAuth } from '../authContext/authContext';

const InitialisationLayer: React.FC = ({
    children
}) => {
    const auth = useAuth();
    const exercises = useSelector(selectors.exercises.all);
    const exercisesInitialised = useSelector(selectors.exercises.isInitialised);
    const workouts = useSelector(selectors.workouts.all);
    const workoutsInitialised = useSelector(selectors.workouts.isInitialised);
    const recipes = useSelector(selectors.recipes.all);
    useDispatchEffect(actions.exercises.fetchAll);
    useDispatchEffect(actions.workouts.fetchAll);
    useDispatchEffect(actions.recipes.fetchAll);

    useEffect(() => {
        auth.actions?.setIsLoading(true);
    }, []);

    useEffect(() => {
        if(exercisesInitialised && workoutsInitialised && recipes.length > 0) {
            auth.actions?.setInitialisedState(true);
            auth.actions?.setIsLoading(false);
        }
    }, [exercises, workouts, recipes]);

    return (
        <>
            {auth.state?.initialised &&
            <>
                {children}
            </>
            }
        </>
    );
};

export default InitialisationLayer;