import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreIcon } from '@material-ui/icons';
import { useState } from 'react';
import AppCard from '../components/AppCard';
import config from '../config';
import { DiaryItem } from '../models/Nutrition';
import { useNutrition } from './context/NutritionContext';

export interface NutritionDiaryItemProps {
    item: DiaryItem
}

const useStyles = makeStyles(theme => ({
    cardBase: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    image: {
        position: 'relative',
        flex: 'none',
        width: 52,
        height: 52,
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: theme.palette.grey[200],
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    info: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        height: 52
    },
    name: {
        fontWeight: theme.typography.fontWeightBold
    },
    macros: {
        display: 'flex',
        marginTop: 'auto',
        fontSize: '.75rem',
        color: theme.palette.grey[600]
    },
    macroItem: {
        display: 'flex',
        alignItems: 'center',
        flex: 1
    },
    protein: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(251,179,182)',
        marginRight: theme.spacing(1)
    },
    carbs: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(171,241,255)',
        marginRight: theme.spacing(1)
    },
    fats: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(134,245,134)',
        marginRight: theme.spacing(1)
    },
    moreBtn: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        width: 20,
        height: 20
    }
}));

const NutritionDiaryItem: React.FC<NutritionDiaryItemProps> = ({
    item
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { removeDiaryItem } = useNutrition();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRemove = () => {
        removeDiaryItem(item.eid);
        handleClose();
    };

    return (
        <AppCard className={classes.cardBase}>
            <div className={classes.image}>
                {item.image && <img src={`${config.apiBaseUrl}/media/${item.image}`} alt={item.name} />}
            </div>
            <div className={classes.info}>
                <div className={classes.name}>{item.name}</div>
                <div className={classes.macros}>
                    <div className={classes.macroItem}>
                        {(item.kcals * item.servings).toFixed(0)} kcal
                    </div>
                    <div className={classes.macroItem}>
                        <span className={classes.protein}></span>
                        {(item.protein * item.servings).toFixed(0)}g
                    </div>
                    <div className={classes.macroItem}>
                        <span className={classes.carbs}></span>
                        {(item.carbohydrates * item.servings).toFixed(0)}g
                    </div>
                    <div className={classes.macroItem}>
                        <span className={classes.fats}></span>
                        {(item.fats * item.servings).toFixed(0)}g
                    </div>
                </div>
            </div>
            <IconButton className={classes.moreBtn} onClick={handleClick}>
                <MoreIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleRemove}>Remove</MenuItem>
            </Menu>
        </AppCard>
    );
};

export default NutritionDiaryItem;