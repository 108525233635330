import Logo from '../assets/logo-white.svg';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import RegisterForm from '../authentication/RegisterForm/RegisterForm';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        display: 'flex',
        backgroundColor: '#D5DDCC',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 200
    },
    formSection: {
        boxShadow: 'none'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: '100%',
        maxWidth: '240px'
    },
}));

const RegisterPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                <img className={classes.logo} src={Logo} alt="Stronger You Logo" />
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={classes.formSection}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Register
                    </Typography>
                    <RegisterForm />
                </div>
            </Grid>
        </Grid>
    );
};

export default RegisterPage;