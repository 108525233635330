import { makeStyles } from '@material-ui/core';
import config from '../../config';
import { Recipe } from '../../models/Recipes';

export interface RecipeItemProps {
    recipe: Recipe,
    handleSelect: () => void
}

const useStyles = makeStyles(theme => ({
    item: {
        positon: 'relative',
        marginBottom: theme.spacing(2),
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    image: {
        position: 'relative',
        width: '100%',
        height: 160,
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    name: {
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: 'center',
        marginTop: theme.spacing(1),
        textDecoration: 'none'
    }
}));

const RecipeItem: React.FC<RecipeItemProps> = ({
    recipe,
    handleSelect
}) => {
    const classes = useStyles();

    return (
        <div className={classes.item} onClick={handleSelect}>
            <div className={classes.image}>
                <img src={`${config.apiBaseUrl}/media/${recipe.image}`} alt={recipe.name} />
            </div>
            <div className={classes.name}>
                {recipe.name}
            </div>
        </div>
    );
};

export default RecipeItem;