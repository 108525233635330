import { makeStyles } from '@material-ui/core';
import { Timer as TimerIcon, FitnessCenter as WorkoutIcon, Stars as StarIcon } from '@material-ui/icons';
import { useEffect } from 'react';
import AppCard from '../components/AppCard';
import { useLogging } from '../Logging/context/LoggingContext';

const useStyles = makeStyles((theme) => ({
    cardBase: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        justifyContent: 'space-around',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    statItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    statIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 46,
        height: 46,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        '& svg': {
            fill: theme.palette.secondary.main,
        }
    },
    statValue: {
        fontSize: 18,
        margin: '6px 0 0',
        fontWeight: theme.typography.fontWeightBold
    },
    statKey: {
        fontSize: 14,
        opacity: .75
    }
}));

const WeeklyWorkoutStats: React.FC = () => {
    const classes = useStyles();
    const { statsCard, getCurrentWeek } = useLogging();

    useEffect(() => {
        getCurrentWeek();
    }, []);

    return (
        <AppCard className={classes.cardBase}>
            <div className={classes.statItem}>
                <div className={classes.statIcon}>
                    <WorkoutIcon />
                </div>
                <div className={classes.statValue}>
                    {statsCard?.workouts}
                </div>
                <div className={classes.statKey}>
                    Workouts
                </div>
            </div>
            <div className={classes.statItem}>
                <div className={classes.statIcon}>
                    <TimerIcon />
                </div>
                <div className={classes.statValue}>
                    {statsCard?.time}
                </div>
                <div className={classes.statKey}>
                    Time
                </div>
            </div>
            <div className={classes.statItem}>
                <div className={classes.statIcon}>
                    <StarIcon />
                </div>
                <div className={classes.statValue}>
                    {statsCard?.pbs}
                </div>
                <div className={classes.statKey}>
                    PBs
                </div>
            </div>
        </AppCard>
    );
};

export default WeeklyWorkoutStats;