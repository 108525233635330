import React, { Context, createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getMyDetails } from '../../api/Authentication';
import { appPaths } from '../../AppRoutes';
import SolidLoader from '../../components/Loading/SolidLoader';
import { User } from '../../models/User';

type AuthProviderProps = {
    children?: any
}

interface AuthContext {
    state?: {
        isLoggedIn: boolean,
        initialised: boolean,
        user: User
    },
    actions?: {
        setLoggedInState: (val: boolean) => void,
        setUserDetails: (values: User) => void,
        setInitialisedState: (val: boolean) => void,
        setIsLoading: (val: boolean) => void,
        logout: () => void,
        refetch: () => void
    }
}

const initialUserValues: User = {
    _id: '',
    avatar: '',
    email: '',
    firstname: '',
    lastname: '',
    lastActive: '',
    marketing: false,
    terms: false,
    trial: false,
    type: 'free'
};

const AuthContext = createContext({});

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const history = useHistory();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [initialised, setInitialised] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<User>({...initialUserValues});

    const checkAuthState = () => {
        const loggedIn = sessionStorage.getItem('isLoggedIn'),
            user = sessionStorage.getItem('user');

        if(loggedIn && user) {
            setIsLoggedIn(true);
            setUser(JSON.parse(user));
        }

        setIsLoading(false);
    };

    // const authenticationCheck = useCallback(async () => {
    //     setLoading(true);
        
    //     await fetch(`${globalVariables.api}/auth/check`, {
    //         method: 'POST',
    //         credentials: 'include',
    //         mode: 'cors',
    //         headers: new Headers({
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         })
    //         })
    //         .then(res => {
    //             if(res.status === 200) {
    //                 setInitialAppData();
    //             } else if (res.status === 401) {
    //                 window.location.href = "/";
    //             } else {
    //                 setIsLoggedIn(false);
    //                 setInitialised(true);
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             setIsLoggedIn(false);
    //             setInitialised(true);
    //         });

    //         setLoading(false);
    // }, []);

    const refetch = async () => {
        const result = await getMyDetails();

        if(result.status === 200) {
            setUser(result.data);
        }
    };

    const logout = () => {
        setIsLoading(true);
        sessionStorage.removeItem('isLoggedIn'),
        sessionStorage.removeItem('user');
        setIsLoggedIn(false);
        history.push(appPaths.home);
        setIsLoading(false);
    };

    useEffect(() => {
        checkAuthState();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                state: {
                    isLoggedIn,
                    initialised,
                    user
                },
                actions: {
                    setLoggedInState: (val: boolean) => setIsLoggedIn(val),
                    setUserDetails: (values: User) => setUser(values),
                    setInitialisedState: (val: boolean) => setInitialised(val),
                    setIsLoading: (val: boolean) => setIsLoading(val),
                    logout: () => logout(),
                    refetch
                }
            }}>
            <>
                {children}
                <>
                    {isLoading && <SolidLoader />}
                </>
            </>
        </AuthContext.Provider>
    );
};

function useAuth(): AuthContext {
    return useContext<AuthContext>(AuthContext);
}

export { AuthProvider, useAuth };