import { Button } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHttpRequest } from '../../api';
import { getAllChallenges, getAllGuides } from '../../api/Workout';
import { adminAppPaths } from '../../AppRoutes';
import AppPageHeading from '../../components/AppPageHeading';
import CreateGuideModal from '../../workout/admin/CreateGuideModal/CreateGuideModal';

const WorkoutManagerPage: React.FC = () => {
    const [createGuideModal, setCreateGuideModal] = useState(false);
    const getGuides = useCallback(() => getAllGuides(), []);
    const getChallenges = useCallback(() => getAllChallenges(), []);
    const { result: guides, isLoading } = useHttpRequest(getGuides);
    const { result: challenges } = useHttpRequest(getChallenges);

    return (
        <>
            <AppPageHeading
                title='Workout Manager' />
            <Button onClick={() => setCreateGuideModal(true)}>New Guide +</Button>
            <h2>Guides</h2>
            {guides && guides.map(guide =>
                <Link to={`${adminAppPaths.guideBuilder}/${guide._id}`} key={guide._id} style={{
                    display: 'block',
                    width: '100%',
                    padding: '2rem',
                    backgroundColor: 'white',
                    marginBottom: '1rem'
                }}>
                    {guide.name}
                </Link>    
            )}
            <hr />
            <h2>Challenges</h2>
            {challenges && challenges.map(guide =>
                <Link to={`${adminAppPaths.guideBuilder}/${guide._id}`} key={guide._id} style={{
                    display: 'block',
                    width: '100%',
                    padding: '2rem',
                    backgroundColor: 'white',
                    marginBottom: '1rem'
                }}>
                    {guide.name}
                </Link>    
            )}
            <CreateGuideModal
                open={createGuideModal}
                handleClose={() => setCreateGuideModal(false)} />
        </>
    );
};

export default WorkoutManagerPage;