import { createContext, useContext } from 'react';
import { SetLog, WorkoutNewFull } from '../../../models/Workout';

export interface IWorkoutWalkthroughContext {
    workout?: WorkoutNewFull,
    currentStep: number,
    timer: string,
    timeCapture?: string,
    guideScheduleId?: string,
    setWorkout: (workout: WorkoutNewFull) => void,
    setCurrentStep: (step: number) => void,
    setGuideScheduleId: (id: string) => void,
    getLogById: (id: string) => SetLog | undefined,
    updateSetLog: (id: string, property: 'reps' | 'weight', value: number) => void,
    completeWorkout: () => void,
    resetWorkoutWalkthrough: () => void
}

const placeholderfunc = () => { throw new Error('Not implemented.'); };

export const WorkoutWalkthroughContext = createContext<IWorkoutWalkthroughContext>({
    workout: undefined,
    currentStep: 0,
    timer: '00:00:00',
    setWorkout: placeholderfunc,
    setCurrentStep: placeholderfunc,
    setGuideScheduleId: placeholderfunc,
    getLogById: placeholderfunc,
    updateSetLog: placeholderfunc,
    completeWorkout: placeholderfunc,
    resetWorkoutWalkthrough: placeholderfunc
});

export const useWorkoutWalkthrough: () => IWorkoutWalkthroughContext = () => useContext(WorkoutWalkthroughContext);