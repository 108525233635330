import MissingConfigError from './MissingConfigError';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const stripePk = process.env.REACT_APP_STRIPE_PK;

if (!apiBaseUrl) throw new MissingConfigError('REACT_APP_API_BASE_URL');
if (!stripePk) throw new MissingConfigError('REACT_APP_STRIPE_PK');

const config = {
    apiBaseUrl,
    stripePk
};

export default config;