import { AppBar, Toolbar, IconButton, Button, Typography, makeStyles } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import Logo from '../assets/logo-white.svg';

interface NavBarProps {
    openMenu: () => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
        color: theme.palette.grey[700],
    },
    toolbar: {
        height: 74
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        // width: 232,
        height: '100%',
        justifyContent: 'center'
    },
    logo: {
        height: '24px'
    },
    rhs: {
        marginLeft: 'auto'
    }
}));

const NavBar: React.FC<NavBarProps> = ({
    openMenu
}) => {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.root}>
            <Toolbar className={classes.toolbar}>
                <div className={classes.logoContainer}>
                    <img className={classes.logo} src={Logo} alt="Stronger You Logo" />
                </div>
                <IconButton className={classes.rhs} onClick={openMenu}>
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;