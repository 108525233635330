import { makeStyles, CircularProgress } from '@material-ui/core';

export interface CardLoaderProps {
    loading: boolean
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.secondary.main
    }
}));

const CardLoader: React.FC<CardLoaderProps> = ({
    loading
}) => {
    const classes = useStyles();

    return (
        <>
            {loading && 
                <div className={classes.container}>
                    <CircularProgress />
                </div>
            }
        </>
    );
};

export default CardLoader;