import AppPageHeading from '../../components/AppPageHeading';
import GuideEditor from '../../workout/GuideEditor/GuideEditor';

const GuideEditorPage: React.FC = () => {
    return (
        <>
            <AppPageHeading
                title='Guide Editor' />
            <GuideEditor />
        </>
    );
};

export default GuideEditorPage;