import { makeStyles } from '@material-ui/core';
import { DateRange as CalendarIcon } from '@material-ui/icons';
import AppPageTitle from '../components/AppPageTitle';
import { useSchedule } from '../schedule/context/scheduleContext';
import CurrentSchedule from '../schedule/CurrentSchedule';
import ScheduleListItem from '../schedule/ScheduleListItem';
import AppButton from '../components/AppButton';
import { appPaths } from '../AppRoutes';

const useStyles = makeStyles(theme => ({
    emptyState: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 400,
    },
    icon: {
        width: 62,
        height: 62
    },
    text: {
        fontSize: '1.25rem',
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2)
    }
}));

const SchedulePage: React.FC = () => {
    const classes = useStyles();
    const { guideData, scheduleByDate } = useSchedule();
    const scheduleItems = scheduleByDate(new Date());

    return (
        <>
            <AppPageTitle title='My Schedule' icon='calendar' />
            {guideData ?
                <>
                    <CurrentSchedule />
                    {scheduleItems.map(item =>
                        <ScheduleListItem scheduleItem={item} key={item._id} />   
                    )}
                </>
                :
                <div className={classes.emptyState}>
                    <CalendarIcon className={classes.icon} />
                    <div className={classes.text}>No scheduled workouts</div>
                    <AppButton linkPath={appPaths.workout}>View Guides</AppButton>
                </div>
            }
        </>
    );
};

export default SchedulePage;