import { makeStyles } from '@material-ui/core';
import config from '../../../config';
import { Exercise } from '../../../models/Workout';

interface WorkoutEditorExerciseLibraryItemProps {
    exercise: Exercise
}

const useStyles = makeStyles((theme) => ({
    item: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        padding: '4px'
    },
    itemImage: {
        position: 'relative',
        flex: 'none',
        marginRight: theme.spacing(2),
        width: 40,
        height: 40,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[300],
        overflow: 'hidden',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        }
    },
    itemName: {
        fontWeight: theme.typography.fontWeightMedium
    }
}));

const WorkoutEditorExerciseLibraryItem: React.FC<WorkoutEditorExerciseLibraryItemProps> = ({
    exercise
}) => {
    const classes = useStyles();

    return (
        <div className={classes.item}>
            <div className={classes.itemImage}>
                {exercise.image && <img src={`${config.apiBaseUrl}/media/content/${exercise.image}`} alt={exercise.name} />}
            </div>
            <div className={classes.itemName}>{exercise.name}</div>
        </div>
    );
};

export default WorkoutEditorExerciseLibraryItem;