import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ArrowForward as ArrowIcon, Close as CloseIcon } from '@material-ui/icons';
import { appPaths } from '../../AppRoutes';
import { useWorkoutWalkthrough } from './Context/WorkoutWalkthroughContext';
import AppButton from '../../components/AppButton';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    floater: {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        bottom: theme.spacing(12),
        right: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: 30,
        backgroundColor: theme.palette.secondary.main,
        textDecoration: 'none',
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        zIndex: 100,
    },
    resumeLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        '& > span': {
            display: 'block',
            backgroundColor: 'rgba(255, 255, 255, .3)',
            padding: theme.spacing(1),
            borderRadius: 30
        }
    },
    icon: {
        fill: theme.palette.common.white,
        marginLeft: theme.spacing(1)
    },
    closeBtn: {
        padding: theme.spacing(.5),
        backgroundColor: 'rgba(0, 0, 0, .2)',
        marginRight: theme.spacing(1)
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const ResumeFloater: React.FC = () => {
    const { workout, guideScheduleId, resetWorkoutWalkthrough } = useWorkoutWalkthrough();
    const classes = useStyles();
    const [confirmCancel, setConfirmCancel] = useState(false);

    const cancelWorkout = () => {
        resetWorkoutWalkthrough();
        setConfirmCancel(false);
    };

    return (
        <>
            {workout &&
                <div className={classes.floater}>
                    <IconButton className={classes.closeBtn} onClick={() => setConfirmCancel(true)}>
                        <CloseIcon />
                    </IconButton>
                    <Link className={classes.resumeLink} to={`${appPaths.workoutWalkthrough}/${workout._id}?${guideScheduleId && `gsid=${guideScheduleId}`}`}>
                        <span>Resume</span>
                        <ArrowIcon className={classes.icon} />
                    </Link>
                </div> 
            }
            <Dialog 
                open={confirmCancel}
                onClose={() => setConfirmCancel(false)}>
                <DialogTitle>Cancel current workout</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to stop your active workout?</p>
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <AppButton variant='text' onClick={() => setConfirmCancel(false)}>Cancel</AppButton>
                    <AppButton color='secondary' onClick={cancelWorkout}>Confirm</AppButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ResumeFloater;