import axios, { AxiosResponse } from 'axios';
import { User } from '../models/User';
import { buildApiUrl } from './common';
import { ApiLogin, ApiLoginResponse, ApiRegisterResponse, RegisterDto, ResetForgottenPasswordDto } from './models/Authentication';

export const loginUser = async (payload: ApiLogin): Promise<AxiosResponse<ApiLoginResponse>> => {
    const url = buildApiUrl('auth/login');

    const response = await axios.post(url, payload, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
};

export const registerUser = async (payload: RegisterDto): Promise<AxiosResponse<ApiRegisterResponse>> => {
    const url = buildApiUrl('auth/v2/register');

    const response = await axios.post(url, payload, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
};

export const forgottenPassword = async (email: string): Promise<AxiosResponse<void>> => {
    const url = buildApiUrl('user/reset');

    const response = await axios.post(url, { email }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
};

export const resetForgottenPassword = async (payload: ResetForgottenPasswordDto): Promise<AxiosResponse<void>> => {
    const url = buildApiUrl('user/reset');

    const response = await axios.put(url, { ...payload }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
};

export const updateUserDetails = async (payload: any): Promise<AxiosResponse<void>> => {
    const url = buildApiUrl('user');

    const response = await axios.put(url, { ...payload }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getMyDetails = async (): Promise<AxiosResponse<User>> => {
    const url = buildApiUrl('user');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const authCheck = async (): Promise<AxiosResponse<void>> => {
    const url = buildApiUrl('auth/check');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};