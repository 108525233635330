import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { CameraAlt as CameraIcon } from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DatePicker } from '@material-ui/pickers';
import config from '../../config';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import SolidLoader from '../../components/Loading/SolidLoader';

const useStyles = makeStyles(() => ({
    photoGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '1rem'
    },
    datePicker: {
        marginBottom: '2rem',
        '& *': {
            fontWeight: 'bold'
        }
    },
    uploadBox: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '180px',
        marginBottom: '2rem',
        border: '2px dashed gray',
        '& > input[type=file]': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0
        }
    },
    uploadBoxInner: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const ProgressPhotos: React.FC = () => {
    const classes = useStyles();

    const [dates, setDates] = useState<any>(undefined);
    const [progress, setProgress] = useState<any>(undefined);
    const [selectedDate, setSelectedDate] = useState<any>(new Date());
    const [loading, setLoading] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState<number>(0);
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);

    const getProgress = async () => {
        setLoading(true);
        const month = moment(selectedDate).format('M'),
            year = moment(selectedDate).format('YYYY');

        await fetch(`${config.apiBaseUrl}/progress/month/${month}/year/${year}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        })
            .then(async (res) => {
                const data = await res.json();

                if(res.status === 200) {
                    const manipulate = [...data];
                    let dates: any[] = [];

                    manipulate.forEach(item => {
                        item.date = moment(item.date).format('DD-MM-YYYY');
                        dates.push(item.date);
                    });

                    dates = dates.filter((v, i, a) => a.indexOf(v) === i);

                    data.forEach((item: any, index: any) => {
                        item.order = index;
                        item.image = `${config.apiBaseUrl}/media/progress/${item.image}`;
                    });

                    setDates(dates); 
                    setProgress(data);
                    setLoading(false);

                } else if (res.status === 401) {
                    window.location.href = '/';
                } else {
                    toast.error('Error fetching your progress pictures. Please try again.');
                    setLoading(false);
                }
            })
            .catch(err => {
                toast.error('Error fetching your progress pictures. Please try again.');
                setLoading(false);
            });
    };

    const fetchProgress = useCallback(async () => {
        getProgress();
    }, [selectedDate]);
    
    useEffect(() => {
        fetchProgress();
    }, [fetchProgress, selectedDate]);

    const handleFileUpload = async (e: any) => {
        setLoading(true);
        const items = e.target.files.length;

        const formData = new FormData();
        
        for(let i = 0; i < items; i++) {
            const date = new Date();
            formData.append('images', e.target.files[i]);
            formData.append('date', date.toUTCString());
        }

        await fetch(`${config.apiBaseUrl}/media/progress`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            body: formData
        })
            .then(async res => {
                if(res.status === 200) {
                    setTimeout(() => {
                        getProgress();
                    }, 3000);
                } else if (res.status === 401) {
                    window.location.href = '/';
                } else {
                    console.log(await res.json());
                    setLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    };

    const openLightBox = (e: any, order: any) => {
        if(e.target === e.currentTarget) {
            setLightboxIndex(order);
            setLightboxOpen(true);
        }
    };
    
    return (
        <>
            <div className={classes.uploadBox}>
                <div className={classes.uploadBoxInner}>
                    <CameraIcon />
                    <p className="upload-box__title">Upload Progress Pictures</p>
                </div>
                <input type="file" name="image" multiple onChange={(e) => handleFileUpload(e)} />
            </div>
            <div className={classes.datePicker}>
                <DatePicker 
                    autoOk
                    variant="dialog"
                    minDate={new Date(2020, 5, 1)}
                    maxDate={new Date()}
                    value={selectedDate}
                    openTo="month"
                    views={['year', 'month']}
                    onChange={(date) => setSelectedDate(date as Date)} />
            </div>
            {loading && <SolidLoader />}
            {!loading && progress && progress.length === 0 && <p>No progress pictures found for <b>{moment(selectedDate).format('MMMM')}</b></p>}
            {!loading &&
            <div className={classes.photoGrid}>
                {progress && progress.map((item: any, i: any) => {
                    return <div className="upload-library__item" key={i} onClick={(e) => openLightBox(e, item.order)}>
                        <img src={`${config.apiBaseUrl}/media/progress/${item.thumbnail}`} onClick={(e) => openLightBox(e, item.order)} />
                        {/* <button className="delete" onClick={((e) => { e.preventDefault(); setDeleteModal({id: item._id, open: true});})}>
                        <svg>
                            <use xlinkHref="/assets/icon-library.svg#trash" />
                        </svg>
                    </button> */}
                    </div>;
                }
                )}
            </div>
            }
            {lightboxOpen && 
                    <Lightbox 
                        mainSrc={progress.map((item: any) => item.image)[lightboxIndex]}
                        nextSrc={progress.map((item: any) => item.image)[(lightboxIndex + 1) % progress.map((item: any) => item.image).length]}
                        prevSrc={progress.map((item: any) => item.image)[(lightboxIndex + progress.map((item: any) => item.image).length - 1) % progress.map((item: any) => item.image).length]}
                        onCloseRequest={() => setLightboxOpen(false)}
                        onMovePrevRequest={() =>
                            setLightboxIndex((lightboxIndex + progress.map((item: any) => item.image).length - 1) % progress.map((item: any) => item.image).length)
                        }
                        onMoveNextRequest={() =>
                            setLightboxIndex((lightboxIndex + 1) % progress.map((item: any) => item.image).length)
                        } />}
        </>
    );
};

export default ProgressPhotos;