import axios, { AxiosResponse } from 'axios';
import { Recipe } from '../models/Recipes';
import { buildApiUrl } from './common';

export const getAllRecipes = async (): Promise<AxiosResponse<Recipe[]>> => {
    const url = buildApiUrl('recipe');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
};
