import { makeStyles, AppBar, Tabs, Tab } from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppCard from '../components/AppCard';
import AppPageTitle from '../components/AppPageTitle';
import config from '../config';
import { selectors } from '../store';

interface PageParams {
    id: string
}

const useStyles = makeStyles(theme => ({
    cardBase: {
        display: 'block'
    },
    image: {
        width: '100%',
        height: 180,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        backgroundColor: theme.palette.grey[200],
        marginBottom: theme.spacing(2),
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    info: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        height: 52
    },
    name: {
        fontWeight: theme.typography.fontWeightBold
    },
    macros: {
        display: 'flex',
        marginTop: 'auto',
        fontSize: '.75rem',
        color: theme.palette.grey[600],
        paddingBottom: theme.spacing(2)
    },
    macroItem: {
        display: 'flex',
        alignItems: 'center',
        flex: 1
    },
    protein: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(251,179,182)',
        marginRight: theme.spacing(1)
    },
    carbs: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(171,241,255)',
        marginRight: theme.spacing(1)
    },
    fats: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(134,245,134)',
        marginRight: theme.spacing(1)
    },
    tabContainer: {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    list: {
        paddingLeft: theme.spacing(2)
    }
}));

const RecipeDetailPage: React.FC = () => {
    const classes = useStyles();
    const { id } = useParams<PageParams>();
    const recipe = useSelector(selectors.recipes.getById(id));
    const [tab, setTab] = useState('ingredients');
    
    return (
        <>
            {recipe && 
            <>
                <div className={classes.image}>
                    {recipe.image && <img src={`${config.apiBaseUrl}/media/${recipe.image}`} alt={recipe.name} />}
                </div>
                <AppCard className={classes.cardBase}>
                    <AppPageTitle title={recipe.name} />
                    <div className={classes.macros}>
                        <div className={classes.macroItem}>
                            {recipe.kcals.toFixed(0)} kcal
                        </div>
                        <div className={classes.macroItem}>
                            <span className={classes.protein}></span>
                            {recipe.protein.toFixed(0)}g
                        </div>
                        <div className={classes.macroItem}>
                            <span className={classes.carbs}></span>
                            {recipe.carbohydrates.toFixed(0)}g
                        </div>
                        <div className={classes.macroItem}>
                            <span className={classes.fats}></span>
                            {recipe.fats.toFixed(0)}g
                        </div>
                        <div className={classes.macroItem}>
                        Serves: <b> {recipe.servings}</b>
                        </div>
                    </div>
                    <AppBar position="static" color='default' className={classes.tabContainer}>
                        <Tabs value={tab} variant='fullWidth' onChange={(e: any, value: string) => setTab(value)} aria-label="Recipe Tabs">
                            <Tab label="Ingredients" value='ingredients' />
                            <Tab label="Instructions" value='instructions' />
                        </Tabs>
                    </AppBar>
                    {tab === 'ingredients' &&
                    <ul className={classes.list}>
                        {recipe.ingredients.map(ingredient => <li key={ingredient}>{ingredient}</li>)}
                    </ul>
                    }
                    {tab === 'instructions' &&
                    <ul className={classes.list}>
                        {recipe.directions.map(direction => <li key={direction}>{direction}</li>)}
                    </ul>
                    }
                </AppCard>
            </>
            }
        </>
    );
};

export default RecipeDetailPage;