import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import AppFormikTextField from '../../components/AppForm/AppFormikTextField';
import { makeStyles } from '@material-ui/core';
import AppButton from '../../components/AppButton';
import { registerUser } from '../../api/Authentication';
import { useAuth } from '../authContext/authContext';
import { appPaths } from '../../AppRoutes';
import { errorToast } from '../../toast';
import { useEffect } from 'react';
import SolidLoader from '../../components/Loading/SolidLoader';

const registerSchema = Yup.object().shape({
    firstname: Yup.string()
        .required('Please provide your first name.'),
    lastname: Yup.string()
        .required('Please provide your last name.'),
    email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Please provide your email address.'),
    password: Yup.string()
        .required('Please provide a password.')
        .min(8, 'Password must be at least 8 characters long.'),
    passwordConfirm: Yup.string()
        .required('Please confirm your password.')
        .min(8, 'Password must be at least 8 characters long.')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

interface IRegisterDetails {
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    passwordConfirm: string
}

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(4)
    },
    formItem: {
        marginBottom: theme.spacing(2)
    },
    paymentItem: {
        position: 'relative',
        padding: '17px 10px',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(4)
    },
    error: {
        color: theme.palette.warning.main
    },
    btn: {
        marginBottom: theme.spacing(4)
    },
    paymentInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1)
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.dark
    }
}));

const RegisterForm: React.FC = () => {
    const { state, actions } = useAuth();
    const history = useHistory();
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const [data, setData] = useState<IRegisterDetails>();
    const [error, setError] = useState<string | undefined>(undefined);
    const [paymentMethodId, setPaymentMethodId] = useState<string>();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (data: IRegisterDetails) => {
        setLoading(true);
        setData(data);
        await getPaymentMethod(data);
    };

    const getPaymentMethod = async (data: IRegisterDetails): Promise<boolean> => {
        if(!stripe || !elements) {
            setError('There was a problem with your payment method. Please try again.');
            setLoading(false);
            return false;
        }

        const cardElement = elements.getElement(CardElement);

        if(cardElement) {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    email: data.email
                }
            });
    
            if(error) {
                setError('There was a problem with your payment method. Please try again.');
                setLoading(false);
                return false;
            } else {
                if(paymentMethod) {
                    setPaymentMethodId(paymentMethod.id);
                    return true;
                } else {
                    setError('There was a problem with your payment method. Please try again.');
                    setLoading(false);
                    return false;
                }
            }
        } else {
            setError('There was a problem with your payment method. Please try again.');
            setLoading(false);
            return false;
        }
    };

    const submitRegistration  = async () => {
        if(paymentMethodId && data) {
            const result = await registerUser({...data, payment_method: paymentMethodId});

            if(result.status === 200) {
                actions?.setIsLoading(true);
                sessionStorage.setItem('isLoggedIn', 'true');
                sessionStorage.setItem('user', JSON.stringify(result.data.data.user));
                actions?.setUserDetails(result.data.data.user);
                actions?.setLoggedInState(true);
                history.push(appPaths.home);
                actions?.setIsLoading(false);
                setLoading(false);
            } else {
                setLoading(false);
                errorToast('There was a problem registering. Please try again.');
            }
        }
    };

    useEffect(() => {
        if(paymentMethodId) {
            submitRegistration();
        }
    }, [paymentMethodId]);
    
    return (
        <>
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    password: '',
                    passwordConfirm: ''
                }}
                validationSchema={registerSchema}
                onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className={classes.grid}>
                            <AppFormikTextField name='firstname' placeholder='First name' label='First name' />
                            <AppFormikTextField name='lastname' placeholder='Last name' label='Last name' />
                        </div>
                        <div className={classes.formItem}>
                            <AppFormikTextField name='email' placeholder='Email address' label='Email address' />
                        </div>
                        <div className={classes.formItem}>
                            <AppFormikTextField name='password' placeholder='Password' label='Password' type='password' />
                        </div>
                        <div className={classes.formItem}>
                            <AppFormikTextField name='passwordConfirm' placeholder='Confirm password' label='Confirm password' type='password' />
                        </div>
                        <div className={classes.paymentInfo}>
                            <label>Payment method</label>
                            <div><b>£12.99</b> / month</div>
                        </div>
                        <div className={classes.paymentItem}>
                            <CardElement
                                options={{
                                    hidePostalCode: true,
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            backgroundColor: '#FFFFFF',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                            />
                        </div>
                        <AppButton type='submit' color='secondary' fullWidth className={classes.btn}>Register</AppButton>
                        {error && <span className={classes.error}>{error}</span>}
                    </Form>
                )}
            </Formik>
            <Link to="/" className={classes.link}>Already have an account? <b>Log In</b></Link>
            {loading && <SolidLoader />}
        </>
    );
};

export default RegisterForm;