import { Formik, Form } from 'formik';
import { initialLoginFormValues, LoginFormValues, LoginValidationSchema } from '.';
import AppFormikSubmitButton from '../../components/AppForm/AppFormikSubmitButton';
import AppFormikTextField from '../../components/AppForm/AppFormikTextField';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { appPaths } from '../../AppRoutes';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import TextInputField from '../../components/AppForm/TextInputField';

interface LoginFormProps {
    handleSubmit: (values: LoginFormValues) => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        display: 'flex',
        backgroundColor: '#D5DDCC',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    formSection: {
        boxShadow: 'none'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: '100%',
        maxWidth: '240px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#D5DDCC',
        color: '#FFFFFF',
        boxShadow: 'none'
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.dark
    }
}));

function Copyright() {
    const classes = useStyles();

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link className={classes.link} to="https://strongeryou.app/">
          Stronger You
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const LoginForm: React.FC<LoginFormProps> = ({
    handleSubmit
}) => {
    const classes = useStyles();
    
    return (
        <>
            <Formik
                validationSchema={LoginValidationSchema}
                onSubmit={handleSubmit}
                initialValues={initialLoginFormValues}>
                <Form className={classes.form}>
                    <TextInputField
                        name='email'
                        label='Email Address'
                        autofocus />
                    <TextInputField
                        name='password'
                        label='Password'
                        type='password' />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
              Sign In
                    </Button>
                </Form>
            </Formik>
            <Grid container>
                <Grid item xs>
                    <Link to={appPaths.forgottenPassword} className={classes.link}>
                  Forgot password?
                    </Link>
                </Grid>
                <Grid item>
                    <Link to={appPaths.register} className={classes.link}>
                        {'Don\'t have an account? Sign Up'}
                    </Link>
                </Grid>
            </Grid>
            <Box mt={5}>
                <Copyright />
            </Box>
        </>
    );
};

export default LoginForm;