import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import AppPageTitle from '../../components/AppPageTitle';
import { selectors } from '../../store';
import { ExerciseForm } from '../../workout/admin/ExerciseForm';

interface ExerciseEditorPageParams {
    id?: string
}

const ExerciseEditorPage: React.FC = () => {
    const { id } = useParams<ExerciseEditorPageParams>();
    const exercise = useSelector(selectors.exercises.getById(id ? id : ''));

    return (
        <>
            <AppPageTitle title='Exercise Editor' />
            <ExerciseForm initialValues={id && exercise ? exercise : undefined} />
        </>
    );
};

export default ExerciseEditorPage;