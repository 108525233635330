import { makeStyles, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { adminAppPaths } from '../AppRoutes';
import config from '../config';

interface ExerciseListItemProps {
    cardioOnly: boolean,
    category: string[],
    description: string,
    image: string,
    name: string,
    video: string,
    _id: string
}

const useStyles = makeStyles((theme) => ({
    item: {
        position: 'relative',
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    itemImage: {
        position: 'relative',
        width: '100%',
        height: 260,
        backgroundColor: theme.palette.secondary.main,
        '& > img': {
            position: 'relative',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    itemContent: {
        position: 'relative',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '1.25rem',
        textAlign: 'center'
    }
}));

const ExerciseListItem: React.FC<ExerciseListItemProps> = ({
    _id,
    name,
    image
}) => {
    const classes = useStyles();

    return (
        <Grid item className={classes.item} xs={3} component={Link} to={`${adminAppPaths.exerciseEditor}/${_id}`}>
            <div className={classes.itemImage}>
                {image && <img src={`${config.apiBaseUrl}/media/content/${image}`} alt={name} />}
            </div>
            <div className={classes.itemContent}>
                {name}
            </div>
        </Grid>
    );
};

export default ExerciseListItem;