import { IconButton, makeStyles } from '@material-ui/core';
import { AddCircle as AddIcon, RemoveCircle as SubtractIcon } from '@material-ui/icons';
import AppCard from '../../components/AppCard';
import CardLoader from '../../components/CardLoader';
import { useNutrition } from '../../nutrition/context/NutritionContext';
import waterIcon from './water.svg';

const useStyles = makeStyles((theme) => ({
    cardBase: {
        padding: theme.spacing(1.5)
    },
    cardInner: {
        display: 'flex',
        '& button': {
            flex: 'none'
        }
    },
    waterIcon: {
        height: 37
    },
    count: {
        fontSize: 18,
        fontWeight: theme.typography.fontWeightBold,
        textAlign: 'center'
    }
}));

const WaterCard: React.FC = () => {
    const classes = useStyles();
    const { diary, isLoading, addWater, removeWater } = useNutrition();

    return (
        <AppCard className={classes.cardBase}>
            <div className={classes.cardInner}>
                <IconButton disabled={diary?.data.filter(item => item.category === 'Water').length === 0} onClick={removeWater}>
                    <SubtractIcon />
                </IconButton>
                <div>
                    <img src={waterIcon} className={classes.waterIcon} />
                    <div className={classes.count}>{diary?.data.filter(item => item.category === 'Water').length}/8</div>
                </div>
                <IconButton onClick={addWater}>
                    <AddIcon />
                </IconButton>
            </div>
            <CardLoader loading={isLoading} />
        </AppCard>
    );
};

export default WaterCard;