import { IconButton, makeStyles } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIcon, ArrowBackIos as ArrowBackwardIcon } from '@material-ui/icons';
import Vimeo from '@u-wave/react-vimeo';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { Swiper, SwiperSlide } from 'swiper/react';
import LocalLoader from '../../../components/Loading/LocalLoader';
import { selectors } from '../../../store';
import { alphabet } from '../../WorkoutEditor';
import { useWorkoutWalkthrough } from '../Context/WorkoutWalkthroughContext';

interface WorkoutVideoProps {
    exercises: string[],
    stepNo: number
}

interface WorkoutVideoItemProps {
    id: string,
    playing: boolean,
    letter?: string
}

const useStyles = makeStyles(theme => ({
    supersetContainer: {
        position: 'relative',
        zIndex: 100000
    },
    supersetLabel: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
        zIndex: 1
    },
    supersetLetter: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        width: 34,
        height: 34,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase'
    },
    supersetIndicator: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1000
    },
    supersetIndicatorItem: {
        width: 18,
        height: 18,
        borderRadius: '50%',
        backgroundColor: theme.palette.common.white,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: 'pointer'
    },
    supersetIndicatorItemActive: {
        width: 18,
        height: 18,
        borderRadius: '50%',
        backgroundColor: theme.palette.secondary.main,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: 'pointer'
    },
    videoWrapper: {
        position: 'relative',
        display: 'block',
        background: theme.palette.common.white,
        '&::before': {
            content: '""',
            display: 'block',
            width: '100%',
            paddingTop: '177%'
        }
    },
    videoItem: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 11,
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     width: '100%',
        //     height: '100%'
        // }
    },
    arrowBtn: {
        zIndex: 1,
        position: 'absolute',
        top: 'calc(50% - 24px)',
        backgroundColor: 'rgba(255, 255, 255, .2)'
    },
    arrowBtnPrev: {
        left: 0
    },
    arrowBtnNext: {
        right: 0
    },
    iframeVideo: {
        position: 'relative',
        width: '100%',
        height: '100%'
    }
}));

const WorkoutVideoItem: React.FC<WorkoutVideoItemProps> = ({
    id,
    playing,
    letter
}) => {
    const classes = useStyles();
    const exercise = useSelector(selectors.exercises.getById(id));
    const [paused, setPaused] = useState(true);
    
    return (
        <div className={classes.videoItem}>
            {exercise && playing &&
                // <Vimeo
                //     video={exercise.video}
                //     autoplay
                //     responsive
                //     // paused={paused}
                //     controls={false}
                //     autopause
                //     // onReady={() => console.log('ready')}
                //     loop
                // />
                <iframe className={classes.iframeVideo} src={`https://player.vimeo.com/video/${exercise.video}?muted=1&autoplay=1&loop=1&controls=0`} frameBorder="0" allow="autoplay; fullscreen; loop;"></iframe>
            }
            {letter &&
                <div className={classes.supersetLetter}>{letter}</div>
            }
        </div>
    );
};


const WorkoutVideo: React.FC<WorkoutVideoProps> = ({
    exercises,
    stepNo
}) => {
    const classes = useStyles();
    const { currentStep } = useWorkoutWalkthrough();
    const [currentSuperSetItem, setCurrentSuperSetItem] = useState(0);

    return (
        <>
            {exercises.length === 1 ?
                <div className={classes.videoWrapper}>
                    <LocalLoader />
                    <WorkoutVideoItem id={exercises[0]} playing={currentStep === stepNo} />
                </div>
                :
                <div className={classes.supersetContainer}>
                    <SwipeableViews
                        index={currentSuperSetItem}
                        onChangeIndex={(index) => setCurrentSuperSetItem(index)}>
                        {exercises.map((exerciseId, index) =>
                            <div className={classes.videoWrapper} key={`${currentStep}-${index}`}>
                                <LocalLoader />
                                <WorkoutVideoItem id={exerciseId} playing={currentStep === stepNo && index === currentSuperSetItem} letter={alphabet[index]} /> 
                            </div>
                        )}
                    </SwipeableViews>
                    {/* <Swiper grabCursor={true}>
                        {exercises.map((exerciseId, index) =>
                            <SwiperSlide  key={exerciseId}>
                                <div className={classes.videoWrapper}>
                                    <WorkoutVideoItem id={exerciseId} playing={currentStep !== stepNo && index !== currentSuperSetItem} letter={alphabet[index]} /> 
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper> */}
                    <div className={classes.supersetLabel}>Superset</div>
                    {currentSuperSetItem !== 0 && 
                    <IconButton className={`${classes.arrowBtn} ${classes.arrowBtnPrev}`} onClick={() => setCurrentSuperSetItem(currentSuperSetItem - 1)}>
                        <ArrowBackwardIcon />
                    </IconButton>
                    }
                    {currentSuperSetItem < (exercises.length - 1) &&
                    <IconButton className={`${classes.arrowBtn} ${classes.arrowBtnNext}`} onClick={() => setCurrentSuperSetItem(currentSuperSetItem + 1)}>
                        <ArrowForwardIcon />
                    </IconButton>
                    }
                    <div className={classes.supersetIndicator}>
                        {exercises.map((exercise, index) =>
                            <div key={index} className={currentSuperSetItem === index ? classes.supersetIndicatorItemActive : classes.supersetIndicatorItem} onClick={() => setCurrentSuperSetItem(index)}></div>    
                        )}
                    </div>
                </div>
            }
        </>
    );
};

export default WorkoutVideo;