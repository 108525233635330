import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { getAllWorkouts } from '../api/Workout';
import { WorkoutNewFull } from '../models/Workout';

const sliceName = 'workouts';

const fetchAllWorkoutsAsyncThunk = createAsyncThunk(
    `${sliceName}/fetchAllWorkouts`,
    async () => {
        const response = await getAllWorkouts();
        
        if(response.status === 401) {
            sessionStorage.clear();
            window.location.pathname = '/';
        }

        return response;
    }
);

const entityAdapter = createEntityAdapter<WorkoutNewFull>(
    {
        selectId: workout => workout._id
    }
);

interface SliceState {
    initialised: boolean,
    entityState: EntityState<WorkoutNewFull>
}

const initialState: SliceState = {
    initialised: false,
    entityState: entityAdapter.getInitialState()
};

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addToWorkouts: (state, { payload }: PayloadAction<WorkoutNewFull>) => {
            entityAdapter.addOne(state.entityState, payload);
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAllWorkoutsAsyncThunk.fulfilled, (state, { payload }) => {
            state.initialised = true;
            entityAdapter.removeAll(state.entityState);
            entityAdapter.setAll(state.entityState, payload.data);
        });
    }
});

export const {
    name,
    reducer
} = slice;

type RootReducerState = {
    [sliceName]: SliceState,
};

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();

const doFetchAll = (
    dispatch: (action: unknown) => void) => {

    dispatch(fetchAllWorkoutsAsyncThunk());
};

export const actions = {
    fetchAll: () => (dispatch: (action: unknown) => void): void => {
        doFetchAll(dispatch);
    },
    addWorkout: (workout: WorkoutNewFull) => (dispatch: (action: unknown) => void): void => {
        dispatch(slice.actions.addToWorkouts(workout));
    },
};

const createSliceSelector = <T, >(selector: (state: SliceState) => T) => {
    return createSelector(
        selectSliceState,
        selector,
    );
};

export const selectors = {
    all: createSliceSelector(state => entitySelectors.selectAll(state.entityState)),
    isInitialised: createSliceSelector(state => state.initialised),
    getWorkoutById: (id: string) => createSliceSelector(state => entitySelectors.selectById(state.entityState, id))
};



