import { makeStyles, TextField } from '@material-ui/core';
import { useState } from 'react';
import { addNewDiaryEntry, updateDiaryEntry } from '../../api/Nutrition';
import AppButton from '../../components/AppButton';
import { v4 as uuidv4 } from 'uuid';
import AppModal from '../../components/AppModal';
import { DiaryCategory } from '../../models/Nutrition';
import { Recipe } from '../../models/Recipes';
import { useNutrition } from '../context/NutritionContext';

export interface ServingsModalProps {
    recipe?: Recipe,
    open: boolean,
    category: DiaryCategory,
    handleClose: () => void,
    handleAdded: () => void
}
const useStyles = makeStyles(theme => ({
    macros: {
        display: 'flex',
        marginTop: 'auto',
        fontSize: '.75rem',
        color: theme.palette.grey[600],
        marginBottom: theme.spacing(2)
    },
    macroItem: {
        display: 'flex',
        alignItems: 'center',
        flex: 1
    },
    protein: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(251,179,182)',
        marginRight: theme.spacing(1)
    },
    carbs: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(171,241,255)',
        marginRight: theme.spacing(1)
    },
    fats: {
        display: 'inline-block',
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: 'rgb(134,245,134)',
        marginRight: theme.spacing(1)
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2)
    }
}));

const ServingsModal: React.FC<ServingsModalProps> = ({
    recipe,
    open,
    category,
    handleClose,
    handleAdded
}) => {
    const classes = useStyles();
    const { diary, fetchDiary } = useNutrition();
    const [servings, setServings] = useState(1);

    const onClose = () => {
        setServings(1);
        handleClose();
    };

    const handleSubmit = async () => {
        if(diary) {
            const data = {...diary};

            if(data && recipe) {
                data.data && data.data.push({
                    ...recipe,
                    servings: servings,
                    category: category,
                    eid: uuidv4()
                });
    
                const result = data._id ? await updateDiaryEntry(data) : await addNewDiaryEntry(data);
    
                if(result.status === 200) {
                    fetchDiary();
                    onClose();
                    handleAdded();
                }
            }
        }
    };

    return (
        <AppModal open={open} handleClose={onClose}>
            {recipe && 
                <>
                    <h3>{recipe.name}</h3>
                    <div className={classes.macros}>
                        <div className={classes.macroItem}>
                            {(recipe.kcals * servings).toFixed(0)} kcal
                        </div>
                        <div className={classes.macroItem}>
                            <span className={classes.protein}></span>
                            {(recipe.protein * servings).toFixed(0)}g
                        </div>
                        <div className={classes.macroItem}>
                            <span className={classes.carbs}></span>
                            {(recipe.carbohydrates * servings).toFixed(0)}g
                        </div>
                        <div className={classes.macroItem}>
                            <span className={classes.fats}></span>
                            {(recipe.fats * servings).toFixed(0)}g
                        </div>
                    </div>
                    <div>
                        <TextField type='number' value={servings} onChange={(e) => setServings(parseFloat(e.target.value))} label='Servings' autoFocus />
                    </div>
                    <div className={classes.actions}>
                        <AppButton onClick={onClose}>Cancel</AppButton>
                        <AppButton color='primary' onClick={handleSubmit}>Add</AppButton>
                    </div>
                </>
            }
        </AppModal>
    );
};

export default ServingsModal;