import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ArrowForward as ArrowIcon, Check as CheckIcon } from '@material-ui/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import AppCard from '../components/AppCard';
import { ScheduleItem } from '../models/Schedule';
import { selectors } from '../store';
import { appPaths } from '../AppRoutes';
import { useLogging } from '../Logging/context/LoggingContext';

export interface ScheduleListItemProps {
    scheduleItem: ScheduleItem
}

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        textDecoration: 'none'
    },
    date: {
        position: 'relative',
        flex: 'none',
        marginRight: theme.spacing(2),
        color: theme.palette.text.primary
    },
    day: {
        fontSize: '.65rem',
        textTransform: 'uppercase'
    },
    dateNo: {
        fontWeight: theme.typography.fontWeightBold
    },
    month: {
        fontWeight: theme.typography.fontWeightMedium,
        opacity: .75,
        textTransform: 'uppercase',
        fontSize: '.65rem'
    },
    itemContent: {
        flex: 1,
        justifyContent: 'flex-start',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: 18
    },
    itemContentRest: {
        opacity: .75
    },
    arrowIcon: {
        width: 32,
        height: 32,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        '& svg': {
            fill: theme.palette.common.white
        }
    },
    itemDone: {
        backgroundColor: theme.palette.secondary.main
    }
}));

interface ItemContainerProps {
    scheduleItem: ScheduleItem
}

const ItemContainer: React.FC<ItemContainerProps> = ({
    scheduleItem,
    children
}) => {
    const classes = useStyles();

    return (
        <>
            {scheduleItem.type === 'restday' && <div className={classes.item}>{children}</div>}
            {scheduleItem.type !== 'restday' && <Link to={`${appPaths.workoutWalkthrough}/${scheduleItem.workoutId}?gsid=${scheduleItem.guideScheduleId}`} className={classes.item}>{children}</Link>}
        </>
    );
};

const ScheduleListItem: React.FC<ScheduleListItemProps> = ({
    scheduleItem
}) => {
    const classes = useStyles();
    const { currentWeek } = useLogging();
    const workoutData = useSelector(selectors.workouts.getWorkoutById(scheduleItem.workoutId ? scheduleItem.workoutId : ''));

    const log = currentWeek?.find(log => log.workoutId === scheduleItem.workoutId);

    return (
        <ItemContainer scheduleItem={scheduleItem}>
            <div className={classes.date}>
                <div className={classes.day}>{moment(scheduleItem.date).format('ddd')}</div>
                <div className={classes.dateNo}>{moment(scheduleItem.date).format('Do')}</div>
                <div className={classes.month}>{moment(scheduleItem.date).format('MMMM')}</div>
            </div>
            <AppCard className={`${classes.itemContent} ${scheduleItem.type === 'restday' && classes.itemContentRest}`}>
                {scheduleItem.type === 'restday' ? 'Rest Day' : workoutData?.name}
                {scheduleItem.type !== 'restday' &&
                    <div className={`${classes.arrowIcon} ${log && classes.itemDone}`}>
                        {log ?
                            <CheckIcon />                       
                            :
                            <ArrowIcon />
                        }
                    </div>
                }
            </AppCard>
        </ItemContainer>
    );
};

export default ScheduleListItem;