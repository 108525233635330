import { useSelector } from 'react-redux';
import { adminAppPaths } from '../../AppRoutes';
import AppButton from '../../components/AppButton';
import AppPageHeading from '../../components/AppPageHeading';
import { selectors } from '../../store';
import ExerciseList from '../../workout/ExerciseList';

const ExerciseManagerPage: React.FC = () => {
    const exerciseList = useSelector(selectors.exercises.all);

    return (
        <>
            <AppPageHeading
                title='Exercise Manager'>
                <AppButton linkPath={adminAppPaths.exerciseEditor}>Add Exercise</AppButton>
            </AppPageHeading>
            <ExerciseList exercises={exerciseList} />
        </>
    );
};

export default ExerciseManagerPage;