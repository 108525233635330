import { makeStyles } from '@material-ui/core';
import { Apps as DashboardIcon, DateRange as DateRangeIcon, Restaurant as NutritionIcon, FitnessCenter as WorkoutIcon } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { appPaths } from '../AppRoutes';

const useStyles = makeStyles((theme) => ({
    navBar: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-around',
        padding: theme.spacing(2),
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        width: `calc(100vw - ${theme.spacing(2)}px)`,
        height: 80,
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[24],
        zIndex: 100
    },
    navItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 50,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[100],
        '& svg': {
            fill: theme.palette.text.primary
        }
    },
    isActive: {
        backgroundColor: theme.palette.secondary.main,
        '& svg': {
            fill: theme.palette.common.white
        }
    }
}));

const BottomNav: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.navBar}>
            <NavLink to={appPaths.dashboard} className={classes.navItem} strict exact activeClassName={classes.isActive}>
                <DashboardIcon />
            </NavLink>
            <NavLink to={appPaths.schedule} className={classes.navItem} activeClassName={classes.isActive}>
                <DateRangeIcon />
            </NavLink>
            <NavLink to={appPaths.workout} className={classes.navItem} activeClassName={classes.isActive}>
                <WorkoutIcon />
            </NavLink>
            <NavLink to={appPaths.nutrition} className={classes.navItem} activeClassName={classes.isActive}>
                <NutritionIcon />
            </NavLink>
        </div>
    );
};

export default BottomNav;