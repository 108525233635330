import { makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

export interface TestIphoneProps {
    test: string
}

const styles = {
    slideContainer: {
        height: '100vh'
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        zIndex: 10000
    },
    slide1: {
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        borderBottom: '3px solid white',
        backgroundColor: 'red'
    },
    slide2: {
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        borderBottom: '3px solid white',
        backgroundColor: 'blue',
        padding: theme.spacing(2)
    },
    slide3: {
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        borderBottom: '3px solid white',
        backgroundColor: 'green'
    }
}));

const TestIphone: React.FC<TestIphoneProps> = ({
    test
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SwipeableViews 
                containerStyle={styles.slideContainer} 
                axis="y" 
                resistance 
                slideStyle={{
                    position: 'relative',
                    minHeight: '100%'
                }}>
                <div className={classes.slide1}></div>
                <div className={classes.slide2}>
                    <h2>Title Here</h2>
                    <p>Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content.</p>
                    <h2>Title Here</h2>
                    <p>Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content.</p>
                    <h2>Title Here</h2>
                    <p>Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content.</p>
                    <h2>Title Here</h2>
                    <p>Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content. Here is some content.</p>
                </div>
                <div className={classes.slide3}></div>
            </SwipeableViews>
        </div>
    );
};

export default TestIphone;