import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { appPaths } from '../../AppRoutes';
import config from '../../config';
import { Guide } from '../../models/Workout';

export interface GuideItemProps {
    guide: Guide
}

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        textDecoration: 'none',
    },
    image: {
        position: 'relative',
        width: '100%',
        height: 162,
        borderRadius: theme.shape.borderRadius,
        // overflow: 'hidden',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: theme.shape.borderRadius,
            position: 'relative'
        },
        '& span': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.grey[400],
            transform: 'rotate(4deg)',
            transition: 'all .2s ease-in-out'
        },
        '&:hover span': {
            transform: 'rotate(0deg)'
        }
    },
    name: {
        fontSize: 16,
        textAlign: 'center',
        marginTop: 8,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary
    },
    tag: {
        position: 'absolute',
        bottom: 6,
        right: 6,
        height: 26,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#414141',
        color: theme.palette.common.white,
        fontSize: 10,
        padding: '0 6px',
        textTransform: 'uppercase',
        borderRadius: 4
    },
    dayTag: {
        top: 6,
        right: 6,
        bottom: 'auto',
        backgroundColor: '#FFD18D'
    }
}));

const GuideItem: React.FC<GuideItemProps> = ({
    guide
}) => {
    const classes = useStyles();
    const daysPerWeek = guide && guide.blocks && guide.blocks[0].workouts.filter(workout => workout !== 'restday').length;

    return (
        <Link to={`${appPaths.guideDetail}/${guide._id}`} className={classes.item}>
            <div className={classes.image}>
                <span></span>
                {guide.image && <img src={`${config.apiBaseUrl}/media/content/${guide.image}`} alt={guide.name} />}
                <div className={classes.tag}>{guide.level}</div>
                <div className={`${classes.tag} ${classes.dayTag}`}>{daysPerWeek} day/week</div>
            </div>
            <div className={classes.name}>
                {guide.name}
            </div>
        </Link>
    );
};

export default GuideItem;