import { createContext, useContext } from 'react';
import { DiaryTotals, Nutrition, NutritionDiary } from '../../models/Nutrition';

export interface INutritionContext {
    target?: Nutrition,
    date: Date,
    diary?: NutritionDiary,
    diaryTotals?: DiaryTotals,
    isLoading: boolean,
    setDate: (date: Date) => void,
    resetTarget: () => void
    fetchDiary: () => void,
    fetchTarget: () => void,
    removeDiaryItem: (itemId: string) => void,
    addWater: () => void,
    removeWater: () => void
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const NutritionContext = createContext<INutritionContext>({
    date: new Date(),
    isLoading: false,
    setDate: placeholderFunc,
    resetTarget: placeholderFunc,
    fetchDiary: placeholderFunc,
    fetchTarget: placeholderFunc,
    removeDiaryItem: placeholderFunc,
    addWater: placeholderFunc,
    removeWater: placeholderFunc
});

export const useNutrition: () => INutritionContext = () => useContext(NutritionContext);