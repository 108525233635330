import axios, { AxiosResponse } from 'axios';
import { Exercise, Guide, LiveWorkout, OldWorkout, Workout, WorkoutNew, WorkoutNewFull } from '../models/Workout';
import { ExerciseFormValues } from '../workout/admin/ExerciseForm';
import { buildApiUrl } from './common';

export const getAllExercises = async (): Promise<AxiosResponse<Exercise[]>> => {
    const url = buildApiUrl('v2/workout/exercise/all');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getAllWorkouts = async (): Promise<AxiosResponse<WorkoutNewFull[]>> => {
    const url = buildApiUrl('v2/workout/get/all');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const addNewExercise = async (exercise: ExerciseFormValues): Promise<AxiosResponse<Exercise>> => {
    const url = buildApiUrl('v2/workout/exercise');

    const response = await axios.post(url, exercise, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const updateExercise = async (exercise: ExerciseFormValues): Promise<AxiosResponse<Exercise>> => {
    const url = buildApiUrl('v2/workout/exercise');

    const response = await axios.put(url, exercise, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const addNewWorkout = async (workout: WorkoutNew): Promise<AxiosResponse<WorkoutNewFull>> => {
    const url = buildApiUrl('v2/workout');

    const response = await axios.post(url, workout, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const updateWorkout = async (workout: WorkoutNew): Promise<AxiosResponse<WorkoutNewFull>> => {
    const url = buildApiUrl('v2/workout');

    const response = await axios.put(url, workout, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getWorkoutById = async (id: string): Promise<AxiosResponse<WorkoutNewFull>> => {
    const url = buildApiUrl(`v2/workout/${id}`);

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const addNewGuide = async (guide: Guide): Promise<AxiosResponse<Guide>> => {
    const url = buildApiUrl('v2/workout/guide');

    const response = await axios.post(url, guide, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getGuideById = async (id: string): Promise<AxiosResponse<Guide>> => {
    const url = buildApiUrl(`v2/workout/guide/${id}`);

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getAllGuides = async (): Promise<AxiosResponse<Guide[]>> => {
    const url = buildApiUrl('v2/workout/all/guide');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getAllChallenges = async (): Promise<AxiosResponse<Guide[]>> => {
    const url = buildApiUrl('v2/workout/all/challenge');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const updateGuide = async (guide: Guide): Promise<AxiosResponse<Guide>> => {
    const url = buildApiUrl('v2/workout/guide');

    const response = await axios.put(url, guide, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const addWorkoutToGuide = async (guideId: string, blockId: string, workoutIndex: string, workoutId: string, copy?: boolean): Promise<AxiosResponse<Guide>> => {
    const url = buildApiUrl(`v2/workout/guide/addworkout/${guideId}`);

    const dto = {
        blockId,
        workoutIndex: parseInt(workoutIndex),
        workoutId,
        copy
    };

    const response = await axios.put(url, dto, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const duplicateGuideBlock = async (guideId: string, blockId: string): Promise<AxiosResponse<Guide>> => {
    const url = buildApiUrl('v2/workout/guide/duplicateblock');

    const dto = {
        guideId,
        blockId
    };

    const response = await axios.put(url, dto, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getLiveWorkouts = async (category = 'all'): Promise<AxiosResponse<LiveWorkout[]>> => {
    const url = buildApiUrl(`workout/${category}`);

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getLiveWorkoutById = async (id: string): Promise<AxiosResponse<LiveWorkout>> => {
    const url = buildApiUrl(`workout/live/id/${id}`);

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getOldWorkouts = async (): Promise<AxiosResponse<OldWorkout[]>> => {
    const url = buildApiUrl('workout/Guide');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getOldWorkoutDetail = async (slug: string): Promise<AxiosResponse<OldWorkout>> => {
    const url = buildApiUrl('workout/detail/' + slug);

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getOldExercises = async (): Promise<AxiosResponse<Exercise[]>> => {
    const url = buildApiUrl('workout/exercise/all');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getLatestLiveWorkout = async (): Promise<AxiosResponse<LiveWorkout>> => {
    const url = buildApiUrl('v2/workout/live/recent');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};