import { makeStyles } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { WorkoutStep } from '../../models/Workout';
import WorkoutEditorStepItem from './WorkoutEditorStepItem';

interface WorkoutEditorStepContainerProps {
    workoutSteps: WorkoutStep[]
}

const useStyles = makeStyles((theme) => ({
    container: {
        positon: 'relative',
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: 'rgba(255, 255, 255, .6)',
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(3),
        opacity: .8
    }
}));

const WorkoutEditorStepContainer: React.FC<WorkoutEditorStepContainerProps> = ({
    workoutSteps
}) => {
    const classes = useStyles();

    return (
        <Droppable droppableId='WorkoutContainer' isCombineEnabled={true} key={1}>
            {(provided, snapshot) => {
                return (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classes.container}
                        style={{
                            background: snapshot.isDraggingOver ? '#cceeff': ''
                        }}>
                        {workoutSteps.map((step, i) =>
                            <WorkoutEditorStepItem step={step} index={i + 1} key={step.stepId} />
                        )}
                        {provided.placeholder}
                    </div>
                );
            }}
        </Droppable>
    );
};

export default WorkoutEditorStepContainer;