import { IconButton, InputAdornment, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Add as AddIcon, Search as SearchIcon, FilterList as FilterIcon } from '@material-ui/icons';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHttpRequest } from '../../api';
import { getMyMeals } from '../../api/Nutrition';
import AppTextField from '../../components/AppForm/AppTextField';
import AppModal from '../../components/AppModal';
import { DiaryCategory } from '../../models/Nutrition';
import { Recipe } from '../../models/Recipes';
import { selectors } from '../../store';
import RecipeItem from './RecipeItem';
import ServingsModal from './ServingsModal';

export interface DiaryAdditionModalProps {
    open: boolean,
    category?: DiaryCategory,
    handleClose: () => void,
    handleAddCustom: () => void
}

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        '& button': {
            marginLeft: 'auto'
        }
    },
    filter: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2),
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto'
    },
    filterBtn: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
        marginLeft: theme.spacing(1)
    },
    customBtn: {
        fontSize: '0.85rem',
        fontWeight: theme.typography.fontWeightBold
    },
    catActive: {
        backgroundColor: theme.palette.grey[200]
    }
}));

const DiaryAdditionModal: React.FC<DiaryAdditionModalProps> = ({
    open,
    category,
    handleClose,
    handleAddCustom
}) => {
    const classes = useStyles();
    const allRecipes = useSelector(selectors.recipes.all);
    const getMyCustomMeals = useCallback(() => getMyMeals(), []);
    const { result: myMeals } = useHttpRequest(getMyCustomMeals);
    const [search, setSearch] = useState('');
    const [selectedRecipe, setSelectedRecipe] = useState<Recipe>();
    const [categoryFilter, setCategoryFilter] = useState<DiaryCategory>('All');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };
    const reset = () => {
        setSearch('');
        setSelectedRecipe(undefined);
        handleClose();
    };

    useEffect(() => {
        handleCloseFilter();
    }, [categoryFilter]);

    let recipes = allRecipes.concat(myMeals && myMeals.length > 0 ? myMeals : []);

    recipes = recipes.filter(recipe => categoryFilter !== 'All' ? recipe.category?.includes(categoryFilter) : recipe).filter(recipe => search !== '' ? recipe.name.toLowerCase().includes(search.toLowerCase()) : recipe);

    return (
        <>
            <AppModal open={open} handleClose={handleClose}>
                <div className={classes.header}>
                    <h2>Food Library</h2>
                    <IconButton onClick={handleAddCustom} className={classes.customBtn}>
                        <AddIcon />
                        <span>New</span>
                    </IconButton>
                </div>
                <div className={classes.filter}>
                    <AppTextField 
                        placeholder='Search...'
                        value={search} 
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'><SearchIcon /></InputAdornment>
                        }} />
                    <IconButton onClick={handleClick} className={classes.filterBtn}>
                        <FilterIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseFilter}
                    >
                        <MenuItem className={categoryFilter === 'All' ? classes.catActive : ''} onClick={() => setCategoryFilter('All')}>All</MenuItem>
                        <MenuItem className={categoryFilter === 'Breakfast' ? classes.catActive : ''} onClick={() => setCategoryFilter('Breakfast')}>Breakfast</MenuItem>
                        <MenuItem className={categoryFilter === 'Lunch' ? classes.catActive : ''} onClick={() => setCategoryFilter('Lunch')}>Lunch</MenuItem>
                        <MenuItem className={categoryFilter === 'Dinner' ? classes.catActive : ''} onClick={() => setCategoryFilter('Dinner')}>Dinner</MenuItem>
                        <MenuItem className={categoryFilter === 'Snacks' ? classes.catActive : ''} onClick={() => setCategoryFilter('Snacks')}>Snacks</MenuItem>
                        <MenuItem className={categoryFilter === 'My Meals' ? classes.catActive : ''} onClick={() => setCategoryFilter('My Meals')}>My Meals</MenuItem>
                    </Menu>
                </div>
                <div className={classes.grid}>
                    {recipes.length > 0 ? 
                        recipes.map(recipe => <RecipeItem key={recipe._id} recipe={recipe} handleSelect={() => setSelectedRecipe(recipe)} />)
                        :
                        <p>No food items found</p>}
                </div>
            </AppModal>
            <ServingsModal 
                open={Boolean(selectedRecipe)} 
                recipe={selectedRecipe} 
                category={category ? category : 'Breakfast'}
                handleClose={() => setSelectedRecipe(undefined)} 
                handleAdded={reset} />
        </>
    );
};

export default DiaryAdditionModal;