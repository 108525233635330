import { Accordion, AccordionSummary, AccordionDetails, makeStyles, AccordionActions, Button, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { GuideBlock } from '../../models/Workout';
import GuideEditorBlockWorkout from './GuideEditorBlockWorkout';

const useStyles = makeStyles(theme => ({
    blockContainer: {
        positon: 'relative',
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: 'rgba(255, 255, 255, .6)',
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        opacity: .8,
        border: 'none',
        '& h3': {
            margin: 0
        },
        '&:before': {
            display: 'none'
        }
    },
    itemContainer: {
        position: 'relative',
        width: '100%'
    },
    blockItem: {
        position: 'relative',
        width: '100%',
        '&:not(:last-of-type)': {
            marginRight: theme.spacing(1)
        },
        '& > h4': {
            marginBottom: theme.spacing(1)
        }
    },
    placeholder: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 60,
        backgroundColor: 'transparent',
        border: `2px dashed ${theme.palette.secondary.main}`,
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
        transition: 'all .2s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    },
    workoutItem: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 60,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1]
    },
    workoutItemImage: {
        position: 'relative',
        width: 34,
        height: 34,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2),
        overflow: 'hidden',
        '& > img': {
            position: 'relative',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    workoutItemTitle: {
        fontWeight: theme.typography.fontWeightMedium
    },
    restItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 60,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(2)
    },
}));

interface GuideEditorBlockItemProps {
    index: number,
    guideId: string,
    block: GuideBlock,
    addRestDay: (blockId: string, workoutIndex: number) => void,
    duplicateBlock: () => void,
    deleteBlock: () => void
}

const GuideEditorBlockItem: React.FC<GuideEditorBlockItemProps> = ({
    index,
    block,
    guideId,
    addRestDay,
    duplicateBlock,
    deleteBlock
}) => {
    const classes = useStyles();

    return (
        <Draggable
            key={index}
            draggableId={block.blockId}
            index={index}>
            {(provided, snapshot) => {
                return (
                    <Accordion 
                        className={classes.blockContainer} 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                        >
                            <h3>Week {index + 1}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.itemContainer}>
                                {block.workouts && block.workouts.map((workout, index) => 
                                    <GuideEditorBlockWorkout
                                        type={workout}
                                        index={index}
                                        blockId={block.blockId}
                                        guideId={guideId}
                                        addRestDay={() => addRestDay(block.blockId, index)}
                                        key={index} />
                                )}
                            </div>
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Button size="small" onClick={deleteBlock}>Delete</Button>
                            <Button size="small" color="primary" onClick={duplicateBlock}>
                            Copy
                            </Button>
                        </AccordionActions>
                    </Accordion>
                );
            }}
        </Draggable>
    );
};

export default GuideEditorBlockItem;