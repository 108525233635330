import { Route, Switch, useParams } from 'react-router-dom';
import LoginPage from './pages/Login';
import DashboardPage from './pages/Dashboard';
import WorkoutPage from './pages/Workout';
import { useAuth } from './authentication/authContext/authContext';
import InitialisationLayer from './authentication/InitialisationLayer/InitialisationLayer';
import MainLayout from './components/MainLayout';
import ExerciseManagerPage from './pages/admin/ExerciseManager';
import ExerciseEditorPage from './pages/admin/ExerciseEditor';
import WorkoutManagerPage from './pages/admin/WorkoutManager';
import WorkoutBuilderPage from './pages/admin/WorkoutBuilder';
import GuideEditorPage from './pages/admin/GuideEditor';
import WorkoutWalkthroughPage from './pages/WorkoutWalkthrough';
import ResumeFloater from './workout/WorkoutWalkthrough/ResumeFloater';
import GuideDetailPage from './pages/GuideDetail';
import SchedulePage from './pages/Schedule';
import TestIphone from './pages/TestIphone';
import TestIphone2 from './pages/TestIphone2';
import NutritionPage from './pages/Nutrition';
import RecipesPage from './pages/Recipes';
import RecipeDetailPage from './pages/RecipeDetail';
import RegisterPage from './pages/Register';
import ForgottenPasswordPage from './pages/ForgottenPassword';
import ResetPasswordPage from './pages/ResetPassword';
import ProfilePage from './pages/Profile';
import LiveWorkoutPage from './pages/LiveWorkout';
import OldWorkoutsPage from './pages/admin/OldWorkouts';
import OldWorkoutDetailPage from './pages/admin/OldWorkoutDetail';
import ProgressPage from './pages/Progress';
import LogBookPage from './pages/LogBook';
import LogBookEntryPage from './pages/LogBookEntry/LogBookEntry';

export const appPaths = {
    home: '/',
    dashboard: '/',
    progress: '/progress',
    workout: '/workout',
    schedule: '/schedule',
    nutrition: '/nutrition',
    recipes: '/recipes',
    guideDetail: '/guide',
    workoutWalkthrough: '/workout/walkthrough',
    liveWorkout: '/workout/live',
    logbook: '/logbook',
    logBookEntry: '/logbook/entry',
    profile: '/profile',
    register: '/register',
    forgottenPassword: '/forgotten-password',
    resetPassword: '/reset'
};

export const adminAppPaths = {
    exerciseManager: '/admin/exercise',
    exerciseEditor: '/admin/exercise/edit',
    workoutManager: '/admin/workout',
    workoutBuilder: '/admin/workout/build',
    guideBuilder: '/admin/guide/build',
    oldWorkouts: '/admin/old'
};

const AppRoutes: React.FC = () => {
    const auth = useAuth();

    return (
        <Switch>
            {!auth.state?.isLoggedIn ?
                <>
                    <Route path={appPaths.home} exact><LoginPage /></Route>
                    <Route path={appPaths.register} exact><RegisterPage /></Route>
                    <Route path={appPaths.forgottenPassword} exact><ForgottenPasswordPage /></Route>
                    <Route path={`${appPaths.resetPassword}/:code`} exact><ResetPasswordPage /></Route>
                </>
                :
                <InitialisationLayer>
                    <MainLayout>
                        <Route path={appPaths.home} exact><DashboardPage /></Route>
                        <Route path={appPaths.progress} exact><ProgressPage /></Route>
                        <Route path={appPaths.workout} exact><WorkoutPage /></Route>
                        <Route path={appPaths.schedule} exact><SchedulePage /></Route>
                        <Route path={appPaths.nutrition} exact><NutritionPage /></Route>
                        <Route path={appPaths.recipes} exact><RecipesPage /></Route>
                        <Route path={`${appPaths.recipes}/:id`} exact><RecipeDetailPage /></Route>
                        <Route path={`${appPaths.workoutWalkthrough}/:id`}><WorkoutWalkthroughPage /></Route>
                        <Route path={`${appPaths.liveWorkout}/:id`}><LiveWorkoutPage /></Route>
                        <Route path={`${appPaths.guideDetail}/:id`}><GuideDetailPage /></Route>
                        <Route path={appPaths.logbook} exact><LogBookPage /></Route>
                        <Route path={`${appPaths.logBookEntry}/:id`} exact><LogBookEntryPage /></Route>
                        <Route path={appPaths.profile}><ProfilePage /></Route>
                        <Route path='/test'><TestIphone test='test' /></Route>
                        <Route path='/test/2'><TestIphone2 test='test' /></Route>
                        <Route render={({ location }) => {
                            return location.pathname.indexOf(appPaths.workoutWalkthrough) === -1 ? <ResumeFloater /> : null; 
                        }} />
                        {auth.state.user.type === 'admin' &&
                            <>
                                <Route path={adminAppPaths.exerciseManager} exact><ExerciseManagerPage /></Route>
                                <Route path={`${adminAppPaths.exerciseEditor}/:id?`} exact><ExerciseEditorPage /></Route>
                                <Route path={adminAppPaths.workoutManager} exact><WorkoutManagerPage /></Route>
                                <Route path={`${adminAppPaths.workoutBuilder}/:id?`} exact><WorkoutBuilderPage /></Route>
                                <Route path={`${adminAppPaths.guideBuilder}/:id`} exact><GuideEditorPage /></Route>
                                <Route path={adminAppPaths.oldWorkouts} exact><OldWorkoutsPage /></Route>
                                <Route path={`${adminAppPaths.oldWorkouts}/:slug`} exact><OldWorkoutDetailPage /></Route>
                            </>
                        }
                    </MainLayout>
                </InitialisationLayer>
            }
        </Switch>
    );
};
 
export default AppRoutes;