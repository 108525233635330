import Logo from '../assets/logo-white.svg';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import RegisterForm from '../authentication/RegisterForm/RegisterForm';
import React, { useState, useContext } from 'react';
import * as yup from 'yup'; 
import { forgottenPassword, resetForgottenPassword } from '../api/Authentication';
import AppTextField from '../components/AppForm/AppTextField';
import AppButton from '../components/AppButton';
import { useParams, Link } from 'react-router-dom';
import { appPaths } from '../AppRoutes';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        display: 'flex',
        backgroundColor: '#D5DDCC',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 200
    },
    formSection: {
        boxShadow: 'none'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& *': {
            width: '100%'
        }
    },
    logo: {
        width: '100%',
        maxWidth: '240px'
    },
    btn: {
        marginTop: theme.spacing(2)
    },
    formItem: {
        marginBottom: theme.spacing(2)
    },
    error: {
        color: theme.palette.warning.main,
        padding: '1rem 0'
    }
}));

interface Params {
    code: string
}

const ResetPasswordPage: React.FC = () => {
    const classes = useStyles();
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [complete, setComplete] = useState<boolean>(false);
    const passwordSchema = yup.string().required().min(8);
    const { code } = useParams<Params>();

    const submitPasswordReset = async () => {
        const passwordValid = await passwordSchema.isValid(password),
            confirmPasswordValid = await passwordSchema.isValid(confirmPassword),
            match = password === confirmPassword;

        if(passwordValid && confirmPasswordValid && match) {
            setError(undefined);

            const result = await resetForgottenPassword({
                password,
                code
            });

            if(result.status === 200) {
                setComplete(true);
            } else {
                setError('There was a problem trying to reset password. Please try again.');
            }
        } else {
            setError('Please make sure your password is at least 8 characters long and passwords match.');
        }
    };
    
    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                <img className={classes.logo} src={Logo} alt="Stronger You Logo" />
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={classes.formSection}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Reset password
                    </Typography>
                    {complete ?
                        <>
                            <p>Your password has been reset</p>
                            <AppButton linkPath={appPaths.home}>Login</AppButton>
                        </>
                        :
                        <>
                            <p>Please enter a new password below:</p>
                            <div className={classes.formItem}>
                                <AppTextField type='password' label='New password' placeholder='New password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className={classes.formItem}>
                                <AppTextField type='password' label='Confirm password' placeholder='Confirm password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>
                            {error && <div className={classes.error}>{error}</div>}
                            <AppButton color='secondary' className="btn" onClick={() => submitPasswordReset()}>Reset Password</AppButton>
                        </>
                    }
                </div>
            </Grid>
        </Grid>
    );
};

export default ResetPasswordPage;