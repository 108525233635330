import { IconButton, makeStyles, LinearProgress, Menu, MenuItem } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { useState } from 'react';
import AppCard from '../components/AppCard';
import { useNutrition } from './context/NutritionContext';

const useStyles = makeStyles(theme => ({
    cardBase: {
        position: 'relative',
        display: 'block',
    },
    title: {
        marginBottom: theme.spacing(2),
        fontSize: '1.15rem'
    },
    progressKey: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1)
    },
    progressKcal: {
        height: 12,
        borderRadius: 30,
        backgroundColor: theme.palette.grey[200],
        marginBottom: theme.spacing(2),
        '& > div': {
            borderRadius: 30,
            background: 'linear-gradient(90deg, rgba(255,231,133,1) 0%, rgba(255,152,35,1) 100%)'
        }
    },
    progressProtein: {
        height: 12,
        borderRadius: 30,
        backgroundColor: theme.palette.grey[200],
        marginBottom: theme.spacing(2),
        '& > div': {
            borderRadius: 30,
            background: 'linear-gradient(90deg, rgba(251,179,182,1) 0%, rgba(254,118,124,1) 100%)'
        }
    },
    progressCarbs: {
        height: 12,
        borderRadius: 30,
        backgroundColor: theme.palette.grey[200],
        marginBottom: theme.spacing(2),
        '& > div': {
            borderRadius: 30,
            background: 'linear-gradient(90deg, rgba(171,241,255,1) 0%, rgba(10,193,228,1) 100%)'
        }
    },
    progressFats: {
        height: 12,
        borderRadius: 30,
        backgroundColor: theme.palette.grey[200],
        '& > div': {
            borderRadius: 30,
            background: 'linear-gradient(90deg, rgba(134,245,134,1) 0%, rgba(42,193,42,1) 100%)'
        }
    },
    cogBtn: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        padding: 0 
    }
}));

const NutritionCard: React.FC = () => {
    const classes = useStyles();
    const { target, diaryTotals, resetTarget } = useNutrition();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReset = () => {
        resetTarget();
        handleClose();
    };

    return (
        <AppCard className={classes.cardBase}>
            {target && diaryTotals &&
            <>
                <div className={classes.title}><b>Nutrition Target</b></div>
                <div className={classes.progressKey}>
                    <span>Kcals</span>
                    <span><b>{diaryTotals?.kcal.toFixed(0)}</b>/{target?.kcal} kcal</span>
                </div>
                <LinearProgress variant='determinate' value={(diaryTotals?.kcal * 100) / target?.kcal > 100 ? 100 : (diaryTotals?.kcal * 100) / target?.kcal} className={classes.progressKcal} />
                <div className={classes.progressKey}>
                    <span>Protein</span>
                    <span><b>{diaryTotals?.protein.toFixed(0)}</b>/{target?.protein}g</span>
                </div>
                <LinearProgress variant='determinate' value={(diaryTotals?.protein * 100) / target?.protein > 100 ? 100 : (diaryTotals?.protein * 100) / target?.protein} className={classes.progressProtein} />
                <div className={classes.progressKey}>
                    <span>Carbohydrates</span>
                    <span><b>{diaryTotals?.carbohydrates.toFixed(0)}</b>/{target?.carbohydrates}g</span>
                </div>
                <LinearProgress variant='determinate' value={(diaryTotals?.carbohydrates * 100) / target?.carbohydrates > 100 ? 100 : (diaryTotals?.carbohydrates * 100) / target?.carbohydrates} className={classes.progressCarbs} />
                <div className={classes.progressKey}>
                    <span>Fats</span>
                    <span><b>{diaryTotals?.fats.toFixed(0)}</b>/{target?.fats}g</span>
                </div>
                <LinearProgress variant='determinate' value={(diaryTotals?.fats * 100) / target?.fats > 100 ? 100 : (diaryTotals?.fats * 100) / target?.fats} className={classes.progressFats} />
            </>
            }
            <IconButton className={classes.cogBtn} onClick={handleClick}>
                <SettingsIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleReset}>Reset</MenuItem>
            </Menu>
        </AppCard>
    );
};

export default NutritionCard;