import { makeStyles } from '@material-ui/core';

export interface AddButtonProps {
    label: string,
    handleClick: () => void
}

const useStyles = makeStyles(theme => ({
    base: {
        backgroundColor: 'transparent',
        border: `3px dashed ${theme.palette.secondary.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: '12px 0',
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    }
}));

const AddButton: React.FC<AddButtonProps> = ({
    label,
    handleClick
}) => {
    const classes = useStyles();

    return (
        <button className={classes.base} onClick={handleClick}>
            {label}
        </button>
    );
};

export default AddButton;