import { IconButton, makeStyles, Modal } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

interface AppModalProps {
    open: boolean,
    handleClose: () => void
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: '0 1rem',
    },
    body: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        maxWidth: 460,
        width: '100%',
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius
    },
    closeBtn: {
        position: 'absolute',
        top: theme.spacing(0),
        right: theme.spacing(0)
    }
}));

const AppModal: React.FC<AppModalProps> = ({
    open,
    handleClose,
    children
}) => {
    const classes = useStyles();

    return (
        <Modal className={classes.container} open={open} onClose={handleClose} onBackdropClick={handleClose}>
            <div className={classes.body}>
                {children}
                <IconButton onClick={handleClose} className={classes.closeBtn}>
                    <CloseIcon />
                </IconButton>
            </div>
        </Modal>
    );
};

export default AppModal;