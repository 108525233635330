import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { addNewWorkout, addWorkoutToGuide, getWorkoutById, updateWorkout } from '../../api/Workout';
import { WorkoutNew } from '../../models/Workout';
import WorkoutEditor from '../../workout/WorkoutEditor';
import queryString from 'query-string';
import { adminAppPaths } from '../../AppRoutes';
import { useDispatch } from 'react-redux';
import { actions } from '../../store';

interface PageParams {
    id?: string
}
interface PageQueryParams {
    guideId: string,
    blockId: string,
    workoutIndex: string
}

const WorkoutBuilderPage: React.FC = () => {
    const { id } = useParams<PageParams>();
    const { guideId, blockId, workoutIndex } = queryString.parse(location.search);
    const [isNew, setIsNew] = useState(false);
    const [workoutData, setWorkoutData] = useState<WorkoutNew>();
    const history = useHistory();
    const dispatch = useDispatch();

    console.log(guideId, blockId, workoutIndex);

    const handleSave = async (workout: WorkoutNew, update?: boolean) => {
        if(update) {
            const result = await updateWorkout(workout);

            if(result.status === 200) {
                dispatch(actions.workouts.addWorkout(result.data));
                if(guideId) {
                    history.push(`${adminAppPaths.guideBuilder}/${guideId}`);
                }
            }
        } else {
            if(guideId && blockId && workoutIndex) {
                const result = await addNewWorkout({...workout, type: 'guide'});
    
                if(result.status === 200 && result.data._id) {
                    dispatch(actions.workouts.addWorkout(result.data));
                    const updateGuideResult = await addWorkoutToGuide(guideId as string, blockId as string, workoutIndex as string, result.data._id);
                    
                    if(updateGuideResult.status === 200) {
                        history.push(`${adminAppPaths.guideBuilder}/${guideId}`);
                    }
                }
            } else {
                console.log(workout);
                const result = await addNewWorkout(workout);
                dispatch(actions.workouts.addWorkout(result.data));
                console.log(result);
            }
        }
    };

    const fetchWorkout = async (workoutId: string) => {
        const result = await getWorkoutById(workoutId);

        setWorkoutData(result.data);
    };

    useEffect(() => {
        if(id) {
            fetchWorkout(id);
        } else {
            setIsNew(true);
        }
    }, []);

    return (
        <>
            {(isNew || workoutData) &&
                <WorkoutEditor initialValues={workoutData} handleSave={handleSave} />
            }
        </>
    );
};

export default WorkoutBuilderPage;