import { useEffect } from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { addNewDiaryEntry, getNutritionDiaryByDate, getNutritionTarget, updateDiaryEntry } from '../../api/Nutrition';
import { useAuth } from '../../authentication/authContext/authContext';
import { DiaryTotals, Nutrition, NutritionDiary } from '../../models/Nutrition';
import { NutritionContext } from './NutritionContext';

const NutritionProvider: React.FC = ({
    children
}) => {
    const { state } = useAuth();
    const [target, setTarget] = useState<Nutrition>();
    const [date, setDate] = useState(new Date());
    const [diary, setDiary] = useState<NutritionDiary>({
        date: date,
        data: []
    });
    const [diaryTotals, setDiaryTotals] = useState<DiaryTotals>({
        kcal: 0,
        protein: 0,
        carbohydrates: 0,
        fats: 0
    });
    const [isLoading, setIsLoading] = useState(false);

    const fetchTarget = async () => {
        setIsLoading(true);
        const result = await getNutritionTarget();

        if(result.status === 200) {
            setTarget(result.data);
            setIsLoading(false);
        }
    };

    const fetchDiary = async () => {
        setIsLoading(true);
        setDiary({
            date: date,
            data: []
        });

        const result = await getNutritionDiaryByDate(date);

        if(result.status === 200) {
            setDiary(result.data);
            setIsLoading(false);
        }
    };

    const calculateTotals = () => {
        const sum: DiaryTotals = {
            kcal: 0,
            protein: 0,
            carbohydrates: 0,
            fats: 0
        };

        if(diary && diary.data) {
            for(let i = 0; i < diary.data?.length; i++) {
                sum.kcal = sum.kcal + (diary.data[i].kcals * diary.data[i].servings);
                sum.protein = sum.protein + (diary.data[i].protein * diary.data[i].servings);
                sum.carbohydrates = sum.carbohydrates + (diary.data[i].carbohydrates * diary.data[i].servings);
                sum.fats = sum.fats + (diary.data[i].fats * diary.data[i].servings);
            }
        }

        setDiaryTotals(sum);
    };

    const removeDiaryItem = async (itemId: string) => {
        const data = {...diary};
        setIsLoading(true);

        data.data = data.data.filter(item => item.eid !== itemId);

        const result = await updateDiaryEntry(data);

        if(result.status === 200) {
            fetchDiary();
            setIsLoading(false);
        }
    };

    const addWater = async () => {
        const data = {...diary};
        setIsLoading(true);

        if(data) {
            data.data && data.data.push({
                kcals: 0,
                protein: 0,
                carbohydrates: 0,
                fats: 0,
                name: 'Water',
                servings: 1,
                category: 'Water',
                eid: uuidv4()
            });
    
            const result = data._id ? await updateDiaryEntry(data) : await addNewDiaryEntry(data);
    
            if(result.status === 200) {
                fetchDiary();
                setIsLoading(false);
            }
        }
    };

    const removeWater = async () => {
        const data = {...diary};
        setIsLoading(true);

        if(data) {
            if(data.data) {
                let indexToRemove = -1;
                for(let i = 0; i < data.data.length; i++) {
                    if(data.data[i].category === 'Water' && indexToRemove < 0) {
                        indexToRemove = i;
                    }
                }

                data.data.splice(indexToRemove, 1);
            
                const result = data._id ? await updateDiaryEntry(data) : await addNewDiaryEntry(data);
    
                if(result.status === 200) {
                    fetchDiary();
                    setIsLoading(false);
                }
            }
        }
    };

    useEffect(() => {
        if(state?.isLoggedIn) {
            fetchTarget();
        }
    }, [state?.isLoggedIn]);

    useEffect(() => {
        calculateTotals();
    }, [diary]);

    useEffect(() => {
        fetchDiary();
    }, [date]);

    return (
        <NutritionContext.Provider value={{
            target,
            date,
            diary,
            diaryTotals,
            isLoading,
            setDate,
            resetTarget: () => setTarget(undefined),
            fetchDiary,
            fetchTarget,
            removeDiaryItem,
            addWater,
            removeWater
        }}>
            {children}
        </NutritionContext.Provider>
    );
};

export default NutritionProvider;