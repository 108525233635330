import { Grid, makeStyles } from '@material-ui/core';
import { Exercise } from '../models/Workout';
import ExerciseListItem from './ExerciseListItem';

interface ExerciseListProps {
    exercises: Exercise[]
}

const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: theme.spacing(4)
    }
}));

const ExerciseList: React.FC<ExerciseListProps> = ({
    exercises
}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} className={classes.grid}>
            {exercises.length > 0 && exercises.map(exercise => 
                <ExerciseListItem key={exercise._id} {...exercise} />    
            )}
            {exercises.length === 0 && <p>No exercises found.</p>}
        </Grid>
    );
};

export default ExerciseList;