import { createContext, useContext } from 'react';
import { IStatsCard, WorkoutLog } from '../../models/Logging';

export interface ILoggingContext {
    currentWeek?: WorkoutLog[],
    statsCard?: IStatsCard
    getCurrentWeek: () => void
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const LoggingContext = createContext<ILoggingContext>({
    currentWeek: [],
    getCurrentWeek: placeholderFunc
});

export const useLogging: () => ILoggingContext = () => useContext(LoggingContext);