import { makeStyles } from '@material-ui/core';
import AppPageTitle from './AppPageTitle';

interface AppPageHeadingProps {
    title?: string
}

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    }
}));

const AppPageHeading: React.FC<AppPageHeadingProps> = ({
    children,
    title
}) => {
    const classes = useStyles();
    return (
        <div className={classes.header}>
            {title && <AppPageTitle title={title} />}
            {children}
        </div>
    );
};
 
export default AppPageHeading;