import { makeStyles } from '@material-ui/core';
import { BarChart as ProgressIcon, FitnessCenter as WorkoutIcon, DateRange as CalendarIcon, Restaurant as NutritionIcon, Fastfood as RecipeIcon, AccountCircle as ProfileIcon } from '@material-ui/icons';

interface AppPageTitleProps {
    title: string,
    icon?: 'workout' | 'nutrition' | 'calendar' | 'recipes' | 'profile' | 'progress'
}

const useStyles = makeStyles((theme) => ({
    title: {
        position: 'relative',
        display: 'flex',
        margin: '0 0 1rem 0',
        fontSize: 24,
        alignItems: 'center'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        width: 48,
        height: 48,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.main,
        '& svg': {
            fill: theme.palette.common.white
        }
    },
    children: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    }
}));

const AppPageTitle: React.FC<AppPageTitleProps> = ({
    title,
    icon,
    children
}) => {
    const classes = useStyles();

    return (
        <>
            <h1 className={classes.title}>
                {icon &&
                    <div className={classes.iconContainer}>
                        {icon === 'workout' && <WorkoutIcon />}
                        {icon === 'calendar' && <CalendarIcon />}
                        {icon === 'nutrition' && <NutritionIcon />}
                        {icon === 'recipes' && <RecipeIcon />}
                        {icon === 'profile' && <ProfileIcon />}
                        {icon === 'progress' && <ProgressIcon />}
                    </div>
                }
                <span>{title}</span>
                <div className={classes.children}>
                    {children}
                </div>
            </h1>
        </>
    );
};

export default AppPageTitle;