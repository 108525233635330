import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getOldWorkouts } from '../../api/Workout';
import { adminAppPaths, appPaths } from '../../AppRoutes';
import AppPageTitle from '../../components/AppPageTitle';
import { OldWorkout } from '../../models/Workout';

const OldWorkoutsPage: React.FC = () => {
    const [workouts, setWorkouts] = useState<OldWorkout[]>();

    const fetchOldWorkouts = async () => {
        const result = await getOldWorkouts();

        if(result.status === 200) {
            setWorkouts(result.data);
        }
    };

    useEffect(() => {
        fetchOldWorkouts();
    }, []);

    return (
        <>
            <AppPageTitle title='Old Workouts' />
            {workouts?.map(workout =>
                <Link style={{
                    display: 'block',
                    width: '100%',
                    padding: '2rem',
                    backgroundColor: 'white',
                    marginBottom: '1rem'
                }} to={`${adminAppPaths.oldWorkouts}/${workout.slug}`} key={workout._id}>{workout.title}</Link>    
            )}
        </>
    );
};

export default OldWorkoutsPage;