import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { adminAppPaths } from '../../AppRoutes';
import config from '../../config';
import { selectors } from '../../store';
import GuideEditorWorkoutModal from './GuideEditorWorkoutModal';

interface GuideEditorBlockWorkoutProps {
    index: number,
    blockId: string,
    guideId: string,
    addRestDay: () => void,
    type: string
}

const useStyles = makeStyles(theme => ({
    blockContainer: {
        positon: 'relative',
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: 'rgba(255, 255, 255, .6)',
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        opacity: .8,
        border: 'none',
        '& h3': {
            margin: 0
        },
        '&:before': {
            display: 'none'
        }
    },
    itemContainer: {
        position: 'relative',
        width: '100%'
    },
    blockItem: {
        position: 'relative',
        width: '100%',
        '&:not(:last-of-type)': {
            marginRight: theme.spacing(1)
        },
        '& > h4': {
            marginBottom: theme.spacing(1)
        }
    },
    placeholder: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 60,
        backgroundColor: 'transparent',
        border: `2px dashed ${theme.palette.secondary.main}`,
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
        transition: 'all .2s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    },
    workoutItem: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 60,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1],
        border: 'none'
    },
    workoutItemImage: {
        position: 'relative',
        width: 34,
        height: 34,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2),
        overflow: 'hidden',
        '& > img': {
            position: 'relative',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    workoutItemTitle: {
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%',
        border: 'none'
    },
    restItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 60,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(2)
    },
}));

const GuideEditorBlockWorkout: React.FC<GuideEditorBlockWorkoutProps> = ({
    index,
    blockId,
    guideId,
    type,
    addRestDay
}) => {
    const classes = useStyles();
    const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
    const [workoutModal, setWorkoutModal] = useState(false);
    const workoutData = useSelector(selectors.workouts.getWorkoutById(type));

    const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
        setMenuEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuEl(null);
    };

    const handleAddRestDay = () => {
        addRestDay();
        handleClose();
    };

    const handleAddWorkout = () => {
        setWorkoutModal(true);
    };

    const item = () => {
        if(type === 'restday') {
            return (
                <div className={classes.blockItem} key={index}>
                    <h4>Day {index + 1}</h4>
                    <div className={classes.restItem}>
                        <button className={classes.workoutItemTitle} onClick={handleMenuClick}>
                        Rest
                        </button>
                        <Menu
                            anchorEl={menuEl}
                            keepMounted
                            open={Boolean(menuEl)}
                            onClose={handleClose}>
                            <MenuItem onClick={handleAddWorkout}>Workout</MenuItem>
                            <MenuItem onClick={handleAddRestDay}>Rest Day</MenuItem>
                        </Menu>
                    </div>
                </div>
            );
        } else if (type === '') {
            return (
                <div className={classes.blockItem} key={index}>
                    <h4>Day {index + 1}</h4>
    
                    <button className={classes.placeholder} onClick={handleMenuClick}>
                    Add +
                    </button>
                    <Menu
                        anchorEl={menuEl}
                        keepMounted
                        open={Boolean(menuEl)}
                        onClose={handleClose}>
                        <MenuItem onClick={handleAddWorkout}>Workout</MenuItem>
                        <MenuItem onClick={handleAddRestDay}>Rest Day</MenuItem>
                    </Menu>
                </div>
            );
        } else {
            return (
                <div className={classes.blockItem} key={index}>
                    <h4>Day {index + 1}</h4>
                    <button className={classes.workoutItem} onClick={handleMenuClick}>
                        <div className={classes.workoutItemImage}>
                            {workoutData && workoutData.image && <img src={`${config.apiBaseUrl}/media/content/${workoutData.image}`} alt={workoutData.name} />}
                        </div>
                        <div className={classes.workoutItemTitle}>
                            {workoutData ? workoutData.name : 'Workout'}
                        </div>
                    </button>
                    <Menu
                        anchorEl={menuEl}
                        keepMounted
                        open={Boolean(menuEl)}
                        onClose={handleClose}>
                        <MenuItem>
                            <Link to={`${adminAppPaths.workoutBuilder}/${type}?guideId=${guideId}`}>Edit Workout</Link>
                        </MenuItem>
                        <MenuItem onClick={handleAddRestDay}>Rest Day</MenuItem>
                    </Menu>
                </div>
            );
        }
    };

    return (
        <>
            {item()}
            <GuideEditorWorkoutModal 
                guideId={guideId}
                blockId={blockId}
                workoutIndex={index}
                open={workoutModal} 
                onClose={() => setWorkoutModal(false)} />
        </>
    );
};

export default GuideEditorBlockWorkout;