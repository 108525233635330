import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
        backgroundColor: theme.palette.secondary.main
    }
}));

const LocalLoader: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress color='inherit' />
        </div>
    );
};

export default LocalLoader;