import AppPageTitle from '../components/AppPageTitle';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { MacroCalcData } from '../models/Nutrition';
import { calculateNutritionTarget } from '../api/Nutrition';
import { useNutrition } from './context/NutritionContext';
import { ButtonGroup, Button, makeStyles } from '@material-ui/core';
import AppCard from '../components/AppCard';
import AppButton from '../components/AppButton';

const useStyles = makeStyles(theme => ({
    cardBase: {
        display: 'block',
    },
    genderPicker: {
        height: 54,
        marginBottom: theme.spacing(2)
    },
    genderActive: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    formError: {
        color: theme.palette.warning.main
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2)
    },
    formItem: {
        marginBottom: theme.spacing(2)
    },
    input: {
        display: 'block',
        width: '100%',
        padding: '0 1rem',
        height: 56,
        borderRadius: 12,
        border: `1px solid ${theme.palette.primary.main}`
    }
}));

const macroCalcSchema = Yup.object().shape({
    dob: Yup.date().required('Please select your date of birth.'),
    weight: Yup.number().required('Please enter your current body weight.'),
    height: Yup.number().required('Please enter your height.'),
    activity: Yup.string().required('Please select your activity level.'),
    goal: Yup.string().required('Please select your goal.')
});

const TargetCalculator: React.FC = () => {
    const classes = useStyles();
    const [gender, setGender] = useState('female');
    const { fetchTarget, fetchDiary } = useNutrition();
    
    const handleSubmit = async (data: MacroCalcData) => {
        const result = await calculateNutritionTarget({...data, date: new Date()});

        if(result.status === 200) {
            fetchTarget();
            fetchDiary();
        }
    };

    return (
        <>
            <AppPageTitle icon='nutrition' title='Macro Calculator' />
            <AppCard className={classes.cardBase}>
                <Formik
                    initialValues={{
                        dob: new Date(),
                        weight: '',
                        height: '',
                        activity: 'sedentary',
                        goal: 'deficit'
                    }}
                    validationSchema={macroCalcSchema}
                    onSubmit={(data: MacroCalcData) => handleSubmit(data)}>
                    {({ errors, touched }) => (
                        <Form>
                            <ButtonGroup fullWidth color='secondary' className={classes.genderPicker}>
                                <Button 
                                    className={gender === 'male' ? classes.genderActive : ''}
                                    onClick={() => setGender('male')}>Male</Button>
                                <Button 
                                    className={gender === 'female' ? classes.genderActive : ''}
                                    onClick={() => setGender('female')}>Female</Button>
                            </ButtonGroup>
                            <div className={classes.formItem}>
                                <label htmlFor="dob">Date of birth</label>
                                <Field type="date" name="dob" id="dob" className={classes.input} />
                                {errors.dob && touched.dob ? <div className={classes.formError}>{errors.dob}</div> : null}
                            </div>
                            <div className={classes.formItem}>
                                <label htmlFor="weight">Weight (KG)</label>
                                <Field type="number" step="0.01" min="0" name="weight" id="weight" className={classes.input} />
                                {errors.weight && touched.weight ? <div className={classes.formError}>{errors.weight}</div> : null}
                            </div>
                            <div className={classes.formItem}>
                                <label htmlFor="height">Height (CM)</label>
                                <Field type="number" name="height" id="height" className={classes.input} />
                                {errors.height && touched.height ? <div className={classes.formError}>{errors.height}</div> : null}
                            </div>
                            <div className={classes.formItem}>
                                <label htmlFor="activity">Activity level</label>
                                <Field as="select" name="activity" id="activity" className={classes.input}>
                                    <option value="sedentary">Sedentary (little to no activity)</option>
                                    <option value="light">Light (3-4 days exercise)</option>
                                    <option value="moderate">Moderate (4-5 days intense exercise with high step count)</option>
                                    <option value="Very">Very (5-6 days intense exercise with high step count)</option>
                                </Field>
                                {errors.activity && touched.activity ? <div className={classes.formError}>{errors.activity}</div> : null}
                            </div>
                            <div className={classes.formItem}>
                                <label htmlFor="goal">Goal</label>
                                <Field as="select" name="goal" id="goal" className={classes.input}>
                                    <option value="deficit">Lose weight and tone-up</option>
                                    <option value="maintenance">Maintain weight</option>
                                    <option value="surplus">Gain strength and muscle</option>
                                </Field>
                                {errors.goal && touched.goal ? <div className={classes.formError}>{errors.goal}</div> : null}
                            </div>
                            <AppButton color='secondary' type='submit' fullWidth>Calculate</AppButton>
                        </Form>
                    )}
                </Formik>
            </AppCard>
        </>
    );
};

export default TargetCalculator;