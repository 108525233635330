import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction, Update } from '@reduxjs/toolkit';
import { addNewExercise, getAllExercises } from '../api/Workout';
import { Exercise } from '../models/Workout';
import { ExerciseFormValues } from '../workout/admin/ExerciseForm';

const sliceName = 'exercises';

const fetchAllExercisesAsyncThunk = createAsyncThunk(
    `${sliceName}/fetchAllExercises`,
    async () => {
        const response = await getAllExercises();

        if(response.status === 401) {
            sessionStorage.clear();
            window.location.pathname = '/';
        }

        return response;
    }
);

const addNewExerciseAsyncThunk = createAsyncThunk(
    `${sliceName}/addNewExercise`,
    async (exercise: ExerciseFormValues) => {
        const response = await addNewExercise(exercise);
        return response;
    }  
);

const entityAdapter = createEntityAdapter<Exercise>(
    {
        selectId: exercise => exercise._id
    }
);

interface SliceState {
    initialised: boolean,
    entityState: EntityState<Exercise>
}

const initialState: SliceState = {
    initialised: false,
    entityState: entityAdapter.getInitialState()
};

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addToExercises: (state, { payload }: PayloadAction<Exercise>) => {
            entityAdapter.addOne(state.entityState, payload);
        },
        updateExercise: (state, { payload }: PayloadAction<Update<Exercise>>) => {
            entityAdapter.updateOne(state.entityState, payload);
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAllExercisesAsyncThunk.fulfilled, (state, { payload }) => {
            state.initialised = true;
            entityAdapter.removeAll(state.entityState);
            entityAdapter.setAll(state.entityState, payload.data);
        });
        builder.addCase(addNewExerciseAsyncThunk.fulfilled, (state, { payload }) => {
            entityAdapter.addOne(state.entityState, payload.data);
        });
    }
});

export const {
    name,
    reducer
} = slice;

type RootReducerState = {
    [sliceName]: SliceState,
};

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();

const doFetchAll = (
    dispatch: (action: unknown) => void) => {
    dispatch(fetchAllExercisesAsyncThunk());
};

const addExercise = (
    dispatch: (action: unknown) => void, exercise: ExerciseFormValues) => {
    dispatch(addNewExerciseAsyncThunk(exercise));
};

export const actions = {
    fetchAll: () => (dispatch: (action: unknown) => void): void => {
        doFetchAll(dispatch);
    },
    addExercise: (exercise: Exercise) => (dispatch: (action: unknown) => void): void => {
        dispatch(slice.actions.addToExercises(exercise));
    },
    updateExercise: (exercise: Exercise) => (dispatch: (action: unknown) => void): void => {
        dispatch(slice.actions.updateExercise({id: exercise._id, changes: exercise}));
    },
};

const createSliceSelector = <T, >(selector: (state: SliceState) => T) => {
    return createSelector(
        selectSliceState,
        selector,
    );
};

export const selectors = {
    all: createSliceSelector(state => entitySelectors.selectAll(state.entityState).reverse()),
    getById: (id: string) => createSliceSelector(state => entitySelectors.selectById(state.entityState, id)),
    getExerciseCategories: () => createSliceSelector(state => {
        const exercises = entitySelectors.selectAll(state.entityState);
        const categories = exercises.map(e => e.category);

        return Array.from(new Set(categories.flat(1)));
    }),
    isInitialised: createSliceSelector(state => state.initialised)
};



