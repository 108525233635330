import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { duplicateGuideBlock, getGuideById, updateGuide } from '../../api/Workout';
import { Guide } from '../../models/Workout';
import GuideEditorBlocks from './GuideEditorBlocks';
import AppTextField from '../../components/AppForm/AppTextField';
import { Checkbox, makeStyles, TextField } from '@material-ui/core';
import FileUpload from '../../components/AppForm/FileUpload';
import AppButton from '../../components/AppButton';
import { successToast } from '../../toast';

interface GuideEditorParams {
    id: string
}

const useStyles = makeStyles(theme => ({
    metaInput: {
        marginBottom: theme.spacing(2)
    }
}));

const GuideEditor: React.FC = () => {
    const classes = useStyles();
    const { id } = useParams<GuideEditorParams>();
    const [initialised, setInitialised] = useState(false);
    const [guideData, setGuideData] = useState<Guide>();

    const handleDragNDrop = (result: DropResult, provided: ResponderProvided) => {
        if(guideData && guideData.blocks) {
            if(result.source.droppableId === 'BlockContainer' && result.destination) {
                const blocksCopy = JSON.parse(JSON.stringify({...guideData}));
                const sourceIndex = result.source.index;
                const desitnationIndex = result.destination.index;
                const blockClone = {...blocksCopy.blocks[sourceIndex]};
        
                blocksCopy.blocks.splice(sourceIndex, 1);
                blocksCopy.blocks.splice(desitnationIndex, 0, blockClone);
                setGuideData(blocksCopy);
            }
        }
    };

    const addRestDay = (blockId: string, workoutIndex: number) => {
        const guideCopy: Guide = JSON.parse(JSON.stringify({...guideData}));

        guideCopy.blocks && guideCopy.blocks.map(block => {
            if(block.blockId === blockId) {
                block.workouts[workoutIndex] = 'restday';
            }
        });

        setGuideData(guideCopy);
    };

    const addNewBlock = () => {
        const guideCopy: Guide = JSON.parse(JSON.stringify({...guideData}));

        guideCopy.blocks && guideCopy.blocks.push({
            blockId: uuidv4(),
            order: 0,
            workouts: [
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]
        });

        setGuideData(guideCopy);
    };

    const deleteBlock = (blockId: string) => {
        const guideCopy: Guide = JSON.parse(JSON.stringify({...guideData}));

        guideCopy.blocks = guideCopy.blocks ? guideCopy.blocks.filter(block => block.blockId !== blockId) : [];

        setGuideData(guideCopy);
    };

    const duplicateBlock = async (blockId: string) => {
        const block = guideData && guideData.blocks && guideData.blocks.find(block => block.blockId === blockId);

        if(block) {
            const result = await duplicateGuideBlock(id, blockId);

            if(result.status === 200) {
                fetchGuide(id);
            }
        }
    };

    const fetchGuide = async (guideId: string) => {
        const result = await getGuideById(guideId);

        setGuideData(result.data);
    };

    const updateGuideData = async () => {
        if(guideData) {
            const result = await updateGuide(guideData);

            console.log(result);
        }
    };

    useEffect(() => {
        if(id) {
            fetchGuide(id);
        }
    }, []);

    useEffect(() => {
        if(!initialised) {
            setInitialised(true);
        } else {
            updateGuideData();
        }
    }, [guideData?.blocks]);

    return (
        <>
            {guideData && guideData.blocks &&
                <>
                    <AppTextField className={classes.metaInput} value={guideData.name} name='name' label='Guide name' onChange={(e) => setGuideData({
                        ...guideData,
                        name: e.target.value
                    })} />
                    <AppTextField className={classes.metaInput} multiline rows={6} value={guideData.description} name='description' label='Description' onChange={(e) => setGuideData({
                        ...guideData,
                        description: e.target.value
                    })} />
                    <FileUpload name='image' label='Guide Image' value={guideData.image} onChange={(name: string, value: string) => setGuideData({
                        ...guideData,
                        image: value
                    })} />
                    <div>
                        <Checkbox name='isChallenge' value={guideData.isChallenge} checked={guideData.isChallenge} onChange={() => setGuideData({
                            ...guideData,
                            isChallenge: !guideData.isChallenge
                        })} />
                        Is challenge?
                    </div>
                    {guideData.isChallenge &&
                        <>
                            <div>
                                <label>Start date</label>
                                <TextField type='date' name="startsAt" value={guideData.startsAt} onChange={(e) => setGuideData({
                                    ...guideData,
                                    startsAt: e.target.value as unknown as Date
                                })} />
                            </div>
                            <div>
                                <label>Join by</label>
                                <TextField type='date' name="joinBy" value={guideData.joinBy} onChange={(e) => setGuideData({
                                    ...guideData,
                                    joinBy: e.target.value as unknown as Date
                                })} />
                            </div>
                        </>
                    }
                    <AppButton color='primary' onClick={updateGuideData}>Update</AppButton>
                    <GuideEditorBlocks
                        guideId={id}
                        blocks={guideData.blocks}
                        handleDragNDrop={handleDragNDrop}
                        addRestDay={addRestDay}
                        addNewBlock={addNewBlock}
                        deleteBlock={deleteBlock}
                        duplicateBlock={duplicateBlock} />
                </>
            }
        </>
    );
};

export default GuideEditor;