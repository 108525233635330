import { makeStyles } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { WorkoutNewFull } from '../../models/Workout';
import { useWorkoutWalkthrough } from './Context/WorkoutWalkthroughContext';
import WorkoutWalkthroughItem from './WorkoutWalkthroughItem';
import { Timer as TimerIcon } from '@material-ui/icons';
import WorkoutWalkthroughComplete from './WorkoutWalkthroughComplete';

export interface WorkoutWalkthroughProps {
    workout: WorkoutNewFull
}

const styles = {
    container: {
        width: 414,
        height: 736
    },
    slideContainer: {
        height: '100vh',
    },
    // slide: {
    //     padding: 15,
    //     minHeight: 736,
    //     color: '#fff',
    // },
    slide1: {
        backgroundColor: '#FEA900',
    },
    slide2: {
        backgroundColor: '#B3DC4A',
    },
    scroll: {
        height: 736,
        overflow: 'scroll',
    },
    slide3: {
        height: 1200,
        backgroundColor: '#6AC0FF',
    },
    slide4: {
        height: 1200,
        backgroundColor: 'pink'
    }
};

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 10001
    },
    slideMain: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: 'white'
    },
    videoContainer: {
        position: 'relative',
        width: '100%',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent'
        }
    },
    exerciseInstructions: {
        position: 'relative',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    floatingBox: {
        position: 'fixed',
        display: 'flex',
        top: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 12,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[20],
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden'
    },
    timerFloatingNotifier: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        fontWeight: theme.typography.fontWeightMedium,
        '& > svg': {
            marginRight: theme.spacing(1)
        }
    },
    currentStepFloatingNotifier: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(2),
    }
}));
  

const WorkoutWalkthroughContainer: React.FC = () => {
    const classes = useStyles();
    const { workout, currentStep, timer, setCurrentStep } = useWorkoutWalkthrough();

    return (
        <div className={classes.container}>
            {workout && currentStep + 1 <= workout.steps.length &&
            <div className={classes.floatingBox}>
                <div className={classes.timerFloatingNotifier}>
                    <TimerIcon />
                    {timer}
                </div>
                <div className={classes.currentStepFloatingNotifier}>{currentStep + 1} / {workout?.steps.length}</div>
            </div>
            }
            {/* <SwipeableViews 
                containerStyle={styles.slideContainer} 
                axis="y" 
                resistance 
                slideStyle={{
                    position: 'relative',
                    minHeight: '100%'
                }}
                index={currentStep}
                onChangeIndex={(index) => setCurrentStep(index)}> */}
            {workout && workout.steps.map((step, index) =>
                <WorkoutWalkthroughItem key={step.stepId} stepNo={index} step={step} />    
            )}
            {workout && currentStep === workout.steps.length && 
                <WorkoutWalkthroughComplete />
            }
            {/* </SwipeableViews> */}
        </div>
    );
};

export default WorkoutWalkthroughContainer;