import { makeStyles } from '@material-ui/core';
import { ArrowForward as ArrowIcon, DriveEtaOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { appPaths } from '../AppRoutes';
import config from '../config';
import { Recipe } from '../models/Recipes';
import { selectors } from '../store';

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(1)
    },
    slide: {
        width: 'auto !important',
    },
    itemCard: {
        position: 'relative',
        display: 'inline-block',
        width: 160,
        height: 190,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden'
    },
    itemCardImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.grey[200],
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    itemCardName: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '1.25rem .75rem .75rem',
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none',
        background: 'linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%)',
    },
    viewAllCard: {
        position: 'relative',
        display: 'inline-block',
        width: 160,
        height: 190,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: 'none'
    },
    viewAllText: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: theme.spacing(2),
        fontSize: '1.5rem',
        '& svg': {
            width: 32,
            height: 32,
            marginLeft: theme.spacing(2)
        }
    },
    newTag: {
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        padding: '2px 6px',
        textTransform: 'uppercase'
    }
}));

interface RecipeCarouselItemProps {
    recipe: Recipe
}

const RecipeCarouselItem: React.FC<RecipeCarouselItemProps> = ({
    recipe
}) => {
    const { push } = useHistory();
    const classes = useStyles();

    return (
        <div onClick={() => push(`${appPaths.recipes}/${recipe._id}`)} className={classes.itemCard}>
            <div className={classes.itemCardImage}>
                {recipe.image && <img src={`${config.apiBaseUrl}/media/${recipe.image}`} alt={recipe.name} />}
            </div>
            <div className={classes.itemCardName}>
                {recipe.name}
            </div>
        </div>
    );
};

const RecentRecipesCarousel: React.FC = () => {
    const { push } = useHistory();
    const classes = useStyles();
    const recipes = useSelector(selectors.recipes.all);

    const recipeItems = recipes.reverse().slice(-10).reverse();

    return (
        <>
            <h3 className={classes.title}>Latest Recipes</h3>
            <Swiper slidesPerView={'auto'} spaceBetween={16}>
                {recipeItems.map((item, i) => 
                    <SwiperSlide key={item._id} className={classes.slide}>
                        <RecipeCarouselItem recipe={item} />
                        {i < 2 && <div className={classes.newTag}>New</div>}
                    </SwiperSlide>)}
                <SwiperSlide className={classes.slide}>
                    <div className={classes.viewAllCard} onClick={() => push(appPaths.recipes)}>
                        <div className={classes.viewAllText}>
                            View all
                            <ArrowIcon />    
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
};

export default RecentRecipesCarousel;