import * as Yup from 'yup';

export { default as LoginForm } from './LoginFormContainer';

export interface LoginFormValues {
    email: string,
    password: string
}

export const LoginValidationSchema: Yup.SchemaOf<LoginFormValues> = Yup.object().shape({
    email: Yup.string().required('Please provide your email address').email('Please enter a valid email address'),
    password: Yup.string().required('Please enter your password')
});

export const initialLoginFormValues = {
    email: '',
    password: ''
};