import React, { useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import config from '../../config';
import AppButton from '../AppButton';
import { makeStyles } from '@material-ui/core';

interface IPaymentMethodProps {
    customTitle?: string,
    newUser?: boolean,
    history?: any
}

const useStyles = makeStyles(theme => ({
    seperator: {
        marginTop: theme.spacing(2)
    }
}));

const PaymentMethod = ({ customTitle, newUser = false, history }: IPaymentMethodProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            toast.error('There was an error loading this action. Please refresh the page and try again.');
            return;
        }
    
        const cardElement = elements.getElement(CardElement);
    
        if(cardElement) {
            const result = await stripe.createToken(cardElement);

            stripePaymentMethodHandler(result);
        } else {
            toast.error('There was an issue loading this action. Please refresh the page and try again.');
        }
    };

    const stripePaymentMethodHandler = async (result: any) => {
        if(result.error) {
            console.log(result.error);
            toast.error('There was an issue loading this action. Please refresh the page and try again.');
        } else {
            // Check if coupon exists and what type it is

            await fetch(`${config.apiBaseUrl}/user/${newUser ? 'upgrade' : 'paymentmethod'}`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ token: result.token })
            })
                .then(async (res) => {
                    if(res.status === 200) {
                        if(newUser) {
                            history.push('/');
                        } else {
                            toast.success('Your payment method has been updated.'); 
                        }                  
                    } else if (res.status === 500) {
                        toast.error('There was an issue loading this action. Please refresh the page and try again.');
                        console.log(res);
                    // setError("Server error. Please try again.");
                    // globalContext.setLoading && globalContext.setLoading(false);
                    } else {
                        toast.error('There was an issue loading this action. Please refresh the page and try again.');
                        console.log(res);
                    // setError("Email address or password do not match.");
                    // globalContext.setLoading && globalContext.setLoading(false);
                    }
                })
                .catch(err => {
                    toast.error('There was an issue loading this action. Please refresh the page and try again.');
                    console.log(err);
                // setError("Server error. Please try again.");
                // globalContext.setLoading && globalContext.setLoading(false);
                });
        }
    };

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)}>
                <h2 className="mb-1">{customTitle ? customTitle : 'Update Payment Method'}</h2>
                <p>Enter your payment method below - future payments will be taken from this card:</p>
                <div className="stripe-payment-wrapper">
                    <CardElement
                        options={{
                            hidePostalCode: true,
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    backgroundColor: '#FFFFFF',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }} />
                </div>
                <div className={classes.seperator}></div>
                <AppButton type='submit' color='secondary'>Submit</AppButton>
            </form>
        </>
    );
};

export default PaymentMethod;