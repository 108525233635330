import { IconButton, InputAdornment, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Search as SearchIcon, FilterList as FilterIcon } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DiaryCategory } from '../models/Nutrition';
import { Recipe } from '../models/Recipes';
import { selectors } from '../store';
import AppPageTitle from '../components/AppPageTitle';
import AppTextField from '../components/AppForm/AppTextField';
import { useSelector } from 'react-redux';
import AppCard from '../components/AppCard';
import RecipeItem from '../nutrition/DiaryAdditionModal/RecipeItem';
import { appPaths } from '../AppRoutes';

const useStyles = makeStyles(theme => ({
    filter: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2),
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto'
    },
    filterBtn: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
        marginLeft: theme.spacing(1)
    },
    customBtn: {
        fontSize: '0.85rem',
        fontWeight: theme.typography.fontWeightBold
    },
    catActive: {
        backgroundColor: theme.palette.grey[200]
    },
    recipeList: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2),
        height: 'calc(100vh - 330px)',
        overflowY: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    recipeLink: {
        textDecoration: 'none'
    }
}));

const RecipesPage: React.FC = () => {
    const classes = useStyles();
    const allRecipes = useSelector(selectors.recipes.all);
    const [search, setSearch] = useState('');
    const [categoryFilter, setCategoryFilter] = useState<DiaryCategory>('All');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };
    const reset = () => {
        setSearch('');
    };

    useEffect(() => {
        handleCloseFilter();
    }, [categoryFilter]);

    const recipes = allRecipes.reverse().filter(recipe => categoryFilter !== 'All' ? recipe.category?.includes(categoryFilter) : recipe).filter(recipe => search !== '' ? recipe.name.toLowerCase().includes(search.toLowerCase()) : recipe);

    return (
        <>
            <AppPageTitle title='Recipes' icon='recipes' />
            <div className={classes.filter}>
                <AppTextField 
                    placeholder='Search...'
                    value={search} 
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'><SearchIcon /></InputAdornment>
                    }} />
                <IconButton onClick={handleClick} className={classes.filterBtn}>
                    <FilterIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseFilter}
                >
                    <MenuItem className={categoryFilter === 'All' ? classes.catActive : ''} onClick={() => setCategoryFilter('All')}>All</MenuItem>
                    <MenuItem className={categoryFilter === 'Breakfast' ? classes.catActive : ''} onClick={() => setCategoryFilter('Breakfast')}>Breakfast</MenuItem>
                    <MenuItem className={categoryFilter === 'Lunch' ? classes.catActive : ''} onClick={() => setCategoryFilter('Lunch')}>Lunch</MenuItem>
                    <MenuItem className={categoryFilter === 'Dinner' ? classes.catActive : ''} onClick={() => setCategoryFilter('Dinner')}>Dinner</MenuItem>
                    <MenuItem className={categoryFilter === 'Snacks' ? classes.catActive : ''} onClick={() => setCategoryFilter('Snacks')}>Snacks</MenuItem>
                </Menu>
            </div>
            <AppCard className={classes.recipeList}>
                {recipes.length > 0 ? 
                    recipes.map(recipe => <Link to={`${appPaths.recipes}/${recipe._id}`} key={recipe._id} className={classes.recipeLink}><RecipeItem recipe={recipe} handleSelect={() => null} /></Link>)
                    :
                    <p>No food items found</p>}
            </AppCard>
        </>
    );
};

export default RecipesPage;