import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { getAllRecipes } from '../api/Recipe';
import { Recipe } from '../models/Recipes';

const sliceName = 'recipes';

const fetchAllRecipesAsyncThunk = createAsyncThunk(
    `${sliceName}/fetchAllRecipes`,
    async () => {
        const response = await getAllRecipes();

        if(response.status === 401) {
            sessionStorage.clear();
            window.location.pathname = '/';
        }
        
        return response;
    }
);

const entityAdapter = createEntityAdapter<Recipe>(
    {
        selectId: recipe => recipe._id
    }
);

interface SliceState {
    entityState: EntityState<Recipe>
}

const initialState: SliceState = {
    entityState: entityAdapter.getInitialState()
};

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(fetchAllRecipesAsyncThunk.fulfilled, (state, { payload }) => {
            entityAdapter.removeAll(state.entityState);
            entityAdapter.setAll(state.entityState, payload.data);
        });
    }
});

export const {
    name,
    reducer
} = slice;

type RootReducerState = {
    [sliceName]: SliceState,
};

const selectSliceState = (state: RootReducerState) => state[sliceName];
const entitySelectors = entityAdapter.getSelectors();

const doFetchAll = (
    dispatch: (action: unknown) => void) => {

    dispatch(fetchAllRecipesAsyncThunk());
};

export const actions = {
    fetchAll: () => (dispatch: (action: unknown) => void): void => {
        doFetchAll(dispatch);
    } 
};

const createSliceSelector = <T, >(selector: (state: SliceState) => T) => {
    return createSelector(
        selectSliceState,
        selector,
    );
};

export const selectors = {
    getById: (id: string) => createSliceSelector(state => entitySelectors.selectById(state.entityState, id)),
    all: createSliceSelector(state => entitySelectors.selectAll(state.entityState))
};



