import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addWorkoutLog } from '../../../api/Log';
import { appPaths } from '../../../AppRoutes';
import { SetLog, WorkoutNewFull } from '../../../models/Workout';
import { WorkoutWalkthroughContext } from './WorkoutWalkthroughContext';

interface WorkoutWalkthroughProviderProps {
    test: null
}

const generateLogs = (workout: WorkoutNewFull) => {
    const logs: SetLog[] = [];

    for(let i = 0; i < workout.steps.length; i++) {
        workout.steps[i].exercises.map(exercise => {
            exercise.sets.forEach(set => {
                logs.push({
                    stepExerciseSetId: set.stepExerciseSetId,
                    exerciseId: exercise.exerciseId
                });
            });
        });
    }

    return logs;
};

const WorkoutWalkthroughProvider: React.FC = ({
    children
}) => {
    const history = useHistory();
    const [guideScheduleId, setGuideScheduleId] = useState<string>();
    const [workout, setWorkout] = useState<WorkoutNewFull | undefined>();
    const [currentStep, setCurrentStep] = useState(0);
    const [setLogs, setSetLogs] = useState<SetLog[]>();
    const [timer, setTimer] = useState('00:00:00');
    const [timeCapture, setTimeCapture] = useState<string>();

    const updateSetLog = (setLogId: string, property: 'reps' | 'weight', value: number) => {
        const logs = setLogs ? [...setLogs] : [];

        if(logs.length > 0) {
            for(let i = 0; i < logs.length; i++) {
                if(logs[i].stepExerciseSetId === setLogId) {
                    logs[i][property] = value;
                }
            }
        }

        setSetLogs(logs);
    };

    const resetWorkoutWalkthrough = () => {
        setWorkout(undefined);
        setCurrentStep(0);
        setSetLogs(undefined);
        setTimer('00:00:00');
    };
    
    const completeWorkout = async () => {
        const time = '' + timer + '';

        const result = await addWorkoutLog({
            date: new Date(),
            workoutId: workout ? workout._id : '',
            setLogs: setLogs ? setLogs : [],
            guideScheduleId: guideScheduleId,
            time: time
        });

        if(result.status === 200) {
            history.push(appPaths.dashboard);
            resetWorkoutWalkthrough();
        }
    };

    useEffect(() => {
        if(workout) {
            const logs = generateLogs(workout);
            setSetLogs(logs);

            const start = new Date();

            const timer = setInterval(() => {
                const time = moment.utc(moment(new Date()).diff(start)).format('HH:mm:ss');

                setTimer(time);
            }, 500);

            return () => clearInterval(timer);
        }
    }, [workout]);

    useEffect(() => {
        if(workout && currentStep + 1 > workout.steps.length) {
            const time = '' + timer + '';
            setTimeCapture(time);
        }
    }, [currentStep]);

    return (
        <WorkoutWalkthroughContext.Provider value={{
            workout: workout,
            currentStep,
            timer,
            timeCapture,
            guideScheduleId,
            setWorkout,
            setCurrentStep,
            setGuideScheduleId,
            getLogById: (id: string) => setLogs?.find(log => log.stepExerciseSetId === id),
            updateSetLog,
            completeWorkout,
            resetWorkoutWalkthrough
        }}>
            {children}
        </WorkoutWalkthroughContext.Provider>
    );
};

export default WorkoutWalkthroughProvider;