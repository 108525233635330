import { makeStyles } from '@material-ui/core';
import AddButton from '../components/AddButton';
import { useNutrition } from './context/NutritionContext';
import NutritionDiaryItem from './NutritionDiaryItem';

export interface NutritionDiaryCategoryProps {
    category: string,
    handleAdd: () => void
}

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom: theme.spacing(1),
        fontSize: '1.15rem'
    }
}));

const NutritionDiaryCategory: React.FC<NutritionDiaryCategoryProps> = ({
    category,
    handleAdd
}) => {
    const classes = useStyles();
    const { diary, removeDiaryItem } = useNutrition();

    const diaryItems = diary?.data.filter(item => item.category === category);

    return (
        <>
            <h2 className={classes.title}>{category}</h2>
            {diaryItems && diaryItems.length > 0 && diaryItems?.map(item =>
                <NutritionDiaryItem key={item.eid} item={item} />
            )}
            <AddButton label='Add +' handleClick={handleAdd} />
        </>
    );
};

export default NutritionDiaryCategory;