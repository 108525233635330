import { makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useHttpRequest } from '../../api';
import { getAllGuides } from '../../api/Workout';
import AppCard from '../../components/AppCard';
import AppLoader from '../../components/AppLoader';
import AppPageTitle from '../../components/AppPageTitle';
import UpcomingChallengeList from '../UpcomingChallengeList/UpcomingChallengeList';
import GuideItem from './GuideItem';


const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        marginBottom: theme.spacing(2)
    },
    gridList: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 16
    },
    upcomingChallenge: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 16,
        marginBottom: theme.spacing(2)
    },
    upcomingChallengeImage: {
        position: 'relative',
        '& > div': {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            maxHeight: '200px',

            '& > img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: theme.shape.borderRadius
            }
        }
    },
    upcomingChallengeContent: {
        display: 'flex',
        alignItems: 'center'
    },
    uPre: {
        textTransform: 'uppercase',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    uName: {
        fontSize: '1.2rem'
    },
    uDate: {

    }
}));

const WorkoutGuideList: React.FC = () => {
    const classes = useStyles();
    const getWorkoutGuides = useCallback(() => getAllGuides(), []);
    const { result: workoutGuides, isLoading } = useHttpRequest(getWorkoutGuides);
    
    const guides = workoutGuides;

    return (
        <div className={classes.container}>
            {/* <AppPageTitle title='Workout Guides' icon='workout' /> */}
            <UpcomingChallengeList />
            <AppCard>
                {isLoading ?
                    <AppLoader />
                    :
                    <div className={classes.gridList}>
                        {guides?.map(guide => 
                            <GuideItem key={guide._id} guide={guide} />
                        )}
                    </div>
                }
            </AppCard>
        </div>
    );
};

export default WorkoutGuideList;