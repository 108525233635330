import axios, { AxiosResponse } from 'axios';
import { AddToScheduleResponse, GetMyScheduleResponse } from '../models/Schedule';
import { buildApiUrl } from './common';

export const getMySchedule = async (): Promise<AxiosResponse<GetMyScheduleResponse>> => {
    const url = buildApiUrl('v2/schedule');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const addToMySchedule = async (guideId: string, startDate: Date): Promise<AxiosResponse<AddToScheduleResponse>> => {
    const url = buildApiUrl('v2/schedule');

    const response = await axios.post(url, { guideId, startDate }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const cancelGuideSchedule = async (guideScheduleId: string): Promise<AxiosResponse<AddToScheduleResponse>> => {
    const url = buildApiUrl('v2/schedule/cancel');

    const response = await axios.post(url, { guideScheduleId }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};