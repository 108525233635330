import { makeStyles } from '@material-ui/core';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { GuideBlock } from '../../models/Workout';
import GuideEditorBlockItem from './GuideEditorBlockItem';

const useStyles = makeStyles(theme => ({
    blockContainer: {
        positon: 'relative',
        width: '100%',
        padding: theme.spacing(3),
        backgroundColor: 'rgba(255, 255, 255, .6)',
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        opacity: .8,
        border: 'none',
        '& h3': {
            margin: 0
        },
        '&:before': {
            display: 'none'
        }
    },
    blockItem: {
        position: 'relative',
        width: '100%',
        '&:not(:last-of-type)': {
            marginRight: theme.spacing(1)
        },
        '& > h4': {
            marginBottom: theme.spacing(1)
        }
    },
    placeholder: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 60,
        backgroundColor: 'transparent',
        border: `2px dashed ${theme.palette.secondary.main}`,
        borderRadius: theme.shape.borderRadius,
        cursor: 'pointer',
        transition: 'all .2s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    },
    workoutItem: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 60,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1]
    },
    workoutItemImage: {
        position: 'relative',
        width: 34,
        height: 34,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2),
        overflow: 'hidden',
        '& > img': {
            position: 'relative',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    workoutItemTitle: {
        fontWeight: theme.typography.fontWeightMedium
    },
    restItem: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 60,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(2)
    },
    addBlockBtn: {
        width: '100%',
        backgroundColor: 'transparent',
        border: `3px dashed ${theme.palette.secondary.main}`,
        height: 80,
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    }
}));

interface GuideEditorBlocksProps {
    guideId: string,
    blocks: GuideBlock[],
    handleDragNDrop: (result: DropResult, provided: ResponderProvided) => void,
    addRestDay: (blockId: string, workoutIndex: number) => void,
    addNewBlock: () => void,
    deleteBlock: (blockId: string) => void,
    duplicateBlock: (blockId: string) => void
}

const GuideEditorBlocks: React.FC<GuideEditorBlocksProps> = ({
    guideId,
    blocks,
    addRestDay,
    addNewBlock,
    deleteBlock,
    handleDragNDrop,
    duplicateBlock
}) => {
    const classes = useStyles();

    return (
        <DragDropContext
            onDragEnd={handleDragNDrop}>
            <Droppable droppableId='BlockContainer' isCombineEnabled={true} key={1}>
                {(provided, snapshot) => {
                    return (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                background: snapshot.isDraggingOver ? '#cceeff': ''
                            }}>
                            {blocks && blocks.map((block, i) =>
                                <GuideEditorBlockItem guideId={guideId} block={block} index={i} key={block.blockId} addRestDay={addRestDay} duplicateBlock={() => duplicateBlock(block.blockId)} deleteBlock={() => deleteBlock(block.blockId)} />
                            )}
                            {provided.placeholder}
                            <button className={classes.addBlockBtn} onClick={addNewBlock}>Add Block +</button>
                        </div>
                    );
                }}
            </Droppable>
        </DragDropContext>
    );
};

export default GuideEditorBlocks;