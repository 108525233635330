import { makeStyles, Paper, Tabs, Tab, Grid, Divider, AppBar } from '@material-ui/core';
import SwiperCore, {
    EffectCoverflow,Pagination
} from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import AppPageTitle from '../components/AppPageTitle';
import WorkoutGuideList from '../workout/WorkoutGuideList/WorkoutGuideList';
import LiveWorkoutCarousel from '../workout/LiveWorkoutCarousel/LiveWorkoutCarousel';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LiveWorkoutSearch from '../workout/LiveWorkoutSearch/LiveWorkoutSearch';

SwiperCore.use([EffectCoverflow,Pagination]);

const useStyles = makeStyles((theme) => ({
    guideContainer: {
        paddingTop: theme.spacing(2),
    },
    guideContainerHeader: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        // '& > h1': {
        //     position: 'relative',
        //     flex: 'none',
        //     display: 'inline-block',
        //     margin: 0,
        //     marginBottom: theme.spacing(2)
        // },
        // '& h1::before': {
        //     content: '""',
        //     position: 'absolute',
        //     bottom: 6,
        //     left: 0,
        //     width: '100%',
        //     height: 10,
        //     backgroundColor: theme.palette.secondary.main
        // },
        // '& h1 > span': {
        //     position: 'relative',
        //     zIndex: 1
        // }
    },
    guideContainerHeaderCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& > h1': {
            position: 'relative',
            margin: '1rem 0'
        },
        '& h1::before': {
            content: '""',
            position: 'absolute',
            bottom: 6,
            left: 0,
            width: '100%',
            height: 10,
            backgroundColor: theme.palette.secondary.main
        },
        '& h1 > span': {
            position: 'relative',
            zIndex: 1
        }
    },
    tabs: {
        flexGrow: 0,
        boxShadow: 'none',
        marginBottom: '1rem',
        '& .MuiTabs-root': {
            minHeight: '68px',
            paddingLeft: '.5rem',
            paddingRight: '.5rem',
            alignItems: 'center',
        },
        '& .MuiTab-textColorPrimary': {
            borderRadius: theme.shape.borderRadius
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius
        },
        '& .MuiTabs-indicator': {
            display: 'none !important'
        }
    },
    tabsCateogires: {
        flexGrow: 0,
        boxShadow: 'none',
        marginTop: theme.spacing(1),
        '& .MuiTab-textColorPrimary': {
            borderRadius: theme.shape.borderRadius
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius
        },
        '& .MuiTabs-indicator': {
            display: 'none !important'
        }
    },
    tab: {

    },
    grid: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    guideItem: {
        position: 'relative',
        // borderRadius: theme.shape.borderRadius,
        transition: 'all .2s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            transform: 'translateY(-8px)',
        }
    },
    slideItem: {
        position: 'relative',
        width: '360px',
        // borderRadius: theme.shape.borderRadius,
        transition: 'all .2s ease-in-out',
        margin: '0 1.5rem',
        '&.swiper-slide-active': {
            backgroundColor: theme.palette.common.white,
            transform: 'scale(1.2)'
        }
    },
    guideItemImage: {
        position: 'relative',
        width: '100%',
        height: 260,
        backgroundColor: theme.palette.secondary.main,
        '& > img': {
            position: 'relative',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    guideItemTitle: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        fontSize: '1.25rem',
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: 'center'
    },
    swiperContainer: {
        marginBottom: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        '& > .swiper-container-horizontal > .swiper-pagination-bullet': {
            bottom: -20
        }
    }
}));

const WorkoutPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (value: any, newValue: number) => {
        history.push({
            search: `?workoutTab=${selectedTab}`
        });
        setSelectedTab(newValue);
    };

    return (
        <>
            <Paper className={classes.tabs}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    <Tab label="Guides" />
                    <Tab label="Live Workouts" />
                </Tabs>         
            </Paper>
            {selectedTab === 0 &&
                <WorkoutGuideList />
            }
            {selectedTab === 1 &&
                <LiveWorkoutSearch />
            }
        </>
        // <>
        //     <div className={classes.guideContainerHeader}>
        //         <AppPageTitle title='Workout Guides' icon='workout' />
        //         <Paper className={classes.tabs}>
        //             <Tabs
        //                 value={0}
        //                 indicatorColor="primary"
        //                 textColor="primary"
        //                 variant='scrollable'
        //                 scrollButtons='auto'
        //             >
        //                 <Tab label="All" />
        //                 <Tab label="Beginner" />
        //                 <Tab label="Intermediate" />
        //                 <Tab label="Advanced" />
        //             </Tabs>
        //         </Paper>
        //     </div>
        //     <Grid container spacing={3} className={classes.grid}>
        //         <Grid item xs={6} sm={3}>
        //             <div className={classes.guideItem}>
        //                 <div className={classes.guideItemImage}>
        //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
        //                 </div>
        //                 <div className={classes.guideItemTitle}>
        //                     Full Body Gym 1.0
        //                 </div>
        //             </div>
        //         </Grid>
        //         <Grid item xs={6} sm={3}>
        //             <div className={classes.guideItem}>
        //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //     </Grid>
    //     <Divider />
    //     <div className={classes.guideContainer}>
    //         <div className={classes.guideContainerHeaderCenter}>
    //             <h1><span>Live Workouts</span></h1>
    //         </div>
    //     </div>
    //     <Swiper grabCursor={true} centeredSlides={true} slidesPerView='auto' pagination={false} className={classes.swiperContainer} loop>
    //         <SwiperSlide className={classes.slideItem}>
    //             <div>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </SwiperSlide>
    //         <SwiperSlide className={classes.slideItem}>
    //             <div>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </SwiperSlide>
    //         <SwiperSlide className={classes.slideItem}>
    //             <div>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </SwiperSlide>
    //         <SwiperSlide className={classes.slideItem}>
    //             <div>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </SwiperSlide>
    //         <SwiperSlide className={classes.slideItem}>
    //             <div>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </SwiperSlide>
    //         <SwiperSlide className={classes.slideItem}>
    //             <div>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </SwiperSlide>
    //         <SwiperSlide className={classes.slideItem}>
    //             <div>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </SwiperSlide>
    //         <SwiperSlide className={classes.slideItem}>
    //             <div>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </SwiperSlide>
    //     </Swiper>
    //     <Divider />
    //     <div className={classes.guideContainer}>
    //         <div className={classes.guideContainerHeader}>
    //             <h1><span>Workout Categories</span></h1>
    //             <Paper className={classes.tabsCateogires}>
    //                 <Tabs
    //                     value={0}
    //                     indicatorColor="primary"
    //                     textColor="primary"
    //                     variant='scrollable'
    //                     scrollButtons='auto'
    //                 >
    //                     <Tab label="All" />
    //                     <Tab label="Abs" />
    //                     <Tab label="Glutes" />
    //                     <Tab label="Upper Body" />
    //                     <Tab label="Full Body" />
    //                     <Tab label="HIIT" />
    //                 </Tabs>
    //             </Paper>
    //         </div>
    //     </div>
    //     <Grid container spacing={3} className={classes.grid}>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
    //                     <img src='https://images.unsplash.com/photo-1594381898411-846e7d193883?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80' />
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //         <Grid item xs={6} sm={3}>
    //             <div className={classes.guideItem}>
    //                 <div className={classes.guideItemImage}>
                            
    //                 </div>
    //                 <div className={classes.guideItemTitle}>
    //                     Full Body Gym 1.0
    //                 </div>
    //             </div>
    //         </Grid>
    //     </Grid>
    // </>
    );
};

export default WorkoutPage;