import { configureStore } from '@reduxjs/toolkit';

import * as exercises from './exercises';
import * as workouts from './workouts';
import * as recipes from './recipes';

const store = configureStore({
    reducer: {
        [exercises.name]: exercises.reducer,
        [workouts.name]: workouts.reducer,
        [recipes.name]: recipes.reducer
    }
});

export const actions = {
    [exercises.name]: exercises.actions,
    [workouts.name]: workouts.actions,
    [recipes.name]: recipes.actions
};

export const selectors = {
    [exercises.name]: exercises.selectors,
    [workouts.name]: workouts.selectors,
    [recipes.name]: recipes.selectors
};

export default store;
export * from './utils/useDispatchEffect';