import { Button, Chip, Input, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent, useEffect, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../store';

interface ExerciseCategorySelectProps {
    name: string
}

const useStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    container: {
        display: 'block',
        width: '100%',
        margin: '1rem 0',
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius
    },
    select: {
        width: '100%'
    },
    newCatContainer: {
        display: 'flex',
        margin: '1rem 0'
    }
}));

const ExerciseCategorySelect: React.FC<ExerciseCategorySelectProps> = ({
    name
}) => {
    const [fieldProps, fieldMeta, { setValue }] = useField(name);
    const isError = fieldMeta.touched && !!fieldMeta.error;
    const helperText = fieldMeta.touched ? fieldMeta.error : undefined;
    const classes = useStyles();
    const categories = useSelector(selectors.exercises.getExerciseCategories());
    const [catInput, setCatInput] = useState('');
    const [newCategory, setNewCategory] = useState<string[]>([]);
    const [selected, setSelected] = useState(fieldProps.value);

    const handleChange = (e: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        const value = e.target.value;
        setSelected((value as string[]));
    };

    const addNewCategory = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if(catInput !== '') {
            setSelected([...selected, catInput]);
            setNewCategory([...newCategory, catInput]);
            setCatInput('');
        }
    };

    useEffect(() => {
        setValue(selected);
    }, [selected]);

    return (
        <div className={classes.container}>
            <InputLabel id="exercise-category-select">Category</InputLabel>
            <Select
                className={classes.select}
                labelId="exercise-category-select"
                id="exercise-category-select"
                multiple
                value={selected}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {(selected as string[]).map((value) => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                    </div>
                )}
            >
                {categories.concat(newCategory).map((category) => (
                    <MenuItem key={category} value={category}>
                        {category}
                    </MenuItem>
                ))}
            </Select>
            <div className={classes.newCatContainer}>
                <TextField 
                    placeholder='New category...'
                    value={catInput}
                    onChange={(e) => setCatInput(e.target.value)} />
                <Button onClick={addNewCategory}>Add +</Button>
            </div>
        </div>
    );
};

export default ExerciseCategorySelect;