import AppModal from '../../../components/AppModal';
import TextInputField from '../../../components/AppForm/TextInputField';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import { addNewGuide } from '../../../api/Workout';
import { Guide } from '../../../models/Workout';
import { useHistory } from 'react-router';
import { adminAppPaths } from '../../../AppRoutes';

interface CreateGuideModalProps {
    open: boolean,
    handleClose: () => void
}

const initialGuideFormValues: Guide = {
    name: '',
    blocks: [
        {
            blockId: uuidv4(),
            order: 0,
            workouts: [
                '',
                '',
                '',
                '',
                '',
                '',
                ''
            ]
        }
    ]
};

const CreateGuideModal: React.FC<CreateGuideModalProps> = ({
    open,
    handleClose
}) => {
    const history = useHistory();

    const submitGuide = async (guideData: Guide) => {
        const result = await addNewGuide(guideData);
        
        history.push(`${adminAppPaths.guideBuilder}/${result.data._id}`);
    };

    return (
        <>
            <AppModal open={open} handleClose={handleClose}>
                <h3>Create Guide</h3>
                <Formik
                    onSubmit={submitGuide}
                    initialValues={initialGuideFormValues}>
                    <Form>
                        <TextInputField
                            name='name'
                            label='Guide name'
                            autofocus />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                        >
                            Create
                        </Button>
                    </Form>
                </Formik>
            </AppModal>
        </>
    );
};

export default CreateGuideModal;