import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';

export interface AppCheckBoxProps {
    name: string,
    label: string
}

const AppCheckBox: React.FC<AppCheckBoxProps> = ({
    name,
    label
}) => {
    const [fieldProps] = useField(name);

    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...fieldProps}
                    name={name}
                    checked={fieldProps.value}
                    color="primary"
                />
            }
            label={label}
        />
    );
};

export default AppCheckBox;