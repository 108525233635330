import { createContext, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { workoutInitialValues } from '..';
import { InstructionType, SetProps, WorkoutNew } from '../../../models/Workout';
import { handleDragEnd } from './WorkoutEditorDnDHelpers';

export interface IWorkoutEditorContext {
    workoutData?: WorkoutNew,
    actions?: {
        handleDragNDrop: (result: DropResult) => void,
        setWorkoutData: (workoutData: WorkoutNew) => void,
        handleExerciseTypeUpdate: (stepId: string, stepExerciseId: string, type: InstructionType) => void,
        handleExerciseSetUpdate: (stepId: string, stepExerciseId: string, stepExerciseSetId: string, property: SetProps, value: any) => void
        handleAddSet: (estepId: string) => void,
        handleDeleteStep: (stepId: string) => void,
        handleDeleteSet: (stepId: string, stepExerciseId: string, stepExerciseSetId: string) => void,
        handleDeleteStepExercise: (stepId: string, stepExerciseId: string) => void
    }
}

export const WorkoutEditorContext = createContext<IWorkoutEditorContext>({});

const WorkoutEditorProvider: React.FC = ({ children }) => {
    const [workoutData, setWorkoutData] = useState(workoutInitialValues);

    const handleDragNDrop = (result: DropResult) => {
        setWorkoutData(handleDragEnd(workoutData, result));
    };

    const handleExerciseTypeUpdate = (stepId: string, stepExerciseId: string, type: InstructionType) => {
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));

        workoutDataClone.steps.map(step => {
            if(step.stepId === stepId) {
                step.exercises.map(exercise => {
                    if(exercise.stepExerciseId === stepExerciseId) {
                        exercise.type = type;
                    }
                });
            }
        });

        setWorkoutData(workoutDataClone);
    };

    const handleExerciseSetUpdate = (stepId: string, stepExerciseId: string, stepExerciseSetId: string, property: SetProps, value: any) => {
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));

        workoutDataClone.steps.map(step => {
            if(step.stepId === stepId) {
                step.exercises.map(exercise => {
                    if(exercise.stepExerciseId === stepExerciseId) {
                        exercise.sets.map(set => {
                            if(set.stepExerciseSetId === stepExerciseSetId) {
                                console.log('here');
                                set[property] = value;
                            }
                        });
                    }
                });
            }
        });

        setWorkoutData(workoutDataClone);
    };
    
    const handleAddSet = (stepId: string) => {
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));

        workoutDataClone.steps.map(step => {
            if(step.stepId === stepId) {
                step.exercises.map(exercise => {
                    console.log('here');
                    const setClone = JSON.parse(JSON.stringify(exercise.sets[exercise.sets.length - 1]));
                    exercise.sets.push({
                        ...setClone,
                        stepExerciseSetId: uuidv4()
                    });
                });
            }
        });

        setWorkoutData(workoutDataClone);
    };

    const handleDeleteStep = (stepId: string) => {
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));

        workoutDataClone.steps = workoutDataClone.steps.filter(step => step.stepId !== stepId);

        setWorkoutData(workoutDataClone);
    };

    const handleDeleteStepExercise = (stepId: string, stepExerciseId: string) => {
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));

        workoutDataClone.steps.map(step => {
            if(step.stepId === stepId) {
                step.exercises = step.exercises.filter(exercise => exercise.stepExerciseId !== stepExerciseId);
            }
        });

        setWorkoutData(workoutDataClone);
    };

    const handleDeleteSet = (stepId: string, stepExerciseId: string, stepExerciseSetId: string) => {
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));

        workoutDataClone.steps.map(step => {
            if(step.stepId === stepId) {
                step.exercises.map(exercise => {
                    if(exercise.stepExerciseId === stepExerciseId) {
                        exercise.sets = exercise.sets.filter(set => set.stepExerciseSetId !== stepExerciseSetId);
                    }
                });
            }
        });

        setWorkoutData(workoutDataClone);
    };

    return (
        <WorkoutEditorContext.Provider
            value={{
                workoutData,
                actions: {
                    handleDragNDrop,
                    setWorkoutData,
                    handleExerciseTypeUpdate,
                    handleExerciseSetUpdate,
                    handleAddSet,
                    handleDeleteStep,
                    handleDeleteSet,
                    handleDeleteStepExercise
                }
            }}>
            {children}
        </WorkoutEditorContext.Provider>
    );
};

export default WorkoutEditorProvider;