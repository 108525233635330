import { createContext, useContext } from 'react';
import { GuideSchedule, ScheduleItem } from '../../models/Schedule';
import { Guide } from '../../models/Workout';

export interface IScheduleContext {
    guideSchedule?: GuideSchedule,
    scheduleItems?: ScheduleItem[],
    guideData?: Guide,
    currentWeek?: number,
    todaysScheduleItem: () => ScheduleItem | undefined,
    fetchScheduleData: () => void,
    scheduleByDate: (date: Date) => ScheduleItem[]
}

const placeholderFunc = () => { throw new Error('Not implemented.'); };

export const ScheduleContext = createContext<IScheduleContext>({
    todaysScheduleItem: placeholderFunc,
    fetchScheduleData: placeholderFunc,
    scheduleByDate: placeholderFunc
});

export const useSchedule: () => IScheduleContext = () => useContext(ScheduleContext);