import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOldExercises, getOldWorkoutDetail } from '../../api/Workout';
import AppPageTitle from '../../components/AppPageTitle';
import { Exercise, OldWorkout } from '../../models/Workout';

interface Params {
    slug: string
}

interface DayProps {
    day: any,
    no: number,
    exercises: Exercise[]
}

interface ExerciseProps {
    exercise: any,
    exercises: Exercise[]
}

const WorkoutExercise: React.FC<ExerciseProps> = ({
    exercise,
    exercises
}) => {
    const details = exercises.find(item => item._id === exercise.eid);
    
    return (
        <>
            {details &&
                <div style={{border: exercise.superset && '1px solid black'}}>
                    {exercise.superset && <b>Superset</b>}
                    <p><b>{details.name} ({details.video})</b> | {exercise.sets.length} sets | {exercise.sets[0].type === 'reps' && `${exercise.sets[0].reps.target} reps`} {exercise.sets[0].type === 'liss' && `${parseFloat(exercise.sets[0].liss.duration) * 60}s`} {exercise.sets[0].type === 'hiit' && `HIIT ${exercise.sets[0].hiit.on}s ON ${exercise.sets[0].hiit.off}s OFF for ${exercise.sets[0].hiit.duration}mins`}</p>
                    {exercise.superset && exercise.supersetExercises.map((item: any) => {
                        const itemDetail = exercises.find(exerciseItem => exerciseItem._id === item.eid);

                        return <p key={item.id}><b>{itemDetail && itemDetail.name} ({itemDetail?.video})</b> | {item.sets.length} sets | {item.sets[0].type === 'reps' && `${item.sets[0].reps.target} reps`} {item.sets[0].type === 'liss' && `${parseFloat(item.sets[0].liss.duration) * 60}s`} {item.sets[0].type === 'hiit' && `HIIT ${item.sets[0].hiit.on}s ON ${item.sets[0].hiit.off}s OFF for ${item.sets[0].hiit.duration}mins`}</p>;
                    })}
                </div>
            }
        </>
    );
};

const WorkoutDay: React.FC<DayProps> = ({day, no, exercises}) => {
    return (
        <>
            <h5>Day {no} - {day.name}</h5>
            {day.exercises.map((exercise: any) =>
                <WorkoutExercise exercise={exercise} key={exercise.id} exercises={exercises} />
            )}
            <hr />
        </>
    );
};

const OldWorkoutDetailPage: React.FC = () => {
    const { slug } = useParams<Params>();
    const [workout, setWorkout] = useState<OldWorkout>();
    const [exercises, setExercises] = useState<Exercise[]>();

    const fetchWorkoutDetail = async () => {
        if(slug) {
            const exercises = await getOldExercises();
            const result = await getOldWorkoutDetail(slug);

            if(result.status === 200) {
                setWorkout(result.data);
            }

            if(exercises.status === 200) {
                setExercises(exercises.data);
            }

        }
    };

    useEffect(() => {
        fetchWorkoutDetail();
    }, []);

    return (
        <>
            {exercises && workout &&
                <>
                    <AppPageTitle title={workout.title} />
                    {workout.data && workout.data.map((week, i) =>
                        <div key={week._id}>
                            <h2>Week {i + 1}</h2>
                            {week.days.map((day: any, index: number) =>
                                <WorkoutDay key={index} day={day} exercises={exercises} no={index + 1} />
                            )}
                        </div>    
                    )}
                </>
            }
        </>
    );
};

export default OldWorkoutDetailPage;