import { makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useEffect, useState } from 'react';
import AppPageTitle from '../components/AppPageTitle';
import { DiaryCategory } from '../models/Nutrition';
import { useNutrition } from '../nutrition/context/NutritionContext';
import DiaryAdditionModal from '../nutrition/DiaryAdditionModal/DiaryAdditionModal';
import DiaryCustomMealModal from '../nutrition/DiaryCustomMealModal';
import NutritionCard from '../nutrition/NutritionCard';
import NutritionDiaryCategory from '../nutrition/NutritionDiaryCategory';
import TargetCalculator from '../nutrition/TargetCalculator';

const useStyles = makeStyles(theme => ({
    datepicker: {
        '& input': {
            backgroundColor: theme.palette.grey[200],
            textAlign: 'center',
            borderRadius: theme.shape.borderRadius,
            width: 140,
            padding: '10px 0',
            fontWeight: theme.typography.fontWeightMedium
        },
        '& .MuiInput-underline:after': {
            display: 'none !important'
        },
        '& .MuiInput-underline:before': {
            display: 'none !important'
        }
    }
}));

const NutritionPage: React.FC = () => {
    const classes = useStyles();
    const { date, setDate, target, fetchTarget } = useNutrition();
    const [category, setCategory] = useState<DiaryCategory>();
    const [recipeModal, setRecipeModal] = useState(false);
    const [customMealModal, setCustomMealModal] = useState(false);

    const handleAddModal = (category: DiaryCategory) => {
        setCategory(category);
        setRecipeModal(true);
    };

    const handleCustomAddModal = () => {
        setRecipeModal(false);
        setCustomMealModal(true);
    };

    useEffect(() => {
        setDate(new Date());
        fetchTarget();
    }, []);

    return (
        <>
            {target ?
                <>
                    <AppPageTitle title='Nutrition' icon='nutrition'>
                        <DatePicker 
                            className={classes.datepicker}
                            autoOk
                            showTodayButton
                            variant='dialog'
                            value={date}
                            onChange={(date) => setDate(date as Date)} />
                    </AppPageTitle>
                    <NutritionCard />
                    <NutritionDiaryCategory category='Breakfast' handleAdd={() => handleAddModal('Breakfast')} />
                    <NutritionDiaryCategory category='Lunch' handleAdd={() => handleAddModal('Lunch')} />
                    <NutritionDiaryCategory category='Dinner' handleAdd={() => handleAddModal('Dinner')} />
                    <NutritionDiaryCategory category='Snacks' handleAdd={() => handleAddModal('Snacks')} />
                    <DiaryAdditionModal open={recipeModal} handleClose={() => setRecipeModal(false)} category={category} handleAddCustom={handleCustomAddModal} />
                    <DiaryCustomMealModal open={customMealModal} handleClose={() => setCustomMealModal(false)} category={category} />
                </>
                :
                <TargetCalculator />
            }
        </>
    );
};

export default NutritionPage;