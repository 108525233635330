import { adminAppPaths } from '../../AppRoutes';
import AppButton from '../../components/AppButton';
import AppModal from '../../components/AppModal';

interface GuideEditorWorkoutModalProps {
    open: boolean,
    blockId: string,
    guideId: string,
    workoutIndex: number,
    onClose: () => void
}

const GuideEditorWorkoutModal: React.FC<GuideEditorWorkoutModalProps> = ({
    open,
    blockId,
    guideId,
    workoutIndex,
    onClose
}) => {
    return (
        <AppModal open={open} handleClose={onClose}>
            <h3>Select a template</h3>
            <hr />
            <AppButton linkPath={`${adminAppPaths.workoutBuilder}?guideId=${guideId}&blockId=${blockId}&workoutIndex=${workoutIndex}`}>Create new workout</AppButton>
        </AppModal>
    );
};

export default GuideEditorWorkoutModal;