import * as Yup from 'yup';

export { default as ExerciseForm } from './ExerciseForm';

export interface ExerciseFormValues {
    isolateral?: boolean,
    cardioOnly: boolean,
    category?: string[],
    description?: string,
    image?: string,
    name: string,
    video: string,
    _id?: string
}

export const ExerciseFormValidationSchema: Yup.SchemaOf<ExerciseFormValues> = Yup.object().shape({
    name: Yup.string().required('Please provide an exercise name'),
    video: Yup.string().required('Please provide a video URL'),
    isolateral: Yup.bool().notRequired(),
    cardioOnly: Yup.bool().required('Please provide a cardio only value'),
    category: Yup.array().of(Yup.string()).notRequired(),
    description: Yup.string().notRequired(),
    image: Yup.string().notRequired(),
    _id: Yup.string().notRequired()
});

export const ExerciseFormInitialValues: ExerciseFormValues = {
    name: '',
    description: '',
    category: [],
    image: '',
    video: '',
    isolateral: false,
    cardioOnly: false
};