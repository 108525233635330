import { Button, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpRequest } from '../api';
import { getLatestLiveWorkout } from '../api/Workout';
import { appPaths } from '../AppRoutes';
import config from '../config';

const useStyles = makeStyles((theme) => ({
    latest: {
        display: 'flex',
        width: 'calc(100% + 2rem)',
        transform: 'translateX(-1rem)',
        backgroundColor: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
        padding: '1.5rem 1rem'
    },
    latestImage: {
        flex: 1,
        '& > div': {
            width: 140,
            height: 140,
            '& > img': {
                position: 'relative',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
            }
        }
    },
    latestContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '& h2': {
            margin: 0,
            textTransform: 'uppercase',
            fontSize: '1rem'
        }
    },
    latestAction: {
        marginTop: 'auto',
        backgroundColor: theme.palette.common.white
    }
}));

const LatestWorkoutBanner: React.FC = () => {
    const classes = useStyles();
    const getWorkout = useCallback(() => getLatestLiveWorkout(), []);
    const { result: workout, isLoading } = useHttpRequest(getWorkout);

    const history = useHistory();

    const handleRedirect = () => {
        if(workout) {
            history.push(`${appPaths.liveWorkout}/${workout._id}`);
        }
    };

    return (
        <>
            <div className={classes.latest}>
                <div className={classes.latestImage}>
                    <div>
                        <img src={`${config.apiBaseUrl}/media/${workout?.image}`} alt={workout?.title} />
                    </div>
                </div>
                <div className={classes.latestContent}>
                    <h2>Latest live workout</h2>
                    <p><b>{workout?.title}</b></p>
                    <Button className={classes.latestAction} color='primary' onClick={handleRedirect}>View workout</Button>
                </div>
            </div>
        </>
    );
};

export default LatestWorkoutBanner;