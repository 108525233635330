import AppPageTitle from '../components/AppPageTitle';
import ProgressPhotos from '../Progress/ProgressPhotos/ProgressPhotos';

const ProgressPage: React.FC = () => {
    return (
        <>
            <AppPageTitle title='My Progress' icon='progress' />
            <ProgressPhotos />
        </>
    );
};

export default ProgressPage;