import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { WorkoutStep } from '../../models/Workout';
import { selectors } from '../../store';
import ExerciseInstructions from './elements/ExerciseInstructions';
import WorkoutVideo from './elements/WorkoutVideo';
import AppButton from '../../components/AppButton';
import 'swiper/swiper.min.css';
import { useWorkoutWalkthrough } from './Context/WorkoutWalkthroughContext';
import { useEffect } from 'react';

interface WorkoutWalkthroughItemProps {
    stepNo: number,
    step: WorkoutStep
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 10001
    },
    slideMain: {
        position: 'relative',
        width: '100vw',
        // height: '100vh',
        overflowY: 'scroll',
        borderBottom: '3px solid white',
        backgroundColor: theme.palette.common.white
    },
    videoContainer: {
        position: 'relative',
        width: '100%',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent'
        }
    },
    exerciseInstructions: {
        position: 'relative',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        width: '100vw',
        overflow: 'hidden'
    },
    navigation: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}));

const WorkoutWalkthroughItem: React.FC<WorkoutWalkthroughItemProps> = ({
    stepNo,
    step
}) => {
    const classes = useStyles();
    const { currentStep, setCurrentStep } = useWorkoutWalkthrough();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);
    
    return (
        <>
            {currentStep === stepNo &&
                <div className={classes.slideMain}>
                    <div className={classes.videoContainer}>
                        <WorkoutVideo exercises={step.exercises.map(exercise => exercise.exerciseId)} stepNo={stepNo} />
                    </div>
                    <div className={classes.exerciseInstructions}>
                        <ExerciseInstructions workoutStep={step}stepNo={stepNo} />
                    </div>
                    <div className={classes.navigation}>
                        <AppButton color='default' onClick={() => setCurrentStep(currentStep - 1)} disabled={currentStep === 0}>Prev</AppButton>
                        <AppButton color='secondary' onClick={() => setCurrentStep(currentStep + 1)}>Next</AppButton>
                    </div>
                </div>
            }
        </>
    );
};

export default WorkoutWalkthroughItem;