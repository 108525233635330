import { useContext } from 'react';
import { makeStyles, MenuItem, Select, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { InstructionType, WorkoutStepInstruction } from '../../models/Workout';
import WorkoutEditorStepExerciseSet from './WorkoutEditorStepExerciseSet';
import { selectors } from '../../store';
import { WorkoutEditorContext } from './WorkoutEditorContext/WorkoutEditorContext';
import config from '../../config';

interface WorkoutEditorStepExerciseProps {
    exercise: WorkoutStepInstruction,
    stepId: string,
    supersetIdentifier?: string
}

const useStyles = makeStyles((theme) => ({
    item: {
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    itemHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    },
    itemImage: {
        position: 'relative',
        flex: 'none',
        marginRight: theme.spacing(2),
        width: 40,
        height: 40,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[300],
        overflow: 'hidden',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        }
    },
    itemName: {
        fontWeight: theme.typography.fontWeightMedium
    },
    itemAction: {
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
        '& > label': {
            marginRight: theme.spacing(1)
        }
    }
}));

const WorkoutEditorStepExercise: React.FC<WorkoutEditorStepExerciseProps> = ({
    exercise,
    stepId,
    supersetIdentifier
}) => {
    const classes = useStyles();
    const exerciseDetails = useSelector(selectors.exercises.getById(exercise.exerciseId));
    const { actions } = useContext(WorkoutEditorContext);

    const handleStepExerciseDelete = () => {
        actions && actions.handleDeleteStepExercise(stepId, exercise.stepExerciseId);
    };

    return (
        <div className={classes.item}>
            <div className={classes.itemHeader}>
                <div className={classes.itemImage}>
                    {exerciseDetails?.image && <img src={`${config.apiBaseUrl}/media/content/${exerciseDetails.image}`} alt={exerciseDetails.name} />}
                </div>
                <div className={classes.itemName}>{exerciseDetails?.name}</div>
                <div className={classes.itemAction}>
                    <label>Type: </label>
                    <Select value={exercise.type} onChange={(e) => actions && actions.handleExerciseTypeUpdate(stepId, exercise.stepExerciseId, e.target.value as InstructionType)}>
                        <MenuItem value='REPS'>Reps</MenuItem>
                        <MenuItem value='HIIT'>HIIT</MenuItem>
                        <MenuItem value='LISS'>LISS</MenuItem>
                    </Select>
                    <IconButton onClick={handleStepExerciseDelete} size='small'>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div>
                {exercise.sets.map((set, i) =>
                    <WorkoutEditorStepExerciseSet 
                        key={i}
                        stepId={stepId}
                        stepExerciseId={exercise.stepExerciseId}
                        set={set}
                        supersetIdentifier={supersetIdentifier}
                        type={exercise.type} 
                        index={i + 1} />
                )}
            </div>
        </div>
    );
};

export default WorkoutEditorStepExercise;