import { useCallback } from 'react';
import { useHttpRequest } from '../api';
import { getWorkoutById } from '../api/Workout';
import SolidLoader from '../components/Loading/SolidLoader';
import { WorkoutWalkthrough } from '../workout/WorkoutWalkthrough';
import { useWorkoutWalkthrough } from '../workout/WorkoutWalkthrough/Context/WorkoutWalkthroughContext';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';

interface PageParams {
    id: string
}

const WorkoutWalkthroughPage: React.FC = () => {
    const { id } = useParams<PageParams>();
    const { gsid } = queryString.parse(location.search);
    const { workout, setWorkout, setGuideScheduleId, resetWorkoutWalkthrough } = useWorkoutWalkthrough();
    const getWorkout = useCallback(() => getWorkoutById(id), []);
    const { result, isLoading } = useHttpRequest(getWorkout);

    if(workout && workout._id !== id) {
        resetWorkoutWalkthrough();
    }

    result && !workout && setWorkout(result);

    if(gsid) {
        setGuideScheduleId(gsid as string);
    }

    return (
        <>
            {isLoading &&
                <SolidLoader />
            }
            {result && !isLoading &&
                <WorkoutWalkthrough />
            }
        </>
    );
};

export default WorkoutWalkthroughPage;