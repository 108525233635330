import moment from 'moment';
import { useEffect } from 'react';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHttpRequest } from '../../api';
import { getMySchedule } from '../../api/Schedule';
import { useAuth } from '../../authentication/authContext/authContext';
import { ScheduleItem } from '../../models/Schedule';
import { ScheduleContext } from './scheduleContext';

const ScheduleProvider: React.FC = ({
    children
}) => {
    const auth = useAuth();
    const [key, setKey] = useState(uuidv4());
    const [currentWeek, setCurrentWeek] = useState<number>();
    const getSchedule = useCallback(() => getMySchedule(), [key, auth.state?.isLoggedIn]);
    const { result: scheduleData, isLoading } = useHttpRequest(getSchedule);

    const refreshKey = () => setKey(uuidv4());

    const scheduleByDate = (date: Date): ScheduleItem[] => {
        if(scheduleData && scheduleData.guideData && scheduleData.guideSchedule && scheduleData.scheduleItems && scheduleData.guideData.blocks) {
            const startDate = scheduleData.guideSchedule.startDate;
            const diff = moment(date).startOf('day').diff(moment(startDate).startOf('day'), 'weeks');

            const items: ScheduleItem[] = [];

            for(let i = (diff * 7); i < ((diff * 7) + 7); i++) {
                items.push(scheduleData.scheduleItems[i]);
            }

            return items;
        }

        return [];
    };

    const todaysScheduleItem = (): ScheduleItem | undefined => {
        if(scheduleData && scheduleData.scheduleItems) {
            const scheduleItem = scheduleData.scheduleItems.find(item => {
                return moment(item.date).startOf('day').diff(moment(new Date()).startOf('day'), 'days') === 0;
            });

            return scheduleItem;
        }
    };

    useEffect(() => {
        if(scheduleData?.guideSchedule) {
            const startDate = scheduleData.guideSchedule.startDate;

            const diff = moment(new Date()).startOf('day').diff(moment(startDate).startOf('day'), 'weeks');

            setCurrentWeek(diff + 1);
        }
    }, [scheduleData]);

    return (
        <ScheduleContext.Provider value={{
            guideSchedule: scheduleData?.guideSchedule,
            scheduleItems: scheduleData?.scheduleItems,
            guideData: scheduleData?.guideData,
            currentWeek,
            todaysScheduleItem,
            fetchScheduleData: refreshKey,
            scheduleByDate
        }}>
            {children}
        </ScheduleContext.Provider>
    );
};

export default ScheduleProvider;