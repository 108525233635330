import { makeStyles } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHttpRequest } from '../../api';
import { getLiveWorkouts } from '../../api/Workout';
import { appPaths } from '../../AppRoutes';
import AppLoader from '../../components/AppLoader';
import config from '../../config';
import { PlayCircleOutline as PlayIcon } from '@material-ui/icons';
import LatestWorkoutBanner from '../LatestWorkoutBanner';

const useStyles = makeStyles(theme => ({
    select: {
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(4)
    },
    gridList: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 16
    },
    item: {
        position: 'relative',
        textDecoration: 'none',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        '& svg': {
            position: 'absolute',
            width: 40,
            height: 40,
            top: 'calc(50% - 20px)',
            left: 'calc(50% - 20px)',
            fill: theme.palette.common.white
        }
    },
    image: {
        position: 'relative',
        width: '100%',
        height: 162,
        borderRadius: theme.shape.borderRadius,
        // overflow: 'hidden',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: theme.shape.borderRadius,
            position: 'relative'
        }
    },
    name: {
        fontSize: 16,
        textAlign: 'center',
        marginTop: 8,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        paddingBottom: theme.spacing(2),
    },
    catList: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: '.5rem'
    },
    catItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '.5rem 0 0 0',
        background: 'transparent',
        border: 'none',
        flex: 1
    },
    catItemActive: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '.5rem 0 0 0',
        background: 'transparent',
        flex: 1,
        border: `2px solid ${theme.palette.secondary.main}`
    },
    catItemImage: {
        position: 'relative',
        width: 50,
        height: 50,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        '& > img': {
            position: 'relative',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
        }
    },
    catItemText: {
        padding: '.5rem 0',
        textTransform: 'uppercase',
        fontSize: '.65rem',
        fontWeight: 'bold'
    }
}));

const LiveWorkoutSearch: React.FC = () => {
    const classes = useStyles();
    const [category, setCategory] = useState('Legs');
    const getWorkouts = useCallback(() => getLiveWorkouts(category), [category]);
    const { result: liveWorkouts, isLoading } = useHttpRequest(getWorkouts);
    
    return (
        <>
            <LatestWorkoutBanner />
            <div className={classes.catList}>
                <button className={category === 'Legs' ? classes.catItemActive : classes.catItem} onClick={() => setCategory('Legs')}>
                    <div className={classes.catItemImage}>
                        <img src="/assets/workout-categories/legs.jpg" />
                    </div>
                    <div className={classes.catItemText}>
                        Lower Body
                    </div>
                </button>
                <button className={category === 'Upper%20Body' ? classes.catItemActive : classes.catItem} onClick={() => setCategory('Upper%20Body')}>
                    <div className={classes.catItemImage}>
                        <img src="/assets/workout-categories/upper-body.jpg" />
                    </div>
                    <div className={classes.catItemText}>
                        Upper Body
                    </div>
                </button>
                <button className={category === 'Full%20Body' ? classes.catItemActive : classes.catItem} onClick={() => setCategory('Full%20Body')}>
                    <div className={classes.catItemImage}>
                        <img src="/assets/workout-categories/full-body.jpeg" />
                    </div>
                    <div className={classes.catItemText}>
                        Full Body
                    </div>
                </button>
                <button className={category === 'Abs' ? classes.catItemActive : classes.catItem} onClick={() => setCategory('Abs')}>
                    <div className={classes.catItemImage}>
                        <img src="/assets/workout-categories/abs.jpg" />
                    </div>
                    <div className={classes.catItemText}>
                        Abs
                    </div>
                </button>
                <button className={category === 'Warm%20Ups%20and%20Cool%20Downs' ? classes.catItemActive : classes.catItem} onClick={() => setCategory('Warm%20Ups%20and%20Cool%20Downs')}>
                    <div className={classes.catItemImage}>
                        <img src="/assets/workout-categories/warm-ups.jpg" />
                    </div>
                    <div className={classes.catItemText}>
                        Warm ups / Cool downs
                    </div>
                </button>
            </div>
            {isLoading && <AppLoader />}
            {liveWorkouts && !isLoading &&
                <div className={classes.gridList}>
                    {liveWorkouts.filter(workout => workout.type === 'video').map(workout => 
                        <Link key={workout._id} to={`${appPaths.liveWorkout}/${workout._id}`} className={classes.item}>
                            <div className={classes.image}>
                                {workout.image && <img src={`${config.apiBaseUrl}/media/${workout.image}`} alt={workout.title} />}
                            </div>
                            <div className={classes.name}>
                                {workout.title}
                            </div>
                            <PlayIcon />
                        </Link>
                    )}
                </div>
            }
        </>
    );
};

export default LiveWorkoutSearch;