import { LoginFormValues } from '.';
import { loginUser } from '../../api/Authentication';
import { useAuth } from '../authContext/authContext';
import LoginForm from './LoginForm';

const LoginFormContainer: React.FC = () => {
    const auth = useAuth();

    const handleLogin = async (loginFormValues: LoginFormValues) => {
        const response = await loginUser(loginFormValues);
        
        if(response.status === 200) {
            sessionStorage.setItem('isLoggedIn', 'true');
            sessionStorage.setItem('user', JSON.stringify(response.data.data.user));
            auth.actions?.setUserDetails(response.data.data.user);
            auth.actions?.setLoggedInState(true);
        }

        auth.actions?.setLoggedInState(true);
    };

    return (
        <>
            <LoginForm handleSubmit={handleLogin} />
        </>
    );
};

export default LoginFormContainer;