import { InputLabel, makeStyles } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadContentMedia } from '../../api/Media';
import config from '../../config';
import LocalLoader from '../Loading/LocalLoader';

export interface FileUploadProps {
    name: string,
    label: string,
    value?: string,
    onChange: (name: string, value: string) => void
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '1rem 0'
    },
    uploadContainer: {
        position: 'relative',
        border: `1px solid ${theme.palette.grey[400]}`,
        marginTop: '1rem',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        cursor: 'pointer',
        overflow: 'hidden',
        '&:hover': {
            borderColor: theme.palette.grey[700],
            backgroundColor: theme.palette.grey[200]
        }
    }
}));

const FileUpload: React.FC<FileUploadProps> = ({
    name,
    label,
    value,
    onChange
}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(value);

    const onDrop = useCallback(async acceptedFiles => {
        setLoading(true);

        const formData = new FormData();

        formData.append('images', acceptedFiles[0]);

        const result = await uploadContentMedia(formData);

        if(result.status === 200) {
            setImagePreview(result.data[0].thumbnail);
            onChange(name, result.data[0].image);
            setLoading(false);
        }

    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
      
    return (
        <div className={classes.container}>
            <InputLabel>{label}</InputLabel>
            <div className={classes.uploadContainer} {...getRootProps()}>
                <input {...getInputProps()} accept="image/*" multiple={false} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag and drop some files here, or click to select files</p>
                }
                {imagePreview && imagePreview !== '' && <img src={`${config.apiBaseUrl}/media/content/${imagePreview}`} alt='Exercise image upload preview' />}
                {loading && <LocalLoader />}
            </div>
        </div>
    );
};

export default FileUpload;