import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { PlayCircleOutline as PlayIcon, Replay as RestartIcon, CheckCircle as CheckIcon } from '@material-ui/icons';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { InstructionType, SetData, WorkoutStep, WorkoutStepInstruction } from '../../../models/Workout';
import { selectors } from '../../../store';
import { alphabet } from '../../WorkoutEditor';
import { useWorkoutWalkthrough } from '../Context/WorkoutWalkthroughContext';

const useStyles = makeStyles((theme) => ({
    exerciseNo: {
        display: 'inline-block',
        fontSize: '.85rem',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius
    },
    setContainer: {
        padidng: theme.spacing(1)
    },
    setBox: {
        boxShadow: theme.shadows[11],
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2)
    },
    setTarget: {
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    setTargetItems: {
        display: 'flex',
        '& span': {
            flex: 1,
            margin: '0 .5rem',
            '&:first-of-type': {
                marginLeft: 0
            },
            '&:last-of-type': {
                marginRight: 0
            }
        }
    },
    supersetContainer: {
        overflow: 'visible',
        paddingRight: theme.spacing(1)
    },
    supersetSlide: {
        marginRight: theme.spacing(2)
    },
    setTimerContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    countdownContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    countdownDone: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        overflow: 'hidden',
        '& svg': {
            width: 120,
            height: 120,
            fill: theme.palette.secondary.main,
            backgroundColor: theme.palette.common.white,
            borderRadius: '50%'
        }
    },
    countDownBtn: {
        width: 100,
        height: 100,
        '& svg': {
            width: '2.5em',
            height: '2.5em'
        }
    },
    'timerWrapper': {
        display: 'flex',
        justifyContent: 'center',
    },
      
    'timeWrapper': {
        position: 'relative',
        width: '80px',
        height: '60px',
        fontSize: '48px',
    },
    'timeWrapperTime': {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'translateY(0)',
        opacity: 1,
        transition: 'all 0.2s',
    },
    'TimeWrapperTimeUp': {
        opacity: 0,
        transform: 'translateY(-100%)',
    },
    'TimeWrapperTimeDown': {
        opacity: 0,
        transform: 'translateY(100%)',
    }
}));

interface ExerciseInstructionsProps {
    workoutStep: WorkoutStep,
    stepNo: number
}

interface SuperSetExerciseNames {
    exercise: WorkoutStepInstruction,
    letter?: string
}

interface ExerciseSet {
    set: SetData,
    type: InstructionType,
    setNo: number,
    letter?: string
}

const prettifyMinutes = (time: number, includeSeconds?: boolean): string => {
    if(time < 1) return `${(60 * time)}${includeSeconds ? 's' : ''}`;

    const seconds = time * 60;
    const remainder = seconds % 60;
    const minutes = (seconds - remainder) / 60;

    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

const ExerciseName: React.FC<SuperSetExerciseNames> = ({
    exercise,
    letter
}) => {
    const exerciseData = useSelector(selectors.exercises.getById(exercise.exerciseId));

    return (
        <h3>{letter && `${letter.toUpperCase()}. `}{exerciseData?.name}</h3>
    );
};

interface ExerciseTimerProps {
    seconds: number
}

const renderTime = ({ remainingTime }: any) => {
    const classes = useStyles();
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);
  
    if (currentTime.current !== remainingTime) {
        isNewTimeFirstTick.current = true;
        prevTime.current = currentTime.current;
        currentTime.current = remainingTime;
    } else {
        isNewTimeFirstTick.current = false;
    }
  
    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
        setTimeout(() => {
            setOneLastRerender(val => val + 1);
        }, 20);
    }
  
    const isTimeUp = isNewTimeFirstTick.current;
  
    return (
        <div className={classes.timeWrapper}>
            <div key={remainingTime} className={`${classes.timeWrapperTime} ${isTimeUp ? classes.TimeWrapperTimeUp : ''}`}>
                {remainingTime}
            </div>
            {prevTime.current !== null && (
                <div
                    key={prevTime.current}
                    className={`${classes.timeWrapperTime} ${!isTimeUp ? classes.TimeWrapperTimeDown : ''}`}
                >
                    {prevTime.current}
                </div>
            )}
        </div>
    );
};

const ExerciseTimer: React.FC<ExerciseTimerProps> = ({
    seconds
}) => {
    const classes = useStyles();
    const [time, setTime] = useState(seconds);
    const [active, setActive] = useState(false);
    const [complete, setComplete] = useState(false);
    const [key, setKey] = useState(uuidv4());

    const reset = () => {
        setTime(seconds);
        setActive(true);
        setComplete(false);
        setKey(uuidv4());
    };

    const completeTimer = () => {
        setComplete(true);
    };

    return (
        <>
            <div className={classes.countdownContainer}>
                <CountdownCircleTimer
                    key={key}
                    isPlaying={active}
                    onComplete={() => completeTimer()}
                    duration={time}
                    colors={[['#004777', 0.33], ['#F7B801', 0.33], ['#A30000', .2]]}>
                    {renderTime}
                </CountdownCircleTimer>
                <div className={classes.countdownDone}>{complete && <CheckIcon />}</div>
            </div>
            {!active && 
            <IconButton className={classes.countDownBtn} onClick={() => setActive(true)}>
                <PlayIcon />
            </IconButton>}
            {active && 
                <IconButton className={classes.countDownBtn} onClick={reset}>
                    <RestartIcon />
                </IconButton>}
        </>
    );
};

const ExerciseSetBox: React.FC<ExerciseSet> = ({
    set,
    type,
    setNo,
    letter
}) => {
    const classes = useStyles();
    const { getLogById, updateSetLog } = useWorkoutWalkthrough();

    const logData = getLogById(set.stepExerciseSetId);

    return (
        <div className={classes.setBox}>
            <h3>Set {setNo}{letter && letter.toUpperCase()}</h3>
            {type === 'REPS' &&
            <div className={classes.setTarget}>
                <h4>Target</h4>
                <div className={classes.setTargetItems}>
                    
                    <>
                        <span>{set.reps && `${set.reps} Reps`}</span>
                        <span>{set.weight && `${set.weight} KG`}</span>
                    </>
                    
                </div>
            </div>}
            {type === 'REPS' &&
            <div className={classes.setTarget}>
                <h4>Achieved</h4>
                <div className={classes.setTargetItems}>
                    <>
                        <span>
                            <TextField 
                                type='number' 
                                variant='outlined' 
                                focused 
                                label='Reps' 
                                name='reps'
                                value={logData?.reps}
                                onChange={(e) => updateSetLog(set.stepExerciseSetId, 'reps', parseInt(e.target.value))} />
                        </span>
                        <span>
                            <TextField 
                                type='number' 
                                variant='outlined'
                                focused 
                                label='Weight'
                                name='weight'
                                value={logData?.weight}
                                onChange={(e) => updateSetLog(set.stepExerciseSetId, 'weight', parseInt(e.target.value))}
                                InputProps={{
                                    endAdornment: <InputAdornment position='end'>KG</InputAdornment>
                                }} />
                        </span>
                    </>
                </div>
            </div>}
            {type === 'LISS' &&
                <div className={classes.setTimerContainer}>
                    <ExerciseTimer seconds={set.duration ? set.duration * 60 : 0} />
                </div>
            }
        </div>
    );
};

interface SuperSetBoxProps {
    exercises: WorkoutStepInstruction[],
}

const ExerciseSuperSetBox: React.FC<SuperSetBoxProps> = ({
    exercises,
}) => {
    const classes = useStyles();
    let highestSetCount = 0;

    for(let i = 0; i < exercises.length; i++) {
        if(exercises[i].sets.length > highestSetCount) {
            highestSetCount = exercises[i].sets.length;
        }
    }

    const exerciseSets = () => {
        const items = [];

        for(let i = 0; i < highestSetCount; i++) {
            const slideItems = [];
            for(let j = 0; j < exercises.length; j++) {
                if(exercises[j].sets[i]) {
                    const set = exercises[j].sets[i];
                    const type = exercises[j].type;
                    const letter = alphabet[j];
    
                    slideItems.push(<SwiperSlide className={classes.supersetSlide} tabIndex={1}><ExerciseSetBox set={set} type={type} setNo={i + 1} letter={letter} /></SwiperSlide>);
                }
            }

            items.push(<Swiper className={classes.supersetContainer} grabCursor={true} slidePrevClass='1' pagination={false}>{slideItems}</Swiper>);
        }

        return items;
    };
    

    return (
        <>
            {exerciseSets()}
        </>
    );
};

const ExerciseInstructions: React.FC<ExerciseInstructionsProps> = ({
    workoutStep,
    stepNo
}) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.exerciseNo}>
                Exercise {stepNo + 1}
            </div>
            {workoutStep.exercises.length === 1 ?
                <ExerciseName exercise={workoutStep.exercises[0]} />
                :
                <>
                    {workoutStep.exercises.map((exercise, i) => 
                        <ExerciseName key={exercise.stepExerciseId} exercise={exercise} letter={alphabet[i]} />   
                    )}
                </>
            }
            <div className={classes.setContainer}>
                {workoutStep.exercises.length === 1 &&
                    <>
                        {workoutStep.exercises[0].sets.map((set, index) => 
                            <ExerciseSetBox key={set.stepExerciseSetId} set={set} type={workoutStep.exercises[0].type} setNo={index + 1} />
                        )}
                    </>
                }
                {workoutStep.exercises.length > 1 &&
                    <ExerciseSuperSetBox exercises={workoutStep.exercises} />
                }
            </div>
        </>
    );
};

export default ExerciseInstructions;