import { Divider, Avatar, Badge, Box, Card, Chip, IconButton, makeStyles, Typography, AppBar, Toolbar, Fab, List, ListItem, ListItemText, ListItemAvatar, Grid } from '@material-ui/core';
import NotificationIcon from '@material-ui/icons/Notifications';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import StatsIcon from '@material-ui/icons/Timeline';
import MenuIcon from '@material-ui/icons/Menu';
import CameraIcon from '@material-ui/icons/CameraAlt';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import FavouriteIcon from '@material-ui/icons/Favorite';
import CommentIcon from '@material-ui/icons/Comment';
import HomeIcon from '@material-ui/icons/Home';
import FitnessIcon from '@material-ui/icons/FitnessCenter';
import FoodIcon from '@material-ui/icons/Restaurant';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import WelcomeCard from '../dashboard/WelcomeCard';
import ProfileCard from '../dashboard/ProfileCard';
import { appPaths } from '../AppRoutes';
import AppCard from '../components/AppCard';
import TodaysWorkoutCard from '../dashboard/TodaysWorkoutCard';
import WaterCard from '../dashboard/WaterCard/WaterCard';
import KcalCard from '../dashboard/KcalCard';
import WeeklyWorkoutStats from '../dashboard/WeeklyWorkoutStats';
import RecentRecipesCarousel from '../nutrition/RecentRecipesCarousel';
import { useNutrition } from '../nutrition/context/NutritionContext';
import NutritionEmptyState from '../dashboard/NutritionEmptyState';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.secondary.main,
        padding: '1rem 1rem 6rem 1rem',
        height: '100%',
        minHeight: '100vh',
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.shape.borderRadius,
        padding: '1rem',
        boxShadow: 'none',
        // marginBottom: theme.spacing(2),
    },
    boxInner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginBottom: theme.spacing(2)
    },
    name: {
        fontSize: '1.2rem',
        marginBottom: theme.spacing(2),
        fontWeight: 500
    },
    notificationIcon: {
        position: 'absolute',
        top: theme.spacing(3),
        right: theme.spacing(3)
    },
    bottomNav: {
        position: 'fixed',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '74px',
        alignItems: 'center',
        zIndex: 10
    },
    appBar: {
        top: 'auto',
        left: '.5rem',
        right: '.5rem',
        width: 'calc(100% - 1rem)',
        boxShadow: 'none',
        borderRadius: theme.shape.borderRadius,
        bottom: '.5rem',
        backgroundColor: '#85AAB1'
    },
    grow: {
        flexGrow: 1,
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    actions: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: 'none',
        marginTop: theme.spacing(3),
        '& h3': {
            margin: '.5rem 1rem 1rem'
        }
    },
    swiperContainer: {
        width: '100vw',
        marginLeft: '-1rem',
        paddingLeft: '1rem'
    },
    swiperSlide: {
        display: 'block',
        width: '150px',
        height: '160px',
        backgroundColor: '#FFFFFF',
        borderRadius: theme.shape.borderRadius,
        backgroundImage: 'url("https://unsplash.it/400/400")',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    activitySlide: {
        display: 'block',
        width: '240px',
        height: '180px',
        backgroundColor: '#FFFFFF',
        borderRadius: theme.shape.borderRadius,
    },
    communityCard: {
        display: 'block',
        width: '100%',
        height: '120px',
        backgroundColor: '#FFFFFF',
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2)
    },
    communityIcons: {
        alignItems: 'center'
    },
    monthCard: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridTemplateRows: '110px 110px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.shape.borderRadius,
        padding: '1rem',
        boxShadow: 'none',
        marginBottom: theme.spacing(2),
    },
    monthSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        height: '100%',
        '&:nth-child(2n + 1)': {
            borderRight: `1px solid ${theme.palette.grey[300]}`
        },
        '&:first-of-type': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '&:nth-of-type(2)': {
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            '& > h2': {
                marginBottom: '0'
            },
            '& > h3': {
                margin: '.5rem 0 0 0',
                opacity: .6
            },
            '& > h4': {
                margin: '.5rem 0 0 0'
            }
        }
    },
    workoutCard: {
        background: 'linear-gradient(15deg, #74ebd5, #85d4c5)',
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    workoutCardTitle: {
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightBold,
        opacity: .85
    },
    roundedImage: {
        width: 46,
        height: 46,
        backgroundColor: 'rgba(255, 255, 255, .4)',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2)
    },
    sectionHeading: {
        marginBottom: theme.spacing(1)
    }
}));

const DashboardPage: React.FC = () => {
    const classes = useStyles();
    const { target } = useNutrition();

    return (
        <>
            <ProfileCard />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TodaysWorkoutCard />
                </Grid>
                <Grid item xs={6}>
                    {target ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <WaterCard />
                            </Grid>
                            <Grid item xs={12}>
                                <KcalCard />
                            </Grid>
                        </Grid>
                        :
                        <NutritionEmptyState />
                    }
                </Grid>
            </Grid>
            <h3 className={classes.sectionHeading}>This Week</h3>
            <WeeklyWorkoutStats />
            <RecentRecipesCarousel />
        </>
    );
};

export default DashboardPage;