import axios, { AxiosResponse } from 'axios';
import { buildApiUrl } from './common';
import { ApiMedia } from './models/Media';

export const uploadContentMedia = async (formData: FormData): Promise<AxiosResponse<ApiMedia[]>> => {
    const url = buildApiUrl('media/content');

    const response = await axios.post(url, formData, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response;
};