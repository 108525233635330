import { DropResult } from 'react-beautiful-dnd';
import { WorkoutNew } from '../../../models/Workout';
import { v4 as uuidv4 } from 'uuid';

export const handleDragEnd = (workoutData: WorkoutNew, result: DropResult): WorkoutNew => {
    // Get exercise ID
    const exerciseId = result.draggableId;

    // Add new exercise step to workoutData
    if(result.destination?.droppableId === 'WorkoutContainer' && result.source.droppableId === 'ExerciseContainer') {
        const destinationIndex = result.destination.index;
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));

        workoutDataClone.steps.splice(destinationIndex - 1, 0, {
            order: workoutDataClone.steps.length + 1,
            stepId: uuidv4(),
            exercises: [
                {
                    exerciseId: exerciseId,
                    stepExerciseId: uuidv4(),
                    type: 'REPS',
                    sets: [
                        {
                            stepExerciseSetId: uuidv4(),
                            reps: 10
                        }
                    ]
                }
            ]
        });

        return workoutDataClone;
    }
    
    // Re-order exercise steps
    if(result.destination?.droppableId === 'WorkoutContainer' && result.source.droppableId === 'WorkoutContainer') {
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));
        const stepClone = {...workoutDataClone.steps[sourceIndex - 1]};

        workoutDataClone.steps.splice(sourceIndex - 1, 1);
        workoutDataClone.steps.splice(destinationIndex - 1, 0, stepClone);

        return workoutDataClone;
    }

    // Handle combine to create super / giant sets
    if(result.source.droppableId === 'WorkoutContainer' && result.combine && result.combine.droppableId === 'WorkoutContainer') {
        const sourceIndex = result.source.index;
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));
        const stepClone = {...workoutDataClone.steps[sourceIndex - 1]};

        workoutDataClone.steps.splice(sourceIndex - 1, 1);

        workoutDataClone.steps.map(step => {
            if(step.stepId === result.combine?.draggableId) {
                step.exercises.push(...stepClone.exercises);
            }
        });

        return workoutDataClone;
    }

    // Handle combine direct from exercise library
    if(result.source.droppableId === 'ExerciseContainer' && result.combine && result.combine.droppableId === 'WorkoutContainer') {
        const workoutDataClone: WorkoutNew = JSON.parse(JSON.stringify(workoutData));
        const targetId = result.combine.draggableId;

        workoutDataClone.steps.map(step => {
            if(step.stepId === targetId) {
                step.exercises.push(
                    {
                        exerciseId: exerciseId,
                        stepExerciseId: uuidv4(),
                        type: 'REPS',
                        sets: [
                            {
                                stepExerciseSetId: uuidv4(),
                                reps: 10
                            }
                        ]
                    }
                );
            }
        });

        return workoutDataClone;
    }

    return workoutData;

};