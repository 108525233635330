import { makeStyles } from '@material-ui/core';
import { Timer as TimerIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectors } from '../../store';
import { useWorkoutWalkthrough } from './Context/WorkoutWalkthroughContext';
import medal from './medal.svg';
import AppButton from '../../components/AppButton';

const useStyles = makeStyles((theme) => ({
    completeContainer: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white
    },
    completeInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    completeBtn: {
        position: 'relative',
        display: 'block',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    stats: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    timeStat: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        fontWeight: theme.typography.fontWeightMedium,
        '& svg': {
            marginRight: theme.spacing(1)
        }
    },
    exerciseStat: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        fontWeight: theme.typography.fontWeightMedium,
        '& > span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.common.white,
            width: 30,
            height: 30,
            borderRadius: '50%',
            backgroundColor: theme.palette.secondary.main,
            marginRight: theme.spacing(1)
        }
    },
    actions: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: theme.spacing(2)
    }
}));

const WorkoutWalkthroughComplete: React.FC = () => {
    const classes = useStyles();
    const { workout, timeCapture, completeWorkout, setCurrentStep, currentStep } = useWorkoutWalkthrough();
    const workoutData = useSelector(selectors.workouts.getWorkoutById(workout ? workout._id : ''));

    return (
        <div className={classes.completeContainer}>
            <div className={classes.completeInner}>
                <h2>Congratulations!</h2>
                <p>You just completed <b>{workoutData?.name}</b></p>
                <img src={medal} alt='Workout complete' />
                <div className={classes.stats}>
                    <div className={classes.timeStat}>
                        <TimerIcon />
                        {timeCapture}
                    </div>
                    <div className={classes.exerciseStat}>
                        <span>{workout?.steps.length}</span> Exercises
                    </div>
                </div>
            </div>
            <div className={classes.actions}>
                <AppButton fullWidth variant='text' onClick={() => setCurrentStep(currentStep - 1)}>Go back</AppButton>
                <AppButton fullWidth color='secondary' className={classes.completeBtn} onClick={() => completeWorkout()}>Complete</AppButton>
            </div>
        </div>
    );
};

export default WorkoutWalkthroughComplete;