import { useState } from 'react';
import { Select, TextField, MenuItem, makeStyles } from '@material-ui/core';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { selectors } from '../../../store';
import WorkoutEditorExerciseLibraryItem from './WorkoutEditorExerciseLibraryItem';

const useStyles = makeStyles(theme => ({
    libraryContainer: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'hidden',
        position: 'sticky',
        top: 100,
        display: 'flex',
        flexDirection: 'column',
        '& > h2': {
            flex: 'none'
        }
    },
    searchContainer: {
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    searchInput: {
        flex: '1'
    },
    categorySelect: {
        flex: 'none'
    },
    resetBtn: {
        border: 'none',
        backgroundColor: theme.palette.grey[200]
    },
    workoutListContainer: {
        flex: '1',
        overflow: 'auto'
    }
}));

const WorkoutEditorExerciseLibrary: React.FC = () => {
    const classes = useStyles();
    const exerciseList = useSelector(selectors.exercises.all);
    const [search, setSearch] = useState('');
    const [category, setCategory] = useState<string>('notselected');

    const exercises = exerciseList.filter(item => 
        category !== 'notselected' ? item.category.includes(category) : item)
        .filter(item => search !== '' ? item.name.toLowerCase().includes(search.toLowerCase()) : item);

    const categories = Array.from(new Set(exerciseList.map(item => item.category).flat(1)));

    return (
        <div className={classes.libraryContainer}>
            <h2>Exercise Library</h2>
            <div className={classes.searchContainer}>
                <TextField className={classes.searchInput} value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder='Search...' />
                <div className={classes.categorySelect}>
                    <Select value={category} onChange={(e) => setCategory(e.target.value as string)}>
                        <MenuItem value='notselected'>All categories</MenuItem>
                        {categories.map(item => 
                            <MenuItem key={item} value={item}>{item}</MenuItem>    
                        )}
                    </Select>
                </div>
                <button className={classes.resetBtn} onClick={() => { setSearch(''); setCategory('notselected');}}>Clear</button>
            </div>
            <div className={classes.workoutListContainer}>
                <Droppable droppableId='ExerciseContainer' key={2}>
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {exercises.map((exercise, i) =>
                                    <Draggable
                                        key={exercise._id}
                                        draggableId={exercise._id}
                                        index={i}>
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    key={exercise._id}>
                                                    <WorkoutEditorExerciseLibraryItem exercise={exercise} />
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>);}}
                </Droppable>
            </div>
        </div>
    );
};

export default WorkoutEditorExerciseLibrary;