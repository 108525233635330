import axios, { AxiosResponse } from 'axios';
import { CustomMeal, DiaryItem, MacroCalcData, Nutrition, NutritionDiary } from '../models/Nutrition';
import { Recipe } from '../models/Recipes';
import { buildApiUrl } from './common';

export const calculateNutritionTarget = async (data: MacroCalcData): Promise<AxiosResponse<Nutrition>> => {
    const url = buildApiUrl('nutrition/calculate');

    const response = await axios.post(url, {...data} ,{
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getNutritionTarget = async (): Promise<AxiosResponse<Nutrition>> => {
    const url = buildApiUrl('nutrition/current');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getNutritionDiaryByDate = async (date: Date): Promise<AxiosResponse<NutritionDiary>> => {
    const url = buildApiUrl('nutrition/diary/findByDate');

    const response = await axios.post(url, { date }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const addNewDiaryEntry = async (diary: NutritionDiary): Promise<AxiosResponse<NutritionDiary>> => {
    const url = buildApiUrl('nutrition/diary');

    const response = await axios.post(url, { ...diary }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const updateDiaryEntry = async (diary: NutritionDiary): Promise<AxiosResponse<NutritionDiary>> => {
    const url = buildApiUrl('nutrition/diary');

    const response = await axios.put(url, { ...diary }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const addToMyMeals = async (customMeal: CustomMeal): Promise<AxiosResponse<CustomMeal>> => {
    const url = buildApiUrl('nutrition/personal');

    const response = await axios.post(url, { ...customMeal }, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    return response;
};

export const getMyMeals = async (): Promise<AxiosResponse<Recipe[]>> => {
    const url = buildApiUrl('nutrition/personal');

    const response = await axios.get(url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status === 401) {
        sessionStorage.clear();
        window.location.pathname = '/';
    }

    if(response.status === 200) {
        response.data = response.data.items.map((item: CustomMeal): Recipe => {
            return {
                _id: item._id ? item._id : '',
                name: item.name,
                slug: '',
                ingredients: [],
                directions: [],
                kcals: item.kcals,
                protein: item.protein,
                carbohydrates: item.carbohydrates,
                fats: item.fats,
                time: 0,
                servings: item.servings,
                category: ['My Meals']
            };
        });
    }

    return response;
};