import { useEffect } from 'react';
import { useState } from 'react';
import { getWorkoutLog } from '../../api/Log';
import { IStatsCard, WorkoutLog } from '../../models/Logging';
import { LoggingContext } from './LoggingContext';

const calculateTotalTime = (times: string[]): string => {
    let minutes = 0;
    let seconds = 0;

    times.forEach(time => {
        const [hours, mins, secs] = time.split(':');

        minutes = minutes + (parseInt(hours) * 60) + (parseInt(mins));
        seconds = seconds + parseInt(secs);
    });

    const totalSeconds = (minutes * 60) + seconds;

    return new Date(totalSeconds * 1000).toISOString().substr(11, 8);
};

const LoggingProvider: React.FC = ({
    children
}) => {
    const [CurrentWeek, setCurrentWeek] = useState<WorkoutLog[]>();
    const [StatsCard, setStatsCard] = useState<IStatsCard>({
        workouts: 0,
        time: '00:00:00',
        pbs: 0
    });

    const getCurrentWeekLogs = async () => {
        const result = await getWorkoutLog();

        if(result.status === 200) {
            setCurrentWeek(result.data);
            setStatsCard({
                workouts: result.data.length,
                time: calculateTotalTime(result.data.map(log => log.time)),
                pbs: 0
            });
        }
    };

    useEffect(() => {
        getCurrentWeekLogs();
    }, []);

    return (
        <LoggingContext.Provider value={{
            currentWeek: CurrentWeek,
            statsCard: StatsCard,
            getCurrentWeek: getCurrentWeekLogs
        }}>
            {children}
        </LoggingContext.Provider>
    );
};

export default LoggingProvider;