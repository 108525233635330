import { makeStyles, TextField } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import AppButton from '../components/AppButton';
import AppModal from '../components/AppModal';
import { CustomMeal, DiaryCategory } from '../models/Nutrition';
import { useNutrition } from './context/NutritionContext';
import { addNewDiaryEntry, addToMyMeals, updateDiaryEntry } from '../api/Nutrition';

export interface DiaryCustomMealModalProps {
    open: boolean,
    category?: DiaryCategory,
    handleClose: () => void
}

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: theme.spacing(2)
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const DiaryCustomMealModal: React.FC<DiaryCustomMealModalProps> = ({
    open,
    category,
    handleClose
}) => {
    const classes = useStyles();
    const { diary, fetchDiary } = useNutrition();
    const [manual, setManual] = useState<CustomMeal>({name: '', kcals: 0, protein: 0, carbohydrates: 0, fats: 0, servings: 1, save: false});

    const saveCustomMeal = async () => {
        if(diary) {
            const data = {...diary};

            if(data && manual && category) {
                data.data && data.data.push({
                    ...manual,
                    category: category,
                    eid: uuidv4()
                });
    
                const result = data._id ? await updateDiaryEntry(data) : await addNewDiaryEntry(data);
    
                if(manual.save) {
                    saveToMyMeals();
                }

                if(result.status === 200) {
                    fetchDiary();
                    handleClose();
                    setManual({name: '', kcals: 0, protein: 0, carbohydrates: 0, fats: 0, servings: 1, save: false});
                }
            }
        }
    };

    const saveToMyMeals = async () => {
        const result = await addToMyMeals(manual);

        if(result.status === 200) {
            console.log('saved');
        }
    };

    return (
        <AppModal open={open} handleClose={handleClose}>
            <h2>Custom Meal</h2>
            <TextField fullWidth autoFocus className={classes.input} value={manual.name} onChange={(e) => setManual({...manual, name: e.target.value})} required label='Name' variant='outlined' focused />
            <TextField fullWidth className={classes.input} value={manual.kcals} onChange={(e) => setManual({...manual, kcals: parseInt(e.target.value)})} type='number' required label='Kcals' variant='outlined' focused />
            <TextField fullWidth className={classes.input} value={manual.protein} onChange={(e) => setManual({...manual, protein: parseInt(e.target.value)})} type='number' required label='Protein' variant='outlined' focused />
            <TextField fullWidth className={classes.input} value={manual.carbohydrates} onChange={(e) => setManual({...manual, carbohydrates: parseInt(e.target.value)})} type='number' required label='Carbohydrates' variant='outlined' focused />
            <TextField fullWidth className={classes.input} value={manual.fats} onChange={(e) => setManual({...manual, fats: parseInt(e.target.value)})} type='number' required label='Fats' variant='outlined' focused />
            <TextField fullWidth className={classes.input} value={manual.servings} onChange={(e) => setManual({...manual, servings: parseFloat(e.target.value)})} type='number' required label='Servings' variant='outlined' focused />
            <div className={classes.input}>
                <label>
                    <input type="checkbox" name="save" checked={manual.save} onChange={(e: any) => setManual({...manual, [e.target.name]: !manual.save})} />
                                Save to My Meals
                </label>
            </div>
            <div className={classes.actions}>
                <AppButton onClick={handleClose}>Cancel</AppButton>
                <AppButton color='primary' onClick={saveCustomMeal}>Add +</AppButton>
            </div>
        </AppModal>
    );
};

export default DiaryCustomMealModal;